<div class="grid-container grid-container_md pb-0" style="position: relative;">
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <a mat-button rel="noopener" [routerLink]="[ '../' ]">
      <mat-icon class="material-icons-outlined prt--02 mr-05">arrow_back</mat-icon> Back to Articles
    </a>
  </div>
</div>
<div class="grid-container grid-container_md" style="position: relative;">
  <div #topOfPage></div>
  <div class="overlay white-95" [ngClass]="{'show':store.isLoadingArticle}">
    <div fxLayout="row wrap" fxLayoutAlign="center stretch">
      <div class="loading">
        <mat-spinner [diameter]="36" color="primary"></mat-spinner>
      </div>
    </div>
  </div>
  <ng-container *ngIf="store.article$?.value; else loading">
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <ng-container *ngIf="article; else noArticle">
        <h1 class="mb-05 font-weight-400 text-center">{{store.article$.value.title}}</h1>
      </ng-container>
      <ng-template #noArticle>
        <h1 class="mb-05 font-weight-400 text-center">Mortgage Partner Article</h1>
      </ng-template>
    </div>
    <br />
    <br />
    <br />
    <div class="article-header">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="50px">
          <div class="article-header-img-container">
            <img [src]="store.article$.value.authorImgUrl" [alt]="store.article$.value.authorAltText" class="article-header-img" />
          </div>
        </div>
        <div fxFlex="calc(100% - 100px)">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <p class="mb-0 mt-10 article-header-text">{{store.article$.value.author}}</p>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <p class="article-header-text">{{store.article$.value.date}} &mdash; {{store.article$.value.readTime}} mins</p>
          </div>
        </div>
        <div fxFlex="50px">
          <button mat-icon-button [matMenuTriggerFor]="articleMenu" type="button" (click)="$event.stopPropagation();">
            <mat-icon class="prt--02">ios_share</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <br />
    <br />
    <!-- <div fxLayout="row wrap" fxLayoutAlign="center center">
      <img style="border-radius: 8px; max-height:500px;" [src]="store.article$.value.imgUrl" [alt]="store.article$.value.altText" />
    </div> -->
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <markdown class="markdown" emoji [src]="store.article$.value.postLocation" fxFlex="100" (load)="onLoad($event)"></markdown>
    </div>
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <p class="mb-0 mt-10">{{store.article$.value.author}}</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <p class="mb-0 mt-0">{{store.article$.value.authorTitle}}</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="store.article$.value.authorNMLS">
      <p class="mb-0 mt-0">NMLS # {{store.article$.value.authorNMLS}}</p>
    </div>
  </ng-container>
  <br />
</div>
<ng-template #loading>
  <div fxLayout="row wrap" fxLayoutAlign="center stretch">
    <div class="loading">
      <mat-spinner [diameter]="36" color="primary"></mat-spinner>
    </div>
  </div>
</ng-template>

<mat-menu #articleMenu="matMenu" direction="down" yPosition="below">
  <h6 class="ml-15 mt-05 font-weight-400">Share article</h6>

  <button mat-menu-item shareButton="facebook" #twtBtn="shareButton">
    <img src="../../../assets/images/facebook.svg" class="mat-icon-button-social prt--02 mr-05" style="height: 18px; display: inline;" /> Share on Facebook
  </button>
  <button mat-menu-item shareButton="twitter" #twtBtn="shareButton">
    <img src="../../../assets/images/twitter.svg" class="mat-icon-button-social prt--02 mr-05" style="height: 18px; display: inline;" /> Share on Twitter
  </button>
  <button mat-menu-item shareButton="linkedin" #twtBtn="shareButton">
    <img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social prt--02 mr-05" style="height: 18px; display: inline;" /> Share on LinkedIn
  </button>
  <!-- <button mat-menu-item shareButton="sms" #twtBtn="shareButton">
    Share via text
  </button> -->
  <!-- <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','sms','email','copy']" [show]="6" [size]="1" [url]="'https://www.mortgagepartner.com/articles/' + store.article$.value.urlPath"
    [image]="store.article$.value.imgUrl">
  </share-buttons> -->
</mat-menu>
