import { Component, OnInit } from '@angular/core';
import { fxArray } from 'src/app/models';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-rapid-lo',
  templateUrl: './rapid-lo.component.html',
  styleUrls: ['./rapid-lo.component.scss'],
  animations: fxArray
})
export class RapidLoComponent implements OnInit {
  public numberMask = createNumberMask({ thousandsSeparatorSymbol: ',', allowDecimal: true, decimalSymbol: '.', prefix: '', suffix: '' })
  title: string = "I am looking to..."
  step: number = 1;
  stepProgress: number = 0;
  loanChoice: string;
  residenceType: string;
  propType: string;
  rapidZip: number = undefined;
  hasZip: boolean = false;
  milType: string;
  hasEstPropValue: boolean = false;
  estPropValueAmount: number;
  hasRemainingMortgage: boolean = false;
  remainingMortgageAmount: number;
  cashOut: string;
  estimatedCreditScore: any;


  constructor() { }

  ngOnInit(): void {
    this.loanChoice = '';
    this.step = 1;
    this.stepProgress = 0;
    this.title = "I am looking to..."
  }

  loanType(type: string) {
    this.loanChoice = type;
    this.step = 2;
    this.stepProgress = 13;
    this.title = "This property will be my..."
  }

  resType(type: string) {
    this.residenceType = type;
    this.step = 3;
    this.stepProgress = 21;
    this.title = "The property is a..."
  }

  propertyType(type: string) {
    this.propType = type;
    this.step = 4;
    this.stepProgress = 30;
    this.title = "The zip code is..."
  }

  zipNext() {
    this.step = 5;
    this.stepProgress = 43;
    this.title = "Have you or your spouse ever served in the military?"
  }

  checkZip(event?) {
    if (event) var value = event.target.value
    if (value?.length >= 5) this.hasZip = true
    else this.hasZip = false
  }

  militaryType(type: string) {
    this.milType = type;
    this.step = 6;
    this.stepProgress = 50;
    this.title = "What is your estimated property value?"
  }

  EstPropValue() {
    this.step = 7;
    this.stepProgress = 63;
    this.title = "What is the remaining balance of your mortgage?"
  }

  checkEstPropValue(event?) {
    if (event) var value = event.target.value
    if (value?.length >= 5) this.hasEstPropValue = true
    else this.hasEstPropValue = false
  }

  remainingMortgageBalance() {
    this.step = 8;
    this.stepProgress = 70;
    this.title = "Would you like to take cash out?"
  }

  checkRemainingMortgageAmnt(event?) {
    if (event) var value = event.target.value
    if (value?.length >= 5) this.hasRemainingMortgage = true
    else this.hasRemainingMortgage = false
  }

  cashOutType(type: string) {
    this.cashOut = type;
    this.step = 9;
    this.stepProgress = 79;
    this.title = "What is your estimated property value?"
  }

  creditScore(type: string) {
    this.step = 10;
    this.stepProgress = 83;
    this.title = "What is your estimated property value?"
  }
  

  back() {
    if (this.step == 2) {
      // clear step 2;
      this.loanChoice = '';
      this.step = 1;
      this.stepProgress = 0;
      this.title = "I am looking to..."
    }
    if (this.step == 3) {
      // clear step 3;
      this.residenceType = '';
      this.step = 2;
      this.stepProgress = 13;
      this.title = "This property will be my..."
    }
    if (this.step == 4) {
      // clear step 4;
      this.propType = '';
      this.step = 3;
      this.stepProgress = 21;
      this.title = "The property is a..."
    }
    if (this.step == 5) {
      // clear step 4;
      this.rapidZip = undefined;
      this.step = 4;
      this.stepProgress = 30;
      this.title = "The zip code is..."
    }
    if (this.step == 6) {
      // clear step 4;
      this.rapidZip = undefined;
      this.step = 5;
      this.stepProgress = 30;
      this.title = "Have you or your spouse ever served in the military?"
    }
  }

}
