<button mat-icon-button [matMenuTriggerFor]="helpMenu" [matTooltip]="matTooltipText" [matTooltipPosition]="matTooltipPosition">
  <mat-icon class="material-icons-outlined">help_outline</mat-icon>
</button>
<mat-menu #helpMenu="matMenu" direction="down" yPosition="below">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h6 class="ml-15 mt-05 font-weight-400">{{helpText}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</h6>
  </div>
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <chat-button [matType]="matType" [matColor]="matColor" [buttonText]="buttonText"></chat-button>
  </div>
</mat-menu>
