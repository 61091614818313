
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { fxArray } from 'src/app/models';
import { RateCalculator, Rates } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss'],
  animations: fxArray
})
export class RatesComponent implements OnInit {
  showRateLegal: boolean = false;
  @ViewChild("legalInfo", { static: false }) scrollToLegal: ElementRef;
  currentMonth = '';
  data: RateCalculator = new RateCalculator;
  public numberMask = createNumberMask({ thousandsSeparatorSymbol: ',', allowDecimal: true, decimalSymbol: '.', prefix: '', suffix: '' })
  showMoreOptions: boolean = true;
  includeInsurance: boolean = false;

  constructor(
    public store: UserService,
  ) { }

  ngOnInit(): void {
    this.currentMonth = moment().format('MMMM YYYY');
    this.store.getRates(30).pipe(first()).subscribe((rate: Rates) => {
      this.resetToDefault();
    });
    // Set defaults
  }

  showLegal() {
    this.showRateLegal = !this.showRateLegal;
    this.scrollToLegal.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  showOptions() {
    this.showMoreOptions = !this.showMoreOptions;
    // this.scrollToLegal.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  resetToDefault() {
    this.data.loanType = "30";
    this.data.homePrice = 375000;
    this.data.mortgageRate = this.store.rate$.value.finalRate;
    this.data.downPayment = 75000;
    this.data.downPaymentPercentage = Math.floor(((this.data.downPayment / this.data.homePrice) * 100) * 100) / 100;
    this.data.countyTaxRate = 0.0068;
    this.data.propertyTaxes = this.data.countyTaxRate * (this.data.homePrice - this.data.downPayment);
    this.data.homeInsurance = 1125;
    this.data.hoaFee = 75;
    this.includeInsurance = false;
    this.calcMonthlyPayment(this.data);
  }

  calcMonthlyPayment(data) {
    console.log("🚀 ~ file: rates.component.ts ~ line 62 ~ RatesComponent ~ calcMonthlyPayment ~ data", data);
    data.homePrice = data.homePrice.toString();
    data.downPayment = data.downPayment.toString();
    data.loanType = data.loanType.toString();
    data.propertyTaxes = data.propertyTaxes.toString();
    data.homeInsurance = data.homeInsurance.toString();
    data.hoaFee = data.hoaFee.toString();


    var homePrice = data.homePrice.replace(/[^\d\.\-]/g, "");
    var downPayment = data.downPayment.replace(/[^\d\.\-]/g, "");
    var loanType = data.loanType.replace(/[^\d\.\-]/g, "");
    var propertyTaxes = data.propertyTaxes.replace(/[^\d\.\-]/g, "");
    var homeInsurance = data.homeInsurance.replace(/[^\d\.\-]/g, "");
    var hoaFee = data.hoaFee.replace(/[^\d\.\-]/g, "");
    var downPaymentBalance = parseFloat(homePrice) - parseFloat(downPayment);
    var monthlyInterest = data.mortgageRate / 1200;
    var loanLength = loanType * 12;


    // Something is incorrect in this formula (TO DO);
    // data.monthlyPayment = Math.floor((downPaymentBalance * ((monthlyInterest * (1 + monthlyInterest ** loanLength)) / ((1 + monthlyInterest) ** loanLength - 1))) * 100) / 100;
    data.monthlyPayment = (downPaymentBalance * monthlyInterest) / (1 - (Math.pow((1 + monthlyInterest), loanLength * -1)));
    data.monthlyPayment = data.monthlyPayment.toFixed(2);

    homeInsurance = homeInsurance / 12;
    homeInsurance = homeInsurance.toFixed(2);
    propertyTaxes = propertyTaxes / 12;
    propertyTaxes = propertyTaxes.toFixed(2);
    var additionsToPayment = +hoaFee + +homeInsurance + +propertyTaxes

    // Take the monthly payment and add in the HOA, monthly insurance, and the monthly property taxes.
    data.monthlyPayment = +data.monthlyPayment + +additionsToPayment;
    // Set the monthly payment to the global monthly payment. Rounded it to the nearest 2nd decimal point
    this.data.monthlyPayment = data.monthlyPayment;
    // Update the down payment percentage and round it to the nearest 2nd decimal point.
    this.data.downPaymentPercentage = (downPayment / homePrice) * 100;
    this.data.downPaymentPercentage = this.data.downPaymentPercentage.toFixed(2);
    data.propertyTaxes = propertyTaxes;
    data.homeInsurance = homeInsurance;
    data.hoaFee = hoaFee;
    this.data = data;
  }

  calcDownPaymentPercentage(data) {
    data.homePrice = data.homePrice.toString();
    data.downPaymentPercentage = data.downPaymentPercentage.toString();
    var downPaymentPercentage = data.downPaymentPercentage.replace(/[^\d\.\-]/g, "");
    var homePrice = data.homePrice.replace(/[^\d\.\-]/g, "");
    this.data.downPayment = homePrice * (downPaymentPercentage / 100);
    this.calcMonthlyPayment(this.data);
  }
}