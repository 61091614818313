import { Injectable } from '@angular/core';
//import { PlanData } from '../shared/account-upgrade/account-upgrade.model';

/**
 * App service
 */
@Injectable({ providedIn: 'root' })
export class AppState {
	// TODO: Remove default 
	public default: InternalStateType = new InternalStateType();
	public static StsServer: "";

	public toggle(prop: string) {
		this.default[prop] = !this.default[prop];
	}
}


export class InternalStateType {

	loading: boolean = true;
	showNavigationDrawer: boolean = false;

	year = 2020;
	// UI Bindings
	titleCount: number = 0;
	appTitle: string;
}
