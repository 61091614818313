export class ApplicationData {
    id: string;
    createdDate: any;
    lastUpdatedDate: any;
    status: string;
    loanOfficer: string = 'Tyler';
    processor?: string = 'Office';
    firstName: string;
    middleName?: string;
    lastName: string;
    ssn?: any;
    dob: any;
    dobMonth: string;
    dobDay: string;
    dobYear: string;
    phoneNumber: number;
    emailAddress: string;
    estimatedCreditScore: string;
    hearAbout: string;
    acceptedTerms: boolean;
    uid: string;
    typeOfLoan: string;
    cashOut?: string;
    propertyType: string;
    lengthOfLoan?: number;
    loanTimeframe?: number;
    desiredLoanAmount?: number;
    rentOrOwn?: string;
    lateMortgageRent: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    zipCode: number;
    estimatedPropertyValue: number;
    mortgageOwed?: number;
    currentInterestRate?: number;
    propertyState?: string;
    currentPropertyType?: string;
    typeOfCurrentProperty?: string;
    loanProgram?: string;
    lengthOfMortgage?: number;
    currentPayment?: number;
    reasonToRefi?: string;
    havePropertyAddress?: string;
    purPropertyType?: string;
    purStreetAddress?: string;
    purStreetAddress2?: string;
    purCity?: string;
    purState?: string;
    purZipCode?: number;
    salesPrice?: number;
    currentlyEmployed?: string;
    grossIncome?: number;
    jobStartDate?: any;
    timeOnCurrentJob?: number;
    hrContactName?: string;
    hrContactPhone?: number;
    hrContactEmail?: string;
    hasCoBorrower: string;
    coFirstName?: string;
    coMiddleName?: string;
    coLastName?: string;
    coEstimatedCreditScore: string;
    coSsn?: any;
    coDob?: any;
    coEmailAddress?: string;
    coStreetAddress?: string;
    coStreetAddress2?: string;
    coCity?: string;
    coState?: string;
    coZip?: number;
    coPhoneNumber?: number;
    coHomePhone?: number;
    coCurrentlyEmployed?: boolean;
    coGrossIncome?: number;
    coJobStartDate?: any;
    coTimeOnCurrentJob?: number;
    coHrContactName?: string;
    coHrContactPhone?: number;
    coHrContactEmail?: string;
}

export class AppData {
    id: string;
    createdDate: any;
    lastUpdatedDate: any;
}
export class UserInfo {
    firstName: string;
    middleName: string;
    lastName: string;
    ssn: any;
    dob: string;
    dobMonth: string;
    dobDay: string;
    dobYear: string;
    displayName?: string;
    phoneNumber: number;
    emailAddress: string;
    estimatedCreditScore: string;
    hearAbout: string;
    acceptedTerms: boolean;
    uid: string;
    isBanned: boolean = false;
}

export class DesiredLoan {
    typeOfLoan: string;
    cashOut?: string;
    propertyType: string;
    lengthOfLoan: number;
    loanTimeframe: number;
    desiredLoanAmount: number;
}

export class CurrentLoanInfo {
    rentOrOwn?: string;
    mortgageOwed: number;
    currentInterestRate: number;
    propertyState: string;
    currentPropertyType: string;
    typeOfCurrentProperty: string;
    loanProgram: string;
    lengthOfMortgage: number;
    currentPayment?: number;
    reasonToRefi: string;
    lateMortgageRent: string;
    emailAddress: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    zipCode: number;
    estimatedPropertyValue: number;
}

export class PurchaseLoanInfo {
    loanType: string;
    purLoanProgram: string;
    purPropertyType: string;
    currentMortgagePayment: number;
    reasonToRefi: string;
    havePropertyAddress: string;
    purStreetAddress: string;
    purStreetAddress2: string;
    purCity: string;
    purState: string;
    purZipCode: number;
    salesPrice: number;
}
export class EmploymentInfo {
    grossIncome: number;
    timeOnCurrentJob: number;
    hrContactName: string;
    hrContactPhone: number;
    hrContactEmail: string;
}

export class AdditionalInfo {
    hasCoBorrower: boolean;
    coBorrower?: CoBorrower;
    loanPreference: string;
    comments: string;
    howDidYouHear: string;
}

export class CoBorrower {
    cofirstName: string;
    comiddleName: string;
    colastName: string;
    coSsn: number;
    coEstimatedCreditScore: string;
    coDob: string;
    coEmailAddress: string;
    coStreetAddress: string;
    coStreetAddress2: string;
    coCity: string;
    coState: string;
    coZip: number;
    coCellPhone: number;
    coHomePhone: number;
}


export class ItemsNeeded {
    docKey?: string;
    title: string;
    requestedDate: any;
    dueDate: any;
    status: string;
    priority: string;
    priorTo: string;
    borrower: string;
    loanIDs: number[];
    note?: string;
    isQueued?: boolean;
    coBorrower?: string;
    attachedFiles: any[];
}

export class Rates {
    lenderFees: number;
    downPayment: number;
    propertyType: string;
    zipCode: number;
    loanPurpose: string;
    rateAsOf: string;
    propertyState: string;
    propertyCounty: string;
    ltv: number;
    loanType: string;
    lockedRate: number;
    appraisedValue: number;
    rate: number;
    finalRate: number;
    loanAmount: number;
    creditScore: number;
    finalPoints: number;
    lockedPeriod: number;
    loanPeriod: number;
    aprInterest: number;
    monthlyPayment: number;
    monthlyInterest: number;
    downPaymentBalance: number;
}

export class RateCalculator {
    homePrice: any;
    loanType: any;
    downPayment: any;
    downPaymentPercentage: any;
    homeInsurance: any;
    mortgageRate: any;
    propertyTaxes: any;
    countyTaxRate: any;
    hoaFee: any;
    monthlyPayment: any;
}