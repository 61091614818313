import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { OfflineComponent } from './shared/offline/offline.component';
import { SelectivePreloadingStrategyService } from './shared/services/selective-preloading-strategy.service';


const routes: Routes = [
    { path: '404', component: OfflineComponent, data: { reason: '404', title: '404' } },
    // { path: 'home', component: HomepageComponent, data:{ title:'Home' }  },
    {
		path:'',
		children:[
			 { 	path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
		]
	},
    { path: '**', component: HomepageComponent, data: { title: "Utah's Best Mortgage Rates. No Origination Fees." } },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false,
    paramsInheritanceStrategy: 'always',
    preloadingStrategy: SelectivePreloadingStrategyService,
    initialNavigation: 'enabled'
})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
