import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { fxArray } from 'src/app/models';
import { UserService } from 'src/app/shared/services/user.service';
import { Article } from '../articles.model';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  animations: fxArray
})
export class ArticleComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("topOfPage", { static: false }) scrollToTopOfPage: ElementRef;
  article: Article;
  articleID: string;
  markdownReady: boolean = false;
  headings: Element[] | undefined;
  currentRoute: any = '';


  constructor(
    public store: UserService,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private elementRef: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {

    this.article = new Article();
    this.articleID = this.route.snapshot.url[0].path;
    if (this.articleID) {
      this.store.isLoadingArticle = true;
    }
    this.store.getArticle(this.articleID);
  };

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollTo('topOfPage');
      console.log("🚀 ~ file: article.component.ts ~ line 36 ~ ArticleComponent ~ ngOnInit ~ this.store.article$.value", this.store.article$.value);
    }, 100);
  }

  ngOnDestroy(): void { }

  async scrollTo(destination: string) {
    if (destination === 'topOfPage') this.scrollToTopOfPage.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }


  onLoad($event): void {
    this.store.isLoadingArticle = false;
    // this.stripContent();
    // this.setHeadings();
  }

  private setHeadings(): void {
    const headings: Element[] = [];
    this.elementRef.nativeElement
      .querySelectorAll('h2')
      .forEach(x => headings.push(x));
    this.headings = headings;
  }

  private stripContent(): void {
    this.elementRef.nativeElement
      .querySelector('markdown')!
      .querySelectorAll('markdown > p:nth-child(-n + 2), #ngx-markdown, #table-of-contents + ul, #table-of-contents')
      .forEach(x => x.remove());
  }
}
