
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import "firebase/storage";
import { Giveaway } from 'src/app/pages/giveaway/giveaway.model';

@Injectable({
    providedIn: 'root'
})
export class GiveawayService {

    constructor(
        private firestore: AngularFirestore,
        public snackBar: MatSnackBar,
    ) {

    }
    createGiveawayData(giveaway: Giveaway) {
        var giveawayUserData = Object.assign({}, giveaway);
        return this.firestore.collection('giveaways').doc(giveawayUserData.docId).collection(giveawayUserData.user).doc('data').set(giveawayUserData);
    }
}