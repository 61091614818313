
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import "firebase/storage";
import { Article } from 'src/app/pages/articles/articles.model';
import { FirebaseErrors } from '../models/error.model';

@Injectable({
    providedIn: 'root'
})
export class ArticlesService {

    constructor(
        private firestore: AngularFirestore,
        public snackBar: MatSnackBar,
    ) {

    }
    updateFavorites(article: Article) {
        var articleData = Object.assign({}, article);
        console.log("Updating items needed");
        return this.firestore.collection('articles').doc(articleData.docKey).update({
            "totalFavorites": articleData.totalFavorites
        })
            .then((res) => {
                if (articleData.isFavorite) this.snackBar.open("Thank you!", "Close", { duration: 3000 });
            }).catch((error) => {
                // Uh-oh, an error occurred!
                console.log("🚀 ~ file: user.service.ts ~ line 236 ~ UserService ~ updateItemsNeeded ~ error", error);
                this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
            });
    }
} 