import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date: any

  constructor(
    public store: UserService,
    
    ) { }

  ngOnInit(): void {
    this.date = moment(new Date()).format('YYYY');
  }

}
