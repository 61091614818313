import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as e from 'express';
import { UserService } from '../services/user.service';

@Component({
  selector: 'help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {
  @Input() matType;
  @Input() matColor;
  @Input() extraClass;
  @Input() extraStyle;
  @Input() buttonText;
  @Input() routerLink;
  @Input() iconClass;
  @Input() iconText;
  @Input() matTooltipText;
  @Input() matTooltipPosition;
  @Input() helpText;

  constructor(
    public store: UserService,
  ) { }

  ngOnInit(): void {
    if (!this.matType) this.matType = 'raised';
    if (!this.matColor) this.matColor = 'primary';
    if (!this.buttonText) this.buttonText = 'Click to get help';
    if (!this.matTooltipText) this.matTooltipText = 'Click to get help';
    if (!this.matTooltipPosition) this.matTooltipPosition = 'above';
  }

}
