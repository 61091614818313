import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AppState } from 'src/app/models';
import { AuthService, LoginBottomSheet } from 'src/app/shared';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger) levelOneTrigger: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) accTrigger: MatMenuTrigger;
  toolsHover: boolean = false;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  toolsMenuList = [
    // { text: 'Prequalification Calculator', route: './mortgage-partner-tools/utah-mortgage-loan-pre-qualification', icon: 'location_searching' },
    // { text: 'Loan Calculators', route: './mortgage-partner-tools/utah-mortgage-loan-calculators', icon: 'calculate' },
    { text: 'Loan Limits', route: './mortgage-partner-tools/utah-mortgage-loan-limits', icon: 'error_outline' },
    { text: 'Write A Review', route: 'route', icon: 'rate_review', html: 'https://www.google.com/search?q=mortgage+partner&rlz=1C1GCEA_enUS936US936&oq=mortgage+partner&aqs=chrome.0.0i355j46i175i199j0l3j69i60l3.3610j1j7&sourceid=chrome&ie=UTF-8#lrd=0x8752869290356721:0x9ad9404e969358a4,3,,,' },
    // { text: 'FAQs', route: './mortgage-partner-tools/utah-mortgage-faqs', icon: 'help_outline' },
    { text: 'Mortgage Glossary', route: './mortgage-partner-tools/utah-mortgage-glossary', icon: 'import_contacts' },
    { text: 'Rate Assumptions', route: './mortgage-partner-tools/utah-mortgage-rate-assumptions', icon: 'trending_down' },
    { text: 'Privacy Policy', route: 'privacy-policy', icon: 'privacy_tip' },
    { text: 'Disclosures', route: 'disclosures', icon: 'security' },
  ]

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ren: Renderer2,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    public appState: AppState,
    public auth: AuthService,
    public store: UserService,
  ) { }

  ngOnInit(): void {
    if (this.auth.isAuthenticated) this.store.getItemsNeeded();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
      document.getElementById('header').classList.add('header-elevated');
    }
    else if (document.body.scrollTop <= 20 ||
      document.documentElement.scrollTop <= 20) {
      document.getElementById('header').classList.remove('header-elevated');
    }
  }

  closeDrawer() {
    this.appState.default.showNavigationDrawer = !this.appState.default.showNavigationDrawer
  }

  menuEnter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        this.levelOneTrigger.closeMenu();
        this.accTrigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1, trigger2) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100)
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu['_elementRef'].nativeElement, 'cdk-program-focused');
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();
        this.ren.removeClass(trigger.menu['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu['_elementRef'].nativeElement, 'cdk-program-focused');
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    })
  }

  buttonLeave(trigger) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }

  login() {
    this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
  }

  logout() {
    this.router.navigate(['home']);
    this.auth.logout(true);
    // Clear user's stored in memory data.
    this.store.clearUserData();
  }

}
