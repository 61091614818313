import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
import { fxArray } from 'src/app/models';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  animations: fxArray
})
export class HomepageComponent implements OnInit {
  testimonial1: boolean = true
  testimonial2: boolean = false
  testimonial3: boolean = false
  testimonials = [
    { quote: "Penny and the mortgage partner have helped us through 3 loans. I have complete trust that they will always be honest in guiding us to make the decision. It is rare to find a mortgage company that is honest enough that they will give you advice on to do something if it's not in your best interest, even if it's something that they would have made money on. I would highly recommend the Mortgage Partner to everyone I know.", name: "Candice Gibbons" },
    { quote: "Some friends recommended these guys to me and I am so glad they did. They helped me purchase my first condo. They were great at explaining everything especially since I had never bought a place before and had no idea what I was doing. I later purchased a duplex in the middle of pandemic craziness. These guys where with me the whole time getting great rates, battling it out with banks and making sure everything went smoothly. I love these guys and will be using them for all of my future property purchases.", name: "Cameron Hudspeth" },
    { quote: "I recently refinanced my home and had to do it all remotely. Penny had secure emails to upload and receive all my important documents for the loan. The team was very professional and efficient and kept me in the loop at all times. Penny was also able to shop for the best rate available and I loved the personal care and honestly that went into helping me. I highly recommended them to anyone, Thanks Mortgage Partner.", name: "Teri Jenson" },
  ]
  showTestimonialIndex: number = 0;

  constructor(
    private route: ActivatedRoute,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    console.log("🚀 ~ file: homepage.component.ts ~ line 31 ~ HomepageComponent ~ ngOnInit ~ this.route.snapshot.url[0]", this.route.snapshot.data.title);
    // Metadata for this page.
    this.metaTagService.updateTag({ name: 'description', content: "Mortgage Partner is Utah's #1 mortgage company, providing Salt Lake City with Conventional, FHA, VA, and USDA loans." });
    this.metaTagService.updateTag({ name: 'date', content: '10/27/2021', scheme: 'MM/DD/YYYY' });
    this.metaTagService.updateTag({ name: 'og:type', content: 'website'});
    this.metaTagService.updateTag({ name: 'og:title', content: 'website'});


      // Cycle through testimonials.
      interval(15000).subscribe(x => {
        // Check if we have more testimonials to cycle through. Else go back to 0,
        if (this.showTestimonialIndex < (this.testimonials.length - 1)) this.nextTestimonial();
        else this.showTestimonialIndex = 0;
      });

  }

  nextTestimonial() {
    if (this.showTestimonialIndex < (this.testimonials.length - 1)) this.showTestimonialIndex = this.showTestimonialIndex + 1;
    else this.showTestimonialIndex = 0;
  }

  prevTestimonial() {
    if (this.showTestimonialIndex === 0) this.showTestimonialIndex = (this.testimonials.length - 1);
    else this.showTestimonialIndex = this.showTestimonialIndex - 1;
  }

}
