import { AnimationTriggerMetadata, trigger, style, state, transition, animate, keyframes } from '@angular/animations';
export const fxArray = [
    trigger('800', [
        transition('bounceInDown => void, * => bounceOutDown', [
            animate('800ms 0ms linear', keyframes([
                style({ transform: "translate3d(0, 10px, 0)", offset: 0.2 }),
                style({ opacity: 1, transform: "translate3d(0, -20px, 0)", offset: 0.5 }),
                style({ opacity: 0, transform: "translate3d(0, 1000px, 0)", offset: 1 })]))]),
        transition('* => bounceInDown', [
            animate('800ms 0ms linear', keyframes([
                style({ opacity: 0, transform: "translate3d(0, -1000px, 0)", offset: 0 }),
                style({ opacity: 1, transform: "translate3d(0, 20px, 0)", offset: 0.6 }),
                style({ transform: "translate3d(0, -10px, 0)", offset: 0.75 }),
                style({ transform: "translate3d(0, 5px, 0)", offset: 0.9 }),
                style({ transform: "translate3d(0, 0, 0)", offset: 1 })]))]),
        state('bounceOutDown',
            style({ display: "none" }))]),
    trigger('600', [
        transition('growInUp => void, * => growOut', [
            animate('600ms 0ms linear', keyframes([
                style({ transform: "scaleY(1)", "transformOrigin": "bottom center", offset: 0 }),
                style({ transform: "scaleY(0)", "transformOrigin": "bottom center", "visibility": "visible", offset: 1 })]))]),
        transition('* => growInUp', [
            animate('600ms 0ms linear', keyframes([
                style({ transform: "scaleY(0)", "transformOrigin": "bottom center", "visibility": "visible", offset: 0 }),
                style({ transform: "scaleY(1)", "transformOrigin": "bottom center", offset: 1 })]))]),
        state('growOut',
            style({ display: "none" })),


        transition('fadeInNotOut => fadeOut', [
            animate('600ms 0ms linear', keyframes([
                style({ opacity: 1, offset: 0 }),
                style({ opacity: 0, offset: 1 })]))]),
        transition('* => fadeInNotOut', [
            animate('600ms 0ms linear', keyframes([
                style({ opacity: 0, offset: 0 }),
                style({ opacity: 1, offset: 1 })]))]),
        state('fadeOut',
            style({ display: "none" })),
    ]),
    trigger('400', [
        transition('slideInLeft => void, * => slideOutRight', [
            animate('400ms 0ms linear', keyframes([
                style({ transform: "translate3d(0, 0, 0)", offset: 0 }),
                style({ transform: "translate3d(100%, 0, 0)", offset: 1 })]))]),
        transition('* => slideInLeft', [
            animate('400ms 0ms linear', keyframes([
                style({ transform: "translate3d(-100%, 0, 0)", offset: 0 }),
                style({ transform: "translate3d(0, 0, 0)", offset: 1 })]))]),
        state('slideOutRight',
            style({ display: "none" })),
        // transition('bounceIn => void, * => bounceOut', [
        //     animate('400ms 0ms linear', keyframes([
        //         style({ transform: "scale3d(.9, .9, .9)", offset: 0.2 }),
        //         style({ opacity: 1, transform: "scale3d(1.1, 1.1, 1.1)", offset: 0.5 }),
        //         style({ opacity: 0, transform: "scale3d(.3, .3, .3)", offset: 1 })]))]),
        transition('* => bounceIn', [
            animate('400ms 0ms linear', keyframes([
                style({ opacity: 0, transform: "scale3d(.3, .3, .3)", offset: 0 }),
                style({ transform: "scale3d(1.1, 1.1, 1.1)", offset: 0.2 }),
                style({ transform: "scale3d(.9, .9, .9)", offset: 0.4 }),
                style({ transform: "scale3d(1.03, 1.03, 1.03)", offset: 0.6 }),
                style({ transform: "scale3d(.97, .97, .97)", offset: 0.8 }),
                style({ opacity: 1, transform: "scale3d(1, 1, 1)", offset: 1 })]))]),

        transition('growInDown => void, * => growOut', [
            animate('400ms 0ms linear', keyframes([
                style({ transform: "scaleY(1)", "transformOrigin": "top center", offset: 0 }),
                style({ transform: "scaleY(0)", "transformOrigin": "top center", "visibility": "visible", offset: 1 })]))]),
        transition('* => growInDown', [
            animate('400ms 0ms linear', keyframes([
                style({ transform: "scaleY(0)", "transformOrigin": "top center", "visibility": "visible", offset: 0 }),
                style({ transform: "scaleY(1)", "transformOrigin": "top center", offset: 1 })]))]),
        state('growOut',
            style({ display: "none" })),

        transition('growInUp => void, * => growOut', [
            animate('400ms 0ms linear', keyframes([
                style({ transform: "scaleY(1)", "transformOrigin": "bottom center", offset: 0 }),
                style({ transform: "scaleY(0)", "transformOrigin": "bottom center", "visibility": "visible", offset: 1 })]))]),
        transition('* => growInUp', [
            animate('400ms 0ms linear', keyframes([
                style({ transform: "scaleY(0)", "transformOrigin": "bottom center", "visibility": "visible", offset: 0 }),
                style({ transform: "scaleY(1)", "transformOrigin": "bottom center", offset: 1 })]))]),
        state('growOut',
            style({ display: "none" })),


        transition('fadeIn => void, * => fadeOut', [
            animate('400ms 0ms linear', keyframes([
                style({ opacity: 1, offset: 0 }),
                style({ opacity: 0, offset: 1 })]))]),
        transition('* => fadeIn', [
            animate('400ms 0ms linear', keyframes([
                style({ opacity: 0, offset: 0 }),
                style({ opacity: 1, offset: 1 })]))]),
        state('fadeOut',
            style({ display: "none" })),

        // Zoom animation
        transition('zoomIn => void, void => zoomOut', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "opacity": 1,
                    "transform": "scale3d(1, 1, 1)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "opacity": 0,
                    "transform": "scale3d(.1, .1, .1)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => zoomIn', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "opacity": 0,
                    "transform": "scale3d(.1, .1, .1)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "opacity": 1,
                    "transform": "scale3d(1, 1, 1)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        state('zoomOut',
            style({
                "display": "none"
            })),
        // End zoom animation
        // slide animation
        transition('void => slideOutUp', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, -100%, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('slideInUp => void', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, -100%, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => slideInUp', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, 100%, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => slideOutLeft', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(-100%, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('slideInRight => void', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(-100%, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => slideInRight', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(100%, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => slideOutRight', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(100%, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('slideInLeft => void', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(100%, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => slideInLeft', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(-100%, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => slideOutDown', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 100%, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('slideInDown => void', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, 100%, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => slideInDown', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "visibility": "hidden",
                    "transform": "translate3d(0, -100%, 0)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "visibility": "visible",
                    "transform": "translate3d(0, 0, 0)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        state('slideOutUp',
            style({
                "display": "none"
            })),
        state('slideOutRight',
            style({
                "display": "none"
            })),
        state('slideOutLeft',
            style({
                "display": "none"
            })),
        state('slideOutDown',
            style({
                "display": "none"
            })),
        // End slide animations
        // rotate animations
        transition('rotate360 => void, void => rotateIn', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "opacity": 1,
                    "transformOrigin": "center",
                    "transform": "rotate3d(0, 0, 1, 0deg)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "opacity": 0,
                    "transformOrigin": "center",
                    "transform": "rotate3d(0, 0, 1, 200deg)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        transition('void => rotateIn', [
            animate('400ms 0ms linear', keyframes([
                style({
                    "opacity": 0,
                    "transformOrigin": "center",
                    "transform": "rotate3d(0, 0, 1, -200deg)",
                    "offset": 0,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                }),
                style({
                    "opacity": 1,
                    "transformOrigin": "center",
                    "transform": "rotate3d(0, 0, 1, 0deg)",
                    "offset": 1,
                    "position": "absolute",
                    "width": "100%",
                    "overflow": "hidden"
                })
            ]))
        ]),
        // End rotate animations

        // 
        // 
        // 
        // End all animations
    ]),

    trigger('200', [
        transition('growInUp => void, * => growOut', [
            animate('200ms 0ms linear', keyframes([
                style({ transform: "scaleY(1)", "transformOrigin": "bottom center", offset: 0 }),
                style({ transform: "scaleY(0)", "transformOrigin": "bottom center", "visibility": "visible", offset: 1 })]))]),
        transition('* => growInUp', [
            animate('200ms 0ms linear', keyframes([
                style({ transform: "scaleY(0)", "transformOrigin": "bottom center", "visibility": "visible", offset: 0 }),
                style({ transform: "scaleY(1)", "transformOrigin": "bottom center", offset: 1 })]))]),
        state('growOut',
            style({ display: "none" })),


        transition('fadeInNotOut => fadeOut', [
            animate('200ms 0ms linear', keyframes([
                style({ opacity: 1, offset: 0 }),
                style({ opacity: 0, offset: 1 })]))]),
        transition('* => fadeInNotOut', [
            animate('200ms 0ms linear', keyframes([
                style({ opacity: 0, offset: 0 }),
                style({ opacity: 1, offset: 1 })]))]),
        state('fadeOut',
            style({ display: "none" })),
    ]),
];


