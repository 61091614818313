import { AfterViewInit, Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState, fxArray } from 'src/app/models';
import { US_STATES } from 'src/app/shared/models/us-states.model';
import { textMaskDateFn, textMaskPhoneFn, textMaskSSN } from 'src/app/shared/services/text-mask-functions';
import { UserInfo, ApplicationData } from 'src/app/shared/models/user.model';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as CryptoJS from 'crypto-js';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthService, LoginBottomSheet } from 'src/app/shared';
import { interval } from 'rxjs';
import * as moment from 'moment';
import { FirebaseErrors } from 'src/app/shared/models/error.model';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WindowRef } from 'src/app/shared/services/window.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
  animations: fxArray
})
export class ApplyComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myForm', { static: false }) myForm: NgForm;

  public a: boolean;
  public isBusy: boolean = false;
  public applicationData: Observable<ApplicationData>;
  public iAgree: any;
  public apply: boolean;
  public dobMask = { guide: false, mask: textMaskDateFn };
  public mask = { guide: false, mask: textMaskPhoneFn };
  public ssnMask = { guide: true, mask: textMaskSSN };
  public data: ApplicationData;
  public userInfo: UserInfo;
  public numberMask = createNumberMask({ thousandsSeparatorSymbol: ',', allowDecimal: true, decimalSymbol: '.', prefix: '', suffix: '' })
  showSsn: boolean = false;
  canEditApp: boolean;
  coShowSsn: boolean = false;
  waitingForUser: boolean = true;
  interval: any;
  appData: any[] = [];
  appsData$: BehaviorSubject<any[]>;
  appsColumns: string[] = ['icon', 'type', 'status', 'propAddress', 'date', 'loanAmount', 'id', 'action'];
  isAppDisabled: boolean = false;
  isSubmitting: boolean = false;
  index: number = 0;
  isViewingApp: boolean = false;

  uid: string = "TD%#c*J8%vnog%2&7EdA#FkpWtt5@Bb0c18bZLZLvOj6ep9Moq*T3UReMmidBwqRvn6X&IrKvosQUryMfoA*mM8rTtUDA6Nsb11";
  conversionEncryptOutput: string;
  conversionDecryptOutput: string;
  animate: boolean = false;


  public allStates = US_STATES;

  // public bank: WalletData = new WalletData();

  @ViewChild('appForm', { static: false }) private appForm: NgForm;
  @ViewChild("userInfo", { static: false }) scrollToUserInfo: ElementRef;
  @ViewChild("coBorrower", { static: false }) scrollToCoBorrower: ElementRef;
  @ViewChild("loanType", { static: false }) scrollToLoanType: ElementRef;
  @ViewChild("currentLoan", { static: false }) scrollToCurrentLoan: ElementRef;
  @ViewChild("purchaseProp", { static: false }) scrollToPurchaseProp: ElementRef;
  @ViewChild("income", { static: false }) scrollToIncome: ElementRef;

  constructor(
    public appState: AppState,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    public auth: AuthService,
    public store: UserService,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRef
  ) { }

  ngOnInit(): void {
    this.store.isLoadingApps = true;
    this.data = new ApplicationData();
    // this.manualConvertCoText('', '')
  }

  ngAfterViewInit(): void {
    this.interval = interval(250).subscribe(x => {
      this.checkForUser();
    });
    setTimeout(() => {
      this.scrollTo('userInfo');
    }, 100);
  }

  ngOnDestroy(): void { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    var body = document.body,
      html = document.documentElement;

    var height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);
    let scrollPosition = 0;
    if (isPlatformBrowser(this.platformId)) {
      // Use the window reference: this.windowRef
      scrollPosition = this.windowRef.nativeWindow.pageYOffset
    }
    // else scrollPosition = window.pageYOffset
    if (document.body.scrollTop > 335 ||
      document.documentElement.scrollTop > 335) {
      document.getElementById('guide').classList.add('fixed');
    }
    else if (document.body.scrollTop <= 335 ||
      document.documentElement.scrollTop <= 335) {
      document.getElementById('guide').classList.remove('fixed');
    }
    if ((height - scrollPosition) <= 1600) {
      // document.getElementById('guide').classList.remove('fixed');
      document.getElementById('guide').style.top = 'auto';
      document.getElementById('guide').style.bottom = (855).toString() + 'px';
    }
    else {
      document.getElementById('guide').style.top = '115px';
      document.getElementById('guide').style.bottom = '0px';
    }
  }

  scrollToShow(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollToShow(firstElementWithError);
  }

  async scrollIfFormHasErrors(form): Promise<any> {
    await this.appForm.invalid;
    this.scrollToError();
  }

  // Used for ToC
  async scrollTo(destination: string) {
    if (destination === 'userInfo') this.scrollToUserInfo.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    if (destination === 'coBorrower') this.scrollToCoBorrower.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    if (destination === 'loanType') this.scrollToLoanType.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    if (destination === 'currentLoan') this.scrollToCurrentLoan.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    if (destination === 'purchaseProp') this.scrollToPurchaseProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    if (destination === 'income') this.scrollToIncome.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  convertText(conversion: string) {
    if (!this.data.ssn) return;
    if (conversion == "encrypt") {
      this.conversionEncryptOutput = CryptoJS.AES.encrypt(this.data.ssn, this.uid.trim()).toString();
    }
    else {
      this.conversionDecryptOutput = CryptoJS.AES.decrypt(this.conversionEncryptOutput.trim(), this.uid.trim()).toString(CryptoJS.enc.Utf8);

    }
  }

  convertCoText(conversion: string) {
    if (!this.data.coSsn) return;
    if (conversion == "encrypt") {
      this.conversionEncryptOutput = CryptoJS.AES.encrypt(this.data.coSsn, this.uid.trim()).toString();
    }
    else {
      this.conversionDecryptOutput = CryptoJS.AES.decrypt(this.conversionEncryptOutput.trim(), this.uid.trim()).toString(CryptoJS.enc.Utf8);

    }
  }

  manualConvertCoText(value: string, uid: string) {
    var conversionDecryptOutput = CryptoJS.AES.decrypt(value.trim(), uid.trim()).toString(CryptoJS.enc.Utf8);
    console.log("SS Converted: ", conversionDecryptOutput);
  }

  checkForUser() {
    this.index++
    if (!this.store.isLoadingUser) {
      if ((this.store.userInfo$?.value.uid != undefined || null) && this.auth.isAuthenticated) {
        this.store.getApplications();
        this.route.queryParams.subscribe(params => {
          if (params?.id) {
            this.store.getApplication(params.id)
              .then((res: any) => {
                res.pipe(first()).subscribe((appData: ApplicationData) => {
                  this.data = appData;
                  if (appData.status === "Started") {
                    this.isAppDisabled = false;
                    this.canEditApp = true;
                  }
                  else {
                    this.isAppDisabled = true;
                    this.canEditApp = false;
                  }
                  if (this.data.dob) this.data.dob = moment(this.data.dob);
                  if (this.data.coDob) this.data.coDob = moment(this.data.coDob);
                  if (this.data.jobStartDate) this.data.jobStartDate = moment(this.data.jobStartDate);
                  if (this.data.coJobStartDate) this.data.coJobStartDate = moment(this.data.coJobStartDate);
                  this.store.showApps = false;
                  this.isViewingApp = true;
                  this.waitingForUser = false;
                  this.interval.unsubscribe();
                },
                  error => {
                    console.log("getApplications error: ", error);
                    if (error === 'FirebaseError: Missing or insufficient permissions.') {
                      this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
                      this.snackBar.open("Please log in.", "Close", { duration: 3000, panelClass: ["info"] });
                      this.waitingForUser = false;
                      this.interval.unsubscribe();
                    }
                  });
              }).catch((error) => {
                // Uh-oh, an error occurred!
                this.data = new ApplicationData();
                console.log("🚀 ~ file: user.service.ts ~ line 236 ~ UserService ~ updateItemsNeeded ~ error", error);
                this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
              });
          }
          else {
            this.data = new ApplicationData();
            this.isAppDisabled = false;
            if (this.store.accAppData$.value && this.store.accAppData$.value.id) {
              this.data = this.store.accAppData$.value;
              if (this.store.accAppData$.value.dob) this.data.dob = moment(this.store.accAppData$.value.dob);
              if (this.store.accAppData$.value.coDob) this.data.coDob = moment(this.store.accAppData$.value.coDob);
              if (this.store.accAppData$.value.jobStartDate) this.data.jobStartDate = moment(this.store.accAppData$.value.jobStartDate);
              if (this.store.accAppData$.value.coJobStartDate) this.data.coJobStartDate = moment(this.store.accAppData$.value.coJobStartDate);
              this.store.showApps = false;
              this.isAppDisabled = true;
              this.isViewingApp = true;
            }
            else {
              this.store.showApps = true;
              this.data.firstName = this.store.userInfo$.value.firstName;
              this.data.middleName = this.store.userInfo$.value.middleName;
              this.data.lastName = this.store.userInfo$.value.lastName;
              this.data.phoneNumber = this.store.userInfo$.value.phoneNumber;
              this.data.emailAddress = this.store.userInfo$.value.emailAddress;
            }
            this.waitingForUser = false;
            this.interval.unsubscribe();
          }
        });
      } else {
        console.log("Not logged in");
        this.store.hasApplications = false;
        this.store.showApps = false;
        if (this.index === 50) this.interval.unsubscribe();
      }
    }
  }

  async checkLoanType(data) {
    // Reset unused data back to undefined
    if (data.typeOfLoan === 'Purchase' || data.typeOfLoan === 'Refinance (no cash out)') {
      data.cashOut = undefined;
    }
    if (data.typeOfLoan === 'Refinance (no cash out)' || data.typeOfLoan === 'Refinance (cash out)') {
      data.rentOrOwn = 'Own';
    }
  }

  async decryptSsn() {
    this.showSsn = !this.showSsn;
    if (this.showSsn) {
      this.convertText('decrypt');
      this.data.ssn = this.conversionDecryptOutput;
    }
    else this.data.ssn = this.conversionEncryptOutput;
  }

  async decryptCoSsn() {
    this.coShowSsn = !this.coShowSsn;
    if (this.coShowSsn) {
      this.convertText('decrypt');
      this.data.coSsn = this.conversionDecryptOutput;
    }
    else this.data.coSsn = this.conversionEncryptOutput;
  }

  async scrubSsn() {
    // Scrub the ssn to encrypted.
    this.data.ssn = this.conversionEncryptOutput;
  }

  async scrubCoSsn() {
    // Scrub the ssn to encrypted.
    this.data.coSsn = this.conversionEncryptOutput;
  }

  submitApplication() {
    this.isSubmitting = true;
    if (this.appForm.valid) {
      if (this.store.applications$.value && this.store.applications$.value.length < 10) {
        var randomId = '';
        var characters = '0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 7; i++) {
          randomId += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        const appDate = moment().format('MM/DD/YYYY LT')
        // Check if there is co-borrower information
        // If there is and the data.hasCoBorrower is no, don't submit the co-borrower info.
        if (this.data.dob) this.data.dob = moment(new Date(this.data.dob)).format('MM/DD/YYYY');
        if (this.data.jobStartDate) this.data.jobStartDate = moment(new Date(this.data.jobStartDate)).format('MM/DD/YYYY');
        if (this.data.hasCoBorrower === 'No') {
          this.data.coFirstName = '';
          this.data.coMiddleName = '';
          this.data.coLastName = '';
          this.data.coPhoneNumber = 0;
          this.data.coEmailAddress = '';
          this.data.coEstimatedCreditScore = '';
          this.data.coDob = '';
          this.data.coSsn = '';
        } else {
          if (this.data.coDob) this.data.coDob = moment(new Date(this.data.coDob)).format('MM/DD/YYYY');
          if (!this.data.coSsn) this.data.coSsn = 'Not given';
          if (this.data.coJobStartDate) this.data.coJobStartDate = moment(new Date(this.data.coJobStartDate)).format('MM/DD/YYYY');
        }
        if (!this.data.ssn) this.data.ssn = 'Not given';
        if (!this.data.id) this.data.id = '1900' + randomId;
        if (!this.data.createdDate) this.data.createdDate = appDate;
        if (!this.data.status) this.data.status = "Started";
        this.data.lastUpdatedDate = appDate;
        if (this.data.middleName === undefined) this.data.middleName = '';
        if (this.data.cashOut === undefined) this.data.cashOut = 'No';



        if (this.canEditApp && this.data.id) {
          this.store.updateApplication(this.data)
            .then((app) => {
              this.ngZone.run(() => {
                console.log('Application updated.');
                this.animate = true;
                this.store.updateMasterApplication(this.data)
                  .then((mApp) => {
                    this.ngZone.run(() => {
                      console.log('Master application updated.');
                      setTimeout(() => {
                        this.snackBar.open("Application updated", "Close", { duration: 3000 });
                        if (this.data.dob) this.data.dob = moment(this.data.dob);
                        if (this.data.coDob) this.data.coDob = moment(this.data.coDob);
                        if (this.data.jobStartDate) this.data.jobStartDate = moment(this.data.jobStartDate);
                        // Get applications after the application is saved to the user and master.
                        this.store.createItemsNeeded(this.data);
                        this.store.getApplications();
                        this.store.baseItemsLoanID = this.data.id;
                      });
                    }, 100)
                    setTimeout(() => {
                      this.animate = false;
                      this.isSubmitting = false;
                      this.router.navigate(['account', 'items-needed']);
                    }, 400)
                  }).catch((error) => {
                    console.log("🚀 ~ file: apply.component.ts ~ line 268 ~ ApplyComponent ~ .then ~ error", error);
                    this.isSubmitting = false;
                    this.animate = false;
                    this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
                    console.log("Server error, please let us know if you see this. Error: ", error);
                  });
              }, 100)
            }).catch((error) => {
              console.log("🚀 ~ file: apply.component.ts ~ line 274 ~ ApplyComponent ~ .then ~ error", error);
              this.isSubmitting = false;
              this.animate = false;
              this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
              console.log("Application submission error: ", error);
            });
        } else {
          this.store.createApplication(this.data)
            .then((app) => {
              this.ngZone.run(() => {
                console.log('Application submitted.');
                this.animate = true;
                this.store.createMasterApplication(this.data)
                  .then((mApp) => {
                    this.ngZone.run(() => {
                      console.log('Master application saved.');
                      setTimeout(() => {
                        this.snackBar.open("Application submitted", "Close", { duration: 3000 });
                        if (this.data.dob) this.data.dob = moment(this.data.dob);
                        if (this.data.coDob) this.data.coDob = moment(this.data.coDob);
                        if (this.data.jobStartDate) this.data.jobStartDate = moment(this.data.jobStartDate);
                        // Get applications after the application is saved to the user and master.
                        this.store.createItemsNeeded(this.data);
                        this.store.getApplications();
                        this.store.baseItemsLoanID = this.data.uid;
                      });
                    }, 100)
                    setTimeout(() => {
                      this.animate = false;
                      this.isSubmitting = false;
                      this.router.navigate(['account', 'items-needed']);
                    }, 400)
                  }).catch((error) => {
                    console.log("🚀 ~ file: apply.component.ts ~ line 268 ~ ApplyComponent ~ .then ~ error", error);
                    this.isSubmitting = false;
                    this.animate = false;
                    this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
                    console.log("Server error, please let us know if you see this. Error: ", error);
                  });
              }, 100)
            }).catch((error) => {
              console.log("🚀 ~ file: apply.component.ts ~ line 274 ~ ApplyComponent ~ .then ~ error", error);
              this.isSubmitting = false;
              this.animate = false;
              this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
              console.log("Application submission error: ", error);
            });
        }
      }
      else {
        this.isSubmitting = false;
        this.snackBar.open("Too many applications, contact us.", "Close", { duration: 3000, panelClass: ["info"] });
      }
    }
    else {
      this.scrollIfFormHasErrors(this.appForm).then(() => {
        this.isSubmitting = false;
        this.snackBar.open("Please fill in more information.", "Close", { duration: 3000, panelClass: ["info"] });
        // Run any additional functionality if you need to. 
      });
    }

  }

  async startNewApp() {
    this.data = new ApplicationData();
    this.store.showApps = false;
    this.isAppDisabled = false;
    this.isViewingApp = false;
    this.canEditApp = undefined;
    setTimeout(() => {
      this.scrollTo('userInfo');
    }, 100);
    if (!this.store.isLoadingUser && (this.store.userInfo$?.value.uid != undefined || null)) {
      this.data.firstName = this.store.userInfo$.value.firstName;
      this.data.middleName = this.store.userInfo$.value.middleName;
      this.data.lastName = this.store.userInfo$.value.lastName;
      this.data.phoneNumber = this.store.userInfo$.value.phoneNumber;
      this.data.emailAddress = this.store.userInfo$.value.emailAddress;
    }
  }

  // async viewApp(appData: ApplicationData) {
  //   this.data = appData;
  //   // Need to turn the dob and coDob data from the DB back to a moment object for the Material Datepicker.
  //   if (appData.dob) this.data.dob = moment(appData.dob);
  //   if (appData.coDob) this.data.coDob = moment(appData.coDob);
  //   if (appData.jobStartDate) this.data.jobStartDate = moment(appData.jobStartDate);
  //   this.store.showApps = false;
  //   this.isAppDisabled = true;
  //   this.isViewingApp = true;
  // }

}
