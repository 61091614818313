import { Injectable, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '@firebase/auth-types';
import { BehaviorSubject } from 'rxjs';
import { delay, first, map, retryWhen, take } from 'rxjs/operators';
import { ApplicationData, ItemsNeeded, Rates, UserInfo } from '../models/user.model';
import "firebase/storage";
import * as moment from 'moment';
import { FirebaseErrors } from '../models/error.model';
import { LiveChatWidgetApiModel, LiveChatWidgetModel } from '@livechat/angular-widget';
import { Article } from 'src/app/pages/articles/articles.model';
import { Meta, Title } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // @ViewChildren(MatPaginator) itemsPaginator = new QueryList<MatPaginator>();
  status: any = false;
  pageTitle: string;

  // Live Chat API
  @ViewChild('liveChatWidget', { static: false }) public liveChatWidget: LiveChatWidgetModel;
  liveChatApi: LiveChatWidgetApiModel;
  isLiveChatWidgetLoaded: boolean = false;

  // User Info
  userInfo: any;
  userInfo$ = new BehaviorSubject<UserInfo>(new UserInfo());

  //  User Applications
  userApps: any;
  applications$: BehaviorSubject<any>;
  accAppData$: BehaviorSubject<any>;
  applicationSource: MatTableDataSource<ApplicationData>;
  showApps: boolean = true;
  hasApplications: boolean = true;

  //User Items Needed
  canDownloadAll: boolean = false;
  currentTaxYear = moment().year()
  initItemsNeeded: any[] = [];
  baseItemsLoanID: number | string;
  itemsNeededSource: MatTableDataSource<ItemsNeeded>;
  accItemsNeeded$: BehaviorSubject<any>;
  hasItemsNeeded: boolean = true;
  itemsNeededLength: number;
  uploadProgress: any;
  downloadURL: any;
  task: any;

  // Uploads

  accUploads$: BehaviorSubject<any>;
  uploadsSource: MatTableDataSource<any>;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  // Articles

  articlesSource: MatTableDataSource<any>;
  articles$: BehaviorSubject<any>;
  article$: BehaviorSubject<any>;
  markdownReady: boolean = false;

  // Rates

  ratesSource: MatTableDataSource<any>;
  rate$: BehaviorSubject<any>;
  loanYears: number = 30;

  // Loadings
  isLoadingUser: boolean;
  isLoadingApps: boolean;
  isLoadingItemsNeeded: boolean;
  isLoadingUploads: boolean;
  isLoadingArticles: boolean;
  isLoadingArticle: boolean;
  isLoadingRates: boolean;



  constructor(
    private firestore: AngularFirestore,
    private afStorage: AngularFireStorage,
    // private firestoreFunctions: AngularFireFunctions,
    public snackBar: MatSnackBar,
    private titleService: Title,
    private titlecasePipe: TitleCasePipe,
    private metaTagService: Meta
    // private db: AngularFireDatabase
  ) {

    if ((moment().month()) < 4) {
      this.currentTaxYear = moment().subtract(1, "year").year()
    };
    this.applications$ = <BehaviorSubject<ApplicationData[]>>new BehaviorSubject([]);
    this.accAppData$ = <BehaviorSubject<ApplicationData[]>>new BehaviorSubject([]);
    this.accItemsNeeded$ = <BehaviorSubject<ItemsNeeded[]>>new BehaviorSubject([]);
    this.accUploads$ = <BehaviorSubject<any[]>>new BehaviorSubject([]);
    this.articles$ = <BehaviorSubject<Article[]>>new BehaviorSubject([]);
    this.article$ = <BehaviorSubject<Article[]>>new BehaviorSubject([]);
    this.rate$ = <BehaviorSubject<any[]>>new BehaviorSubject([]);
    this.userInfo$.next(JSON.parse(localStorage.getItem('user')));
    this.initItemsNeeded = [
      {
        "docKey": "two-months-bank-statements",
        "note": "Upload two month's worth of bank statements (all pages). At closing, be prepared to provide your most recently bank statement.",
        "title": "Two months bank statements (all pages)",
        "priority": "high",
        "priorTo": "Locking Interest Rate",
        "isQueued": false,
        "status": "Needed"
      },
      {
        "docKey": "two-most-recent-pay-stubs",
        "isQueued": false,
        "note": "Upload a month's worth of pay stubs (all employers). At closing, be prepared to provide your most recently pay stubs.",
        "status": "Needed",
        "priorTo": "Locking Interest Rate",
        "title": "Two most recent pay stubs",
        "priority": "high"
      },
      {
        "docKey": "taxes",
        "isQueued": false,
        "status": "Needed",
        "priority": "high",
        "priorTo": "Locking Interest Rate",
        "note": "Upload all pages; including, K-1s, and 1099s.",
        "title": this.currentTaxYear + " Taxes (all schedules)"
      },
      {
        "docKey": "w2s",
        "isQueued": false,
        "priorTo": "Locking Interest Rate",
        "note": "Upload the most recent two years of W2s (all employers).",
        "priority": "high",
        "title": moment().subtract(1, "year").year() + " W2s",
        "status": "Needed"
      },
      {
        "docKey": "copy-of-drivers-license",
        "status": "Needed",
        "title": "Copy of Driver's License and Social Security Card",
        "priorTo": "Locking Interest Rate",
        "note": "Make sure the Driver's License is valid. If you do not have a Social Security Card, you can upload a Passport or Green Card (front and back of the Green Card).",
        "isQueued": false,
        "priority": "high"
      }]
  }

  getUser(userId: any) {
    this.isLoadingUser = true;
    // If nothing is passed for startAt, default to 0;
    // if(!startAt) startAt = 0;
    this.firestore.collection('users').doc(userId).collection('userInfo').doc('data').valueChanges().pipe(first()).subscribe((user: UserInfo) => {
      if (user) {
        var clearedUser = new UserInfo();
        this.userInfo$.next(clearedUser);
        this.userInfo$.next(user);
        localStorage.setItem('user', JSON.stringify(this.userInfo$.value));
        this.isLoadingUser = false;
      }
      else {
        this.isLoadingUser = false;
        console.log('Not logged in');
      }
    },
      error => {
        console.log("App get user data error: ", error);
        this.clearUserData();
        // this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
        // this.snackBar.open("Please log in.", "Close", { duration: 3000, panelClass: ["info"] });
        this.isLoadingUser = false;
      });
  }

  getStatus() {
    // If nothing is passed for startAt, default to 0;
    // if(!startAt) startAt = 0;
    return this.firestore.collection("inMaintenance").valueChanges();
  }

  getUserData() {
    console.log("Getting all user data");
    this.getApplications();
    this.getItemsNeeded();
    this.getFileUploads();
  }

  clearUserData() {
    console.log("Clearing user data");
    localStorage.removeItem('user');
    this.userInfo = new UserInfo();
    this.userInfo$.next(this.userInfo);
    this.applications$.next([]);
  }

  createUserInfo(pInfo: UserInfo) {
    // If nothing is passed for startAt, default to 0;
    // if(!startAt) startAt = 0;
    this.userInfo = Object.assign({}, pInfo);
    return this.firestore.collection('users').doc(this.userInfo.uid).collection('userInfo').doc('data').set(this.userInfo);
  }

  async getApplication(id) {
    // If nothing is passed for startAt, default to 0;
    // if(!startAt) startAt = 0;
    return this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('applications').doc(id).valueChanges();
  }

  async getApplications() {
    this.isLoadingApps = true;
    this.applications$.next([]);
    console.log("Getting applications");
    this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('applications', ref => ref.orderBy("lastUpdatedDate", "desc")).valueChanges()
      .pipe(
        first(),
        retryWhen(errors => errors.pipe(delay(500), take(10))),
      ).subscribe(x => {
        if (x && x.length > 0) {
          // this.showApps = true;
          this.hasApplications = true;
          this.applications$.next(x);
          this.applicationSource = new MatTableDataSource(this.applications$.value);
          this.isLoadingApps = false;
        } else {
          // this.showApps = false;
          this.hasApplications = false;
          this.isLoadingApps = false;
          console.log("No applications found for the user.");
        }
      },
        error => {
          console.log("getApplications error: ", error);
          if (error === 'FirebaseError: Missing or insufficient permissions.') {
            // this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
            this.snackBar.open("Please log in.", "Close", { duration: 3000, panelClass: ["info"] });
          }
        });
  }

  async getItemsNeeded() {
    this.itemsNeededLength = null;
    this.itemsNeededSource = new MatTableDataSource();
    this.isLoadingItemsNeeded = true;
    this.accItemsNeeded$.next([]);
    console.log("Getting items needed");
    if (this.userInfo$.value.uid) this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('itemsNeeded', ref => ref.orderBy("status", "desc")).snapshotChanges()
      .pipe(
        first(),
        retryWhen(errors => errors.pipe(delay(500), take(10))),
      ).subscribe(x => {
        var item = x.map(e => {
          const docKey = e.payload.doc.id;
          return { docKey, ...e.payload.doc.data() as {} }
        });
        const items = this.accItemsNeeded$.value.concat(item)
        if (x?.length > 0) {
          this.isLoadingItemsNeeded = false;
          this.hasItemsNeeded = true;
          this.accItemsNeeded$.next(items);
          this.accItemsNeeded$.value.map((d: ItemsNeeded) => {
            if (d.status === 'Needed' || d.status === 'Rejected') this.itemsNeededLength++;
          });

          this.accItemsNeeded$.pipe(
            map(x => {
              x.map(f => {
                if (f && f.attachedFiles?.length > 0) {
                  this.canDownloadAll = true;
                  return;
                }
              })
            })
          ).subscribe();
          this.itemsNeededSource = new MatTableDataSource(this.accItemsNeeded$.value);
          return true;
        } else {
          this.hasItemsNeeded = false;
          this.isLoadingItemsNeeded = false;
          console.log("No applications found for the user.");
          return false;
        }
      },
        error => {
          console.log("getItemsNeeded: ", error);
          if (error = "FirebaseError: Missing or insufficient permissions.") {
            // this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
            this.snackBar.open("Please log in.", "Close", { duration: 3000, panelClass: ["info"] });
          }
        });
  }

  async updateItemsNeeded(itemData: ItemsNeeded) {
    console.log("Updating items needed");
    return this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('itemsNeeded').doc(itemData.docKey).update(itemData)
      .then((res) => {
        this.snackBar.open('Item updated', "Close", { duration: 3000 });
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log("🚀 ~ file: user.service.ts ~ line 236 ~ UserService ~ updateItemsNeeded ~ error", error);
        this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
      });
  }

  async createItemsNeeded(application?: ApplicationData) {

    var itemData = this.initItemsNeeded;
    console.log("Creating items needed");
    if (application.rentOrOwn === 'Own') itemData.push(
      {
        "docKey": "mortgage-statement",
        "status": "Needed",
        "title": "Current Mortgage Statement",
        "priorTo": "Locking Interest Rate",
        "note": "Mortgage statements can be on legal-sized paper. make sure the entire document is shown.",
        "isQueued": false,
        "priority": "high"
      });
    itemData.map((x, index) => {
      if (this.baseItemsLoanID) x.loanID = this.baseItemsLoanID;
      x.attachedFiles = [];
      x.status = 'Needed';
      x.requestedDate = moment(new Date()).format('MM/DD/YYYY');
      x.dueDate = moment(new Date()).add(2, 'days').format('MM/DD/YYYY');
      x.borrower = application.firstName + ' ' + application.lastName;
      if (application.hasCoBorrower) x.coBorrower = application.coFirstName + ' ' + application.coLastName;
      // Check if the user has items needed already. If the user does have items needed, update the items to blank.
      if (this.accItemsNeeded$?.value.length > 0) {
        console.log("Updating items needed");
        this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('itemsNeeded').doc(x.docKey).update(x);
      }
      // If not, we need to create the items needed for the user.
      else {
        console.log("Creating items needed");
        this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('itemsNeeded').doc(x.docKey).set(x);
      }


      // if (index === itemData.length) this.getItemsNeeded();
    })
  }


  createApplication(application: ApplicationData) {
    console.log("🚀 ~ file: user.service.ts ~ line 350 ~ UserService ~ createApplication ~ application", application);
    var app = Object.assign({}, application);
    console.log("🚀 ~ file: user.service.ts ~ line 351 ~ UserService ~ createApplication ~ app", app);
    return this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('applications').doc(app.id).set(app);
  }

  updateApplication(application: ApplicationData) {
    var app = Object.assign({}, application);
    return this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('applications').doc(app.id).update(app);
  }

  createMasterApplication(application: ApplicationData) {
    var app = Object.assign({}, application);
    return this.firestore.collection('applications').doc(this.userInfo$.value.uid).collection('applications').doc(app.id).set(app);
  }

  updateMasterApplication(application: ApplicationData) {
    var app = Object.assign({}, application);
    return this.firestore.collection('applications').doc(this.userInfo$.value.uid).collection('applications').doc(app.id).update(app);
  }

  updateApp(application: ApplicationData, user: User) {
    this.snackBar.open("Application submitted", "Close", { duration: 3000 });
    // delete item.key;
    // this.firestore.doc('users' + '/' + user.uid + '/' + 'applications' + '/' + application.id).update(application);
  }

  uploadDocToDB(file) {
    var item = Object.assign({}, file);
    if (!item.fileForItem) item.fileForItem = "General";
    return this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('uploads').doc(item.originalName).set(item);
  }

  uploadDocToMasterDB(file) {
    var item = Object.assign({}, file);
    if (!item.fileForItem) item.fileForItem = "General";
    return this.firestore.collection('uploads').doc(item.originalName).set(item);
  }

  // batchUpdate(items) {
  //   var batch = this.firestore.firestore.batch();
  //   items.map((bItem) => {
  //     var userRef = this.firestore.collection(this.ladderSelected + '/').doc(bItem.docKey).ref;
  //     batch.update(userRef, bItem);
  //   })
  //   return batch.commit();
  // }

  // deleteItem(docKey: string, item) {
  //   this.snackBar.open("Deleted: " + item.title + ".", "Close", { duration: 3000, panelClass: ['info'] });
  //   this.firestore.doc(this.ladderSelected + '/' + docKey).delete();
  // }

  uploadDoc(document) {
    // const meta = {};
    // meta['Content-Disposition'] = 'attachment';
    // var newMetadata = {
    //   customMetadata: {
    //     expires: Date.now() + 1000 * 60 * 60 * 24 * 182.5, // 1/2 Year
    //     metadata: meta,
    //   }
    // };
    if (this.userInfo$.value.uid) {
      const fileRef: any = this.afStorage.ref(`mortgage-partner/users/${this.userInfo$.value.uid}/${document.file.name}`);
      this.task = fileRef.put(document.file.rawFile);
    }
    else {
      const fileRef: any = this.afStorage.ref(`mortgage-partner/anonymous/${document.file.name}`);
      this.task = fileRef.put(document.file.rawFile);
    }
    return this.task
  }

  uploadDocToMaster(document) {
    const fileRef: any = this.afStorage.ref(`mortgage-partner/users/${this.userInfo$.value.uid}/${document.file.name}`);
    this.task = fileRef.put(document.file.rawFile);
    return this.task.percentageChanges();
  }

  async getFileUploads() {
    this.isLoadingUploads = true;
    this.accUploads$.next([]);
    console.log("Getting uploads");
    this.firestore.collection('users').doc(this.userInfo$.value.uid).collection('uploads', ref => ref.orderBy("lastModified", "desc")).snapshotChanges()
      .pipe(
        first(),
        retryWhen(errors => errors.pipe(delay(500), take(10))),
      ).subscribe(x => {
        var item = x.map(e => {
          const docKey = e.payload.doc.id;
          return { docKey, ...e.payload.doc.data() as {} }
        });
        const items = this.accUploads$.value.concat(item)
        if (x && x.length > 0) {
          this.accUploads$.next(items);
          this.uploadsSource = new MatTableDataSource(this.accUploads$.value);
          this.isLoadingUploads = false;
        } else {
          // this.showApps = false;
          this.isLoadingUploads = false;
          console.log("No uploads found for the user.");
        }
      },
        error => {
          console.log("getFileUploads error: ", error);
          if (error === 'FirebaseError: Missing or insufficient permissions.') {
            // this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
            this.snackBar.open("Please log in.", "Close", { duration: 3000, panelClass: ["info"] });
          }
        });
  }

  // async getFileUploads() {
  //   console.log("Getting uploads");
  //   const fileRef: any = firebase.storage().ref();
  //   var listRef = fileRef.child(`mortgage-partner/users/` + this.userInfo$.value.uid);
  //   return listRef.listAll()
  //     .then((res) => {
  //       // console.log("🚀 ~ file: user.service.ts ~ line 231 ~ UserService ~ res.items.forEach ~ res.items", res.items);
  //       res.prefixes.forEach((folderRef) => {
  //         // All the prefixes under listRef.
  //         // You may call listAll() recursively on them.
  //       });
  //       res.items.forEach((itemRef) => {
  //         itemRef.pipe(untilDestroyed(this)).subscribe(x => {
  //           console.log("🚀 ~ file: user.service.ts ~ line 346 ~ UserService ~ itemRef.pipe ~ x", x);
  //           const items = this.accUploads$.value.concat(x);
  //           this.accUploads$.next(items);
  //           console.log("🚀 ~ file: user.service.ts ~ line 352 ~ UserService ~ itemRef.pipe ~ this.accUploads$.value", this.accUploads$.value);
  //         })
  //         // All the items under listRef.
  //       });
  //     }).catch((error) => {
  //       // Uh-oh, an error occurred!
  //     });
  //   // return something
  // }

  customMetadata() {
    // [START storage_custom_metadata]
    var metadata = {
      customMetadata: {
        'location': 'Yosemite, CA, USA',
        'activity': 'Hiking'
      }
    };
    // [END storage_custom_metadata]
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }


  // Articles

  async getArticles() {

    // featuredArticles$: BehaviorSubject<any>;
    // articles$: BehaviorSubject<any>;
    this.isLoadingArticles = true;
    this.articlesSource = new MatTableDataSource();
    this.articles$.next([]);
    console.log("Getting articles");

    return this.firestore.collection("articles").snapshotChanges()
      .pipe(
        first(),
        retryWhen(errors => errors.pipe(delay(500), take(10))),
      ).subscribe(x => {
        var article = x.map(e => {
          const docKey = e.payload.doc.id;
          return { docKey, ...e.payload.doc.data() as {} }
        });
        const articles = this.articles$.value.concat(article)
        if (x && x.length > 0) {
          this.isLoadingArticles = false;
          
          var userFavoritedArticles = JSON.parse(localStorage.getItem('favoritedArticles'))
          if (userFavoritedArticles?.length > 0) {
              userFavoritedArticles.map((storedData) => {
                articles.map((article) => {
                  if (article.id == storedData) article.isFavorite = true;
                })
              })
          }
          this.articles$.next(articles);
          this.articlesSource = new MatTableDataSource(this.articles$.value);
        } else {
          this.isLoadingArticles = false;
          console.log("No articles.");
        }
      },
        error => {
          console.log("getArticles error: ", error);
          if (error === 'FirebaseError: Missing or insufficient permissions.') {
            // this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
            this.snackBar.open("Please log in.", "Close", { duration: 3000, panelClass: ["info"] });
          }
        });
  }

  async getArticle(id) {
    this.isLoadingArticle = true;
    var newArticle = new Article()
    this.article$.next(newArticle);
    // If nothing is passed for startAt, default to 0;
    // if(!startAt) startAt = 0;
    this.firestore.collection('articles').doc(id).valueChanges().pipe(first()).subscribe((article: Article) => {
      article.title = this.titlecasePipe.transform(article.title)
      console.log("🚀 ~ file: user.service.ts ~ line 550 ~ UserService ~ this.firestore.collection ~ article", article);
      this.article$.next(article);
      this.article$.value.urlPath = id;
      this.titleService.setTitle('Mortgage Partner - ' + this.article$.value.title);
      this.metaTagService.updateTag({ name: 'description', content: article.snapshot });
      this.metaTagService.updateTag({ name: 'date', content: article.date });
      this.metaTagService.updateTag({ name: 'og:type', content: 'article' });
      this.metaTagService.updateTag({ name: 'og:title', content: article.title });
      this.metaTagService.updateTag({ name: 'og:url', content: 'https://mortgagepartner.com/articles/' + article.urlPath });
      this.metaTagService.updateTag({ name: 'og:image', content: article.imgUrl });
      // this.article.htmlMarkDown = this.markdownService.compile(article.htmlMarkDown);
      this.markdownReady = true;
      this.isLoadingArticle = false;
    },
      error => {
        console.log("getArticle error: ", error);
        this.isLoadingArticle = false;
        console.log("🚀 ~ file: article.component.ts ~ line 35 ~ ArticleComponent ~ .then ~ error", error)
        this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
      });
  }

  sendEmail() {

  }

  getRates(loanLength: number) {
    var moment = require('moment-timezone');
    var userTimeZone = moment.tz.guess(true);
    var loanDays = this.loanYears * 365;
    var loanLengthString = loanLength.toString() + 'yr';
    this.isLoadingRates = true;
    this.rate$.next([]);
    this.firestore.collection('rates').doc(loanLengthString).valueChanges().pipe(first()).subscribe((rate: Rates) => {
      rate.rateAsOf = moment(rate.rateAsOf).format('LLLL') + ' ' + moment.tz(userTimeZone).format('z');
      loanLength = loanLength * 12;

      rate.downPaymentBalance = rate.loanAmount - rate.downPayment;
      rate.monthlyInterest = rate.rate / 1200;
      // rate.monthlyPayment = Math.floor((rate.downPaymentBalance * ((rate.monthlyInterest * (1 + rate.monthlyInterest ** 360)) / ((1 + rate.monthlyInterest) ** 360 - 1))) * 100) / 100;
      rate.monthlyPayment = (rate.downPaymentBalance * rate.monthlyInterest) / (1 - (Math.pow((1 + rate.monthlyInterest), loanLength * -1)));
      // rate.monthlyPayment = rate.monthlyPayment.toFixed(2);

      rate.aprInterest = (rate.loanAmount) - (rate.loanAmount * (((rate.rate / 100) * 30)));

      // var aprInterest = (rate.loanAmount * (1+ ((rate.rate / 100) * 360))) - (rate.loanAmount);
      console.log("🚀 ~ file: user.service.ts ~ line 573 ~ UserService ~ this.firestore.collection ~ aprInterest", rate.aprInterest);
      var apr = ((rate.lenderFees + rate.monthlyInterest) / rate.downPaymentBalance) / loanDays * 365 * 100;
      var finalRate = rate.rate + apr;
      rate.finalRate = Math.floor(finalRate * 100) / 100; // Round the final apr interest rate to the second decimal.
      this.rate$.next([]);
      this.rate$.next(rate);
      this.isLoadingRates = false;
    },
      error => {
        console.log("getRates error: ", error);
        this.isLoadingRates = false;
        this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
      });
    return this.firestore.collection('rates').doc('30yr').valueChanges();
  }


  openLiveChat(): void {
    // Only run if the live chat is loaded
    if (this.isLiveChatWidgetLoaded) {
      // Open the live chat window.
      this.liveChatApi.open_chat_window();
      this.liveChatWidget.openChatWindow();

      // You can also use methods directly on liveChatApi instance
      // for more details please read our documentation 
      // https://developers.livechatinc.com/docs/extending-ui/extending-chat-widget/javascript-api/#methods
      // this.liveChatApi.open_chat_window();
    };
  }
}
