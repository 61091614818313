<div class="image-standout">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">Current Utah Mortgage Rates For {{currentMonth}}</h1>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400 mb-0">Today's low mortgage rates.
        <a mat-link href="javascript:;" style="color: #ffffff;" (click)="showLegal()" class="prt--03">†</a>
      </p>
    </div>
  </div>
</div>
<div class="grid-container">
  <!-- Subsection 5: Stand Out -->
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h2 class="mb-05 font-weight-400 text-center">{{store.rate$.value.finalRate}}%</h2>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h2 class="mb-05 font-weight-400 text-center">{{store.rate$.value.monthlyPayment | currency:'USD'}}</h2>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p class="disclosure-text">Mortgage rates valid as of {{store.rate$.value.rateAsOf}} and assume borrower has excellent credit (including a credit score of 760 or higher). Rates based on a
      {{store.rate$.value.loanAmount | currency:'USD':'symbol-narrow':'4.0-0'}} loan with a {{store.rate$.value.ltv}}%
      LTV (Loan to value) and using a {{store.rate$.value.zipCode}} zip code. Rate data is for <strong>illustrative purposes only</strong> and is subject to change without notice.</p>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <small>
      <a mat-link href="javascript:;" (click)="showLegal()">Important rate and payment information
        <span class="prt--03">†</span></a>
    </small>
  </div>
  <div #legalInfo></div>
  <ng-container *ngIf="showRateLegal">
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start center" [@400]="'fadeIn'">
      <p class="disclosure-text">Advertised rate, points, and APR are based on a set of loan assumptions (refer Loan assumptions and disclosures above for
        important
        information). Your actual
        rate and APR may differ from the rate data. Rate accuracy is not guaranteed and products may not be available for your situation. Advertised rate assumes escrow accounts (monthly collection of subject property taxes and any applicable
        homeowners insurance with your monthly principal and interest payment) unless you request otherwise and the loan program and applicable law allows. Should you choose to waive escrows, your rate, costs and/or APR may increase</p>
    </div>
  </ng-container>
</div>

<div class="grid-container">
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h2 class="mb-05 font-weight-400 text-center">Mortgage Loan <span class="light-primary">Calculator</span></h2>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h5 class="mb-05 font-weight-400 text-center">See how much your monthly payments would be below</h5>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100">
      <mat-card class="mat-elevation-z6">
        <br />
        <div fxLayoutAlign="start center" fxLayout="row wrap">
          <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="25" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.loanType}">
            <mat-label>Loan type</mat-label>
            <mat-select tabindex="0" id="loanType" placeholder="Loan type" name="loanType" required #loanLength="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.loanType" (ngModelChange)="calcMonthlyPayment(data)" ngDefaultControl>
              <mat-option value="select" selected="selected" disabled="disabled">Choose the length of the loan</mat-option>
              <mat-option value="30">30 year</mat-option>
              <mat-option value="15">15 year</mat-option>
            </mat-select>
            <mat-error *ngIf="loanLength.hasError('required')">Loan type is required</mat-error>
          </mat-form-field>
          <div fxFlex="15px" class="mb-15 text-center"> | </div>
          <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="25" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.homePrice}">
            <mat-label>Home price</mat-label>
            <mat-icon matPrefix>attach_money</mat-icon>
            <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="375000" id="homePrice" name="homePrice" #hPrice="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.homePrice"
              (ngModelChange)="calcMonthlyPayment(data)" minlength="1" maxlength="10">
            <mat-error *ngIf="hPrice.hasError('required')">Home price is required.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="25" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.downPayment}">
            <mat-label>Down payment</mat-label>
            <mat-icon matPrefix>attach_money</mat-icon>
            <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="75000" id="downPayment" name="downPayment" #dPayment="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.downPayment"
              (ngModelChange)="calcMonthlyPayment(data)" minlength="1" maxlength="10">
            <mat-error *ngIf="dPayment.hasError('required')">Down payment is required.</mat-error>
          </mat-form-field>
          <!-- <div fxFlex="15px" class="mb-15 text-center"> = </div> -->
          <mat-form-field appearance="outline" fxFlex="90px" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.downPaymentPercentage}">
            <!-- <mat-label>%</mat-label> -->
            <span matSuffix>%</span>
            <!-- <mat-icon [inline]="true" matSuffix>%</mat-icon> -->
            <input matInput tabindex="0" inputmode="numeric" type="text" placeholder="20" id="downPaymentPercentage" name="downPaymentPercentage" #pDown="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.downPaymentPercentage"
              (ngModelChange)="calcDownPaymentPercentage(data)" minlength="1" maxlength="3">
            <mat-error *ngIf="pDown.hasError('required')">Percentage down is required.</mat-error>
          </mat-form-field>
        </div>
        <ng-container *ngIf="showMoreOptions">
          <br />
          <div fxLayoutAlign="start center" fxLayout="row wrap" [@400]="'fadeIn'">
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="15" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.mortgageRate}">
              <mat-label>Mortgage rate</mat-label>
              <span matSuffix>%</span>
              <input matInput tabindex="0" inputmode="numeric" type="text" placeholder="{{data.mortgageRate}}" id="mortgageRate" name="mortgageRate" #mRate="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.mortgageRate"
                (ngModelChange)="calcMonthlyPayment(data)" minlength="1" maxlength="5">
              <mat-error *ngIf="mRate.hasError('required')">Mortgage rate is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="21" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.homeInsurance}">
              <mat-label>Home insurance</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <small matSuffix>/yr</small>
              <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="1125" id="homeInsurance" name="homeInsurance" #hInsurance="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                [(ngModel)]="data.homeInsurance" (ngModelChange)="calcMonthlyPayment(data)" minlength="1" maxlength="10">
              <mat-error *ngIf="hInsurance.hasError('required')">Home insurance is required.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="20" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.propertyTaxes}">
              <mat-label>Property taxes</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <small matSuffix>/yr</small>
              <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="0" id="propertyTaxes" name="propertyTaxes" #pTaxes="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                [(ngModel)]="data.propertyTaxes" (ngModelChange)="calcMonthlyPayment(data)" minlength="1" maxlength="10">
              <mat-error *ngIf="pTaxes.hasError('required')">Property taxes is required.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="20" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.hoaFee}">
              <mat-label>HOA fee</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <small matSuffix>/month</small>
              <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="75000" id="hoaFee" name="hoaFee" #pTaxes="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.hoaFee"
                (ngModelChange)="calcMonthlyPayment(data)" minlength="1" maxlength="10">
            </mat-form-field>
            <div fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="24" class="ph-05">
              <div fxLayoutAlign="start center" fxLayout="row wrap">
                <mat-checkbox class="mr-15" name="rTemp" id="rTemp" #rTemp="ngModel" [(ngModel)]="includeInsurance">Include PMI/MI</mat-checkbox>
              </div>
            </div>
          </div>

        </ng-container>


        <div fxLayoutAlign="start center" fxLayout="row wrap">
          <button mat-button type="button" (click)="showOptions()">
            <mat-icon class="material-icon-outlined">{{showMoreOptions ? 'remove_circle_outline' : 'add_circle_outline'}}</mat-icon> {{showMoreOptions ? 'Hide more' : 'More'}} options
          </button>
        </div>
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
          <h5>Your monthly payment will be:</h5>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
          <h2 class="mb-05 text-center payment-text">{{data.monthlyPayment | currency:'USD'}}</h2>
        </div>
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h5>Let's started on your mortgage loan</h5>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-10">
          <div wmAnimate="fadeInLeft" speed="fast" aos>
            <a mat-raised-button color="primary" rel="noopener" [routerLink]="['../', 'apply' ]">Start my application</a>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <div wmAnimate="fadeInLeft" speed="fast" aos>
            <a mat-button rel="noopener" [routerLink]="['../', 'contact' ]">Contact us</a>
          </div>
        </div>
        <br />
      </mat-card>
    </div>
  </div>


</div>
<div class="social-standout">
  <div class="grid-container">
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <mat-divider fxFlex="50"></mat-divider>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <h2 class="mb-05 font-weight-400">Social Media</h2>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <p>Connect with us on social media.</p>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">

      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social" /></a>

    </div>
    <br />
    <br />
    <br />
  </div>
</div>
