<div class="hero">
  <div class="grid-container mt-0 mb-0">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex.xs="100" fxFlex.gt-xs="75" fxFlex.gt-lg="80" class="hero-text-backdrop">
        <div fxLayout="row wrap" fxLayoutAlign="center center" wmAnimate="fadeInUp" speed="faster" aos>
          <!-- <h1 class="text-center">Utah's #1 Mortgage Company</h1> -->
          <h1 class="font-weight-400 text-center">Who We Are</h1>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center" wmAnimate="fadeInUp" speed="fast" aos>
          <p class="font-weight-400 text-center">The Mortgage Partner has been providing mortgage loans in Utah since 1993. Our Utah based company has over 40 years of combined lending experience. We are a mortgage broker which allows us the
            opportunity
            to work with multiple lenders&mdash;getting you the best rate possible.</p>
        </div>
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="7.5px">
          <div wmAnimate="fadeInUp" speed="fast" aos>
            <chat-button matType="raised" matColor="primary" buttonText="Talk to a loan expert"></chat-button>
            <!-- <a mat-raised-button style="font-size: 16px;" color="primary" href="tel:8013020100">Talk to a loan expert</a> -->
          </div>
          <div wmAnimate="fadeInUp" speed="fast" aos>
            <a mat-button color="white" class="with-border" rel="noopener" [routerLink]="['../', 'apply' ]">Apply online</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid-container">
  <!-- Subsection 5: Stand Out -->
  <br />
  <br />
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h3 class="mb-05 font-weight-400 text-center">What Does It Mean To Be A Mortgage Broker?</h3>
  </div>
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <p class="font-weight-400 text-center" fxFlex.xs="100" fxFlex.gt-xs="80" fxFlex.gt-md="70">Being a mortgage broker in Utah gives us the ability to access many different programs and secure the best rate for our clients. This includes allowing us
      to shop around many different lenders and title companies to get you your perfect mortgage loan.</p>
  </div>
  <br />
  <br />
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="33.333">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <mat-icon class="material-icons-outlined mat-64">meeting_room</mat-icon>
      </div>
      <br />
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <h4 class="font-weight-400">Access</h4>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <p class="pl-15 pr-15">As a mortgage broker, we have access to many different lenders that others&mdash;bankers&mdash;aren't able to access. When going through a bank or credit union, if you don't qualify for their loan, they turn you down
          and you have to start over.</p>
      </div>
    </div>

    <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="33.333">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <mat-icon class="material-icons-outlined mat-64">person</mat-icon>
      </div>
      <br />
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <h4 class="font-weight-400">Low Rates</h4>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <p class="pl-15 pr-15">We are able to offer low rates by shopping around for you. What does that mean? We work with many lending companies within the mortgage industry and visit each one to find you the best rate possible. Give us a call, we
          are eager to save you money.</p>
      </div>
    </div>

    <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="33.333">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <mat-icon class="material-icons-outlined mat-64">business</mat-icon>
      </div>
      <br />
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <h4 class="font-weight-400">Salaried Loan Officers</h4>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <p class="pl-15 pr-15">Looking to save even more on your mortgage loan in Utah? We've got you covered. Our salaried Loan Officers are looking forward to helping you and your family save money, guaranteed.</p>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>
<div class="light-bg">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h3 class="mb-05 font-weight-400 text-center">Who We Work With</h3>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400 text-center" fxFlex.xs="100" fxFlex.gt-xs="80" fxFlex.gt-md="50">We work with everyone. No matter your credit score, income, loan amount, etc. we are happy to work with you.</p>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="7.5px">
      <div wmAnimate="fadeInUp" speed="fast" aos>
        <chat-button matType="raised" matColor="accent" buttonText="Talk to a loan officer"></chat-button>
        <!-- <a mat-raised-button style="font-size: 16px;" color="accent" href="tel:8013020100">Talk to a loan officer</a> -->
      </div>
      <div wmAnimate="fadeInUp" speed="fast" aos>
        <a mat-button class="with-border" rel="noopener" [routerLink]="['../', 'apply' ]">Apply online</a>
      </div>
    </div>
    <br />
    <br />
  </div>
</div>
<!-- Testimonials section -->
<div class="pv-40 testimonials">
  <div class="grid-container grid-container_full pv-0 mt-0 mb-0">
    <br />
    <br />
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="grid-container pv-0 mb-0">
        <br />
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <h2 class="font-weight-600 mb-0 text-center mb-50" style="color: #9f445d; font-size: 2.715em;">Getting <u>You</u> Utah's Best Mortgage Rates</h2> -->
          <h3 class="mb-05 font-weight-400 text-center">What People Say About Us</h3>
        </div>
        <br />
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(100% - 100px)" fxFlexOrder.lt-md="1" fxFlexOrder.gt-sm="2">
            <!-- Testimonials -->
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
            </div>
            <ng-container *ngFor="let testimonial of testimonials; let i = index">
              <ng-container *ngIf="i === showTestimonialIndex">

                <div wmAnimate="slideInRight" speed="fast" aos>
                  <br />
                  <blockquote>{{testimonial.quote}}</blockquote>
                  <cite>{{testimonial.name}}</cite>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div fxFlex.lt-md="50" fxFlex.gt-sm="40px" fxFlexOrder.lt-md="2" fxFlexOrder.gt-sm="1">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <button mat-icon-button style="position: relative; overflow: hidden;" (click)="prevTestimonial()">
                <mat-icon class="material-icons-outlined text-muted">chevron_left</mat-icon>
              </button>
            </div>
          </div>
          <div fxFlex.lt-md="50" fxFlex.gt-sm="40px" fxFlexOrder="3">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <button mat-icon-button style="position: relative; overflow: hidden;" (click)="nextTestimonial()">
                <mat-icon class="material-icons-outlined text-muted">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="15px">
          <img class="equal-housing-icon" src="../../../assets/images/equal-housing-house.svg" alt="Mortgage Partner service Salt Lake City, Lehi, Riverton, and Utah is an equal housing lender" />
          <strong class="text-muted">Equal Housing Lender <br /> <small class="text-muted">NMLS # 3123</small></strong>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid-container">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-divider fxFlex="40"></mat-divider>
  </div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
    <h3 class="mb-05 font-weight-400">Meet Our Team</h3>
  </div>
  <!-- <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
    <p>Our team is great.</p>
  </div> -->
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="15px">
    <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(50% - 15px)" fxFlex.gt-md="calc(50% - 15px)">
      <mat-card class="m-15 mat-elevation-z0">
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <div fxFlex="160px">
            <div class="team-header-img-container">
              <!-- Probably will need Cloundinary for this to make it easily dynamic -->
              <img src="../../../assets/images/penny_arens.jpg" alt="Penny Arens is the Principle Lending Manager at The Mortgage Partner, service all of Utah." class="team-header-img" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h4 class="font-weight-400">Penny Arens</h4>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="pl-15 pr-15 mb-05" style="font-size: 1rem;">Principle Lending Manager</p>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="pl-15 pr-15 mb-0 text">Sr. Loan Officer</p>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="mb-0 font-weight-400" style="font-size: 0.750rem;"><small class="font-weight-400">NMLS # 51126</small></p>
        </div>
      </mat-card>
    </div>
    <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(50% - 15px)" fxFlex.gt-md="calc(50% - 15px)">
      <mat-card class="m-15 mat-elevation-z0">
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <div fxFlex="160px">
            <div class="team-header-img-container">
              <!-- Probably will need Cloundinary for this to make it easily dynamic -->
              <img src="../../../assets/images/tyler-arens.jpg" alt="Tyler Arens is a loan officer at The Mortgage Partner in Riverton, Utah." class="team-header-img" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h4 class="font-weight-400">Tyler Arens</h4>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="pl-15 pr-15 mb-0" style="font-size: 1rem;">Loan Officer</p>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="mb-0 font-weight-400" style="font-size: 0.750rem;"><small class="font-weight-400">NMLS # 1982509</small></p>
        </div>
        <br />
      </mat-card>
    </div>
  </div>
  <br />
</div>
<div class="social-standout">
  <div class="grid-container">
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <mat-divider fxFlex="50"></mat-divider>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <h2 class="mb-05 font-weight-400">Social Media</h2>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <p>Connect with us on social media.</p>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">

      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social" /></a>

    </div>
    <br />
    <br />
    <br />
  </div>
</div>
