<div class="image-standout">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">Contact The Mortgage Partner</h1>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400">Have a question? We're here to help!</p>
    </div>
  </div>
</div>
<div class="grid-container ph-15 ph-10-sm">
  <!-- Subsection 5: Stand Out -->
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.xs="0px" fxLayoutGap.gt-sm="15px">
    <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(50% - 7.5px)" class="mt-30 mb-30">
      <!-- Business Info -->
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <h3 class="mb-05 font-weight-400 text-center">Business Info</h3>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="54px">
              <mat-icon class="material-icons-outlined mat-18 ml-15 mr-15">business</mat-icon>
            </div>
            <div fxFlex="calc(100% - 54px)">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <p class="mb-05 font-weight-400">
                  <a mat-link class="text-primary" target="_blank" rel="noopener"
                    href="https://www.google.com/maps/place/The+Mortgage+Partner+Inc/@40.5156562,-111.9464016,15z/data=!4m5!3m4!1s0x0:0x9ad9404e969358a4!8m2!3d40.5143512!4d-111.939106">
                    13032 South Redwood Rd., Suite 3,<br />
                    Riverton, UT 84065</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="54px">
              <mat-icon class="material-icons-outlined mat-18 ml-15 mr-15">schedule</mat-icon>
            </div>
            <div fxFlex="calc(100% - 54px)">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <p class="mb-05 font-weight-400">Monday - Friday: 9am-5pm MST</p>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <p class="mb-0"><small>Emails are monitored outside of regular business hours&mdash;for time-sensitive items.</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="54px">
              <mat-icon class="material-icons-outlined mat-18 ml-15 mr-15">admin_panel_settings</mat-icon>
            </div>
            <div fxFlex="calc(100% - 54px)">
              <p class="mb-0 font-weight-400">NMLS # 3123</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(50% - 7.5px)" class="mt-30 mb-30">
      <!-- Contact Us -->
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <h3 class="mb-05 font-weight-400 text-center">Contact Info</h3>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="54px">
              <mat-icon class="material-icons-outlined mat-18 ml-15 mr-15 prt-03">phone</mat-icon>
            </div>
            <div fxFlex="calc(100% - 54px)">
              <p class="mb-0 font-weight-400">
                <a mat-link href="tel:8013020100" class="text-primary ml-15">801-302-0100</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="54px">
              <mat-icon class="material-icons-outlined mat-18 ml-15 mr-15 prt-03">email</mat-icon>
            </div>
            <div fxFlex="calc(100% - 54px)">
              <p class="mb-0 font-weight-400">
                <a mat-link href="mailto:tyler@mortgagepartner.com" class="text-primary ml-15" rel="noopener">
                  tyler@mortgagepartner.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="54px">
              <mat-icon class="material-icons-outlined mat-18 ml-15 mr-15 prt-03">live_help</mat-icon>
            </div>
            <div fxFlex="calc(100% - 54px)">
              <div class="ml-15">
                <!-- <button mat-raised-button color="primary" (click)="store.openLiveChat()">Chat with us!</button> -->
                <chat-button matType="raised" matColor="primary" buttonText="Chat with us!"></chat-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid-container ph-15 ph-10-sm">
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
    <a fxFlex="100" class="m-15" target="_blank" rel="noopener"
      href="https://www.google.com/maps/place/The+Mortgage+Partner+Inc/@40.5156562,-111.9464016,15z/data=!4m5!3m4!1s0x0:0x9ad9404e969358a4!8m2!3d40.5143512!4d-111.939106">
      <mat-card class="mat-elevation-z6 g-maps-screenshot clickable clickable_hover" matRipple>

      </mat-card>
    </a>
  </div>
</div>
<div class="social-standout">
  <div class="grid-container">
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <mat-divider fxFlex="50"></mat-divider>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <h2 class="mb-05 font-weight-400">Social Media</h2>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <p>Connect with us on social media.</p>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">

      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social" /></a>

    </div>
    <br />
    <br />
    <br />
  </div>
</div>
