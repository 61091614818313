<div class="light-bg">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">Mortgage Partner Privacy Policy</h1>
    </div>
    <br />
    <br />
    <br />
  </div>
</div>

<div class="grid-container">
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      By using MortgagePartner.com, including requesting more information, or applying for a loan,
      you may receive phone calls, recorded phone messages, emails, text messages, and mailed
      communication, if you have provided a cell phone in the contact information. Regular cell phone
      charges may apply. If you no longer wish to receive communication from us, you can call us at
      801-302-0100.
      <br /><br /> During the process of serving our customers, and with your permission, we share
      your personal information to third parties, in regard to a service provider in conjunction to
      closing your loan, such as, but not limited to, an appraiser, or title company.
      <br /><br /> Our website also uses cookies, tracking pixels and related technologies. Cookies
      are small data files that are served by our platform and stored on your device. Our site uses
      cookies dropped by us or third parties for a variety of purposes including to operate the
      website. Also, cookies may also be used to track how you use the site to target ads to you on
      other websites.
    </p>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      General
    </h3>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      This privacy notice discloses the privacy practices for The Mortgage Partner, Inc.. This privacy
      notice applies solely to information collected by this web site. It will notify you of the
      following:
    </p>
    <ol class="pl-20">
      <li>
        What personally identifiable information is collected from you through the web site, how it
        is used and with whom it may be shared.
      </li>
      <li>
        What choices are available to you regarding the use of your data.
      </li>
      <li>
        The security procedures in place protect the misuse of your information.
      </li>
      <li>
        How you can correct any inaccuracies in the information.
      </li>
    </ol>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      Information Collection, Use, and Sharing
    </h3>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      We are the sole owners of the information collected on this site. We only have access
      to/collect information that you voluntarily give us via email or other direct contact from
      you. We will not sell or rent this information to anyone.
      <br /><br /> We will use your information to respond to you, regarding the reason you
      contacted us. We will not share your information with any third party outside of our
      organization.
      <br /><br /> Unless you ask us not to, we may contact you via email in the future to tell
      you about specials, new products or services, or changes to this privacy policy.
    </p>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      Your Access to and Control Over Information
    </h3>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      You may opt out of any future contacts from us at any time. You can do the following at any
      time by contacting us via the email address or phone number given on our website <a rel="noopener" [routerLink]="[ 'contact-us' ]"><i>here</i></a>.
      <br /><br />Don't hesitate to contact us about the information we have about you. Below are
      some examples of inquiries.
    </p>
    <ul>
      <li>
        To see what data we have about you, if any, or change/correct any data we have about
        you.
      </li>
      <li>
        Have us delete any data we have about you.
      </li>
      <li>
        Express any concern you have about our use of your data.
      </li>
    </ul>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      Security
    </h3>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>We take precautions to protect your information. When you submit sensitive information via
      the website, your information is protected both online and offline.
      <br /><br />Wherever we collect sensitive information (such as credit card data), that
      information is encrypted and transmitted to us securely. You can verify this by looking for
      a closed lock icon at the bottom of your web browser, or looking for "https" at the
      beginning of the address of the web page.
      <br /><br />While we use encryption to protect sensitive information transmitted online, we
      also protect your information offline. Only employees who need the information to perform a
      specific job (for example, billing or customer service) are granted access to personally
      identifiable information. The computers/servers in which we store personally identifiable
      information are kept in a secure environment.
    </p>
    <br />
    <p>For information on quotes, rates, terms, and other financial info on our website, visit the
      <a rel="noopener" [routerLink]="[ 'disclosures' ]">Disclosures</a> page.</p>
    <br />
    <p>For information on rates, visit the <a rel="noopener" [routerLink]="[ '../', 'mortgage-partner-tools', 'utah-mortgage-rate-assumptions' ]">Rate
        Assumptions</a> page.</p>
    <br />
    <p>
      For more information please <a rel="noopener" [routerLink]="[ 'contact-us' ]">Contact Us</a>.
    </p>
    <br />
  </div>
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      Updated: May 31, 2016
    </p>
  </div>
  <br />
  <br />
</div>
