<div class="grid-container pl-0 pr-0 pt-0">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="mt-10">
    <a mat-button href="tel:8013020100" class="text-primary ml-15" rel="noopener">
      <mat-icon class="material-icons-outlined mat-16 prt-04 mr-05 mb-10">call</mat-icon> 801-302-0100
    </a>
    <div>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social" /></a>
    </div>
  </div>
  <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-10">
    <a mat-link href="mailto:tyler@mortgagepartner.com" class="text-primary ml-15" rel="noopener">
      <mat-icon class="material-icons-outlined mat-16 prt-04 mr-05 mb-10">mail</mat-icon> tyler@mortgagepartner.com
    </a>
  </div> -->
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15 mb-15">
    <a mat-raised-button color="primary" rel="noopener" [routerLink]="[ './apply' ]" class="mr-05">Apply online</a>
    <!-- <a mat-stroked-button color="primary" rel="noopener" [routerLink]="[ './rapid-loan-officer' ]">Rapid Loan Officer</a> -->
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15 mb-15">
  </div>
  <mat-list>
    <ng-container *ngIf="!auth.isLoggedIn; else logOut">
      <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple (click)="login()">
        <mat-icon matListIcon class="material-icons-outlined">account_circle</mat-icon>
        <h3 matLine> My Account </h3>
      </mat-list-item>
    </ng-container>
    <ng-template #logOut>
      <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple (click)="accExpanded = !accExpanded" *ngIf="auth.user">
        <mat-icon matListIcon class="material-icons-outlined">account_circle</mat-icon>
        <h3 matLine>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div>{{(auth.user.displayName) ? auth.user.displayName : auth.user.email}}</div>
            <mat-icon [@indicatorRotate]="accExpanded ? 'accExpanded': 'collapsed'">
              expand_more
            </mat-icon>
          </div>
        </h3>
      </mat-list-item>
      <div *ngIf="accExpanded">
        <mat-list class="pl-20">
          <ng-container *ngIf="accMenuList && accMenuList.length>0">
            <ng-container *ngFor="let item of accMenuList">
              <ng-container *ngIf="!item.function; else btn">
                <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [routerLink]="[item.route]" routerLinkActive #articlesActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }"
                  [ngClass]="{'active': item.route.isActive}">
                  <mat-icon matListIcon class="material-icons-outlined">{{item.icon}}</mat-icon>
                  <h3 matLine> {{item.text}} </h3>
                </mat-list-item>
              </ng-container>
              <ng-template #btn>
                <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple (click)="logout()">
                  <mat-icon matListIcon class="material-icons-outlined">logout</mat-icon>
                  <h3 matLine> Logout </h3>
                </mat-list-item>
              </ng-template>
            </ng-container>
          </ng-container>
        </mat-list>
      </div>
    </ng-template>
    <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [routerLink]="[ '' ]" routerLinkActive #homeActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': homeActive.isActive}">
      <mat-icon matListIcon class="material-icons-outlined">house</mat-icon>
      <h3 matLine> Homepage </h3>
    </mat-list-item>
    <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [routerLink]="[ 'about' ]" routerLinkActive #aboutActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': aboutActive.isActive}">
      <mat-icon matListIcon class="material-icons-outlined">favorite_border</mat-icon>
      <h3 matLine> About us </h3>
    </mat-list-item>
    <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [routerLink]="[ 'loan-process' ]" routerLinkActive #loanprocessActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }"
      [ngClass]="{'active': loanprocessActive.isActive}">
      <mat-icon matListIcon class="material-icons-outlined">account_tree</mat-icon>
      <h3 matLine> Loan Process </h3>
    </mat-list-item>
    <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [routerLink]="[ 'contact' ]" routerLinkActive #contactActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': contactActive.isActive}">
      <mat-icon matListIcon class="material-icons-outlined">perm_phone_msg</mat-icon>
      <h3 matLine> Contact Us </h3>
    </mat-list-item>
    <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple (click)="expanded = !expanded">
      <mat-icon matListIcon class="material-icons-outlined">build</mat-icon>
      <h3 matLine>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div>Tools</div>
          <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
            expand_more
          </mat-icon>
        </div>
      </h3>
    </mat-list-item>
    <div *ngIf="expanded">
      <mat-list class="pl-20">
        <ng-container *ngIf="toolsMenuList && toolsMenuList.length>0">
          <ng-container *ngFor="let item of toolsMenuList">
            <ng-container *ngIf="item.html; else btn">
              <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [html]="item.html" target="_blank">
                <mat-icon matListIcon class="material-icons-outlined">{{item.icon}}</mat-icon>
                <h3 matLine> {{item.text}} </h3>
              </mat-list-item>
            </ng-container>
            <ng-template #btn>
              <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [routerLink]="[item.route]" routerLinkActive #articlesActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }"
                [ngClass]="{'active': item.route.isActive}">
                <mat-icon matListIcon class="material-icons-outlined">{{item.icon}}</mat-icon>
                <h3 matLine> {{item.text}} </h3>
              </mat-list-item>
            </ng-template>
          </ng-container>
        </ng-container>
      </mat-list>
    </div>
    <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple [routerLink]="[ 'articles' ]" routerLinkActive #articlesActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }"
      [ngClass]="{'active': articlesActive.isActive}">
      <mat-icon matListIcon class="material-icons-outlined">pages</mat-icon>
      <h3 matLine> Articles </h3>
    </mat-list-item>
    <!-- <mat-list-item class="nav-list ph-05 clickable clickable_hover" matRipple (click)="store.openLiveChat();">
      <mat-icon matListIcon class="material-icons-outlined">contact_support</mat-icon>
      <h3 matLine> Chat with us </h3>
    </mat-list-item> -->
  </mat-list>
  <br />
</div>
