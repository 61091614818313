<!-- Child view with scroller -->
<div class="grid-container grid-container_full p-0 m-0" style="position: relative;" wmAnimateView useElement cdkScrollable>
  <div #top></div>
  <app-header></app-header>
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isLoadingApp">
    <mat-progress-bar fxFlex="100%" class="loading-bar" color="primary" mode="indeterminate" value="50" bufferValue="75"></mat-progress-bar>
  </div>
  <!-- Spaced needed for the fixed header (60px) -->
  <div class="app-contents">
    <router-outlet></router-outlet>
    <app-footer></app-footer>

    <!-- <livechat-widget #liveChatWidget [licenseId]="liveChatId" (onChatLoaded)="initChat($event)" (onChatWindowOpened)="onChatOpened()"></livechat-widget> -->
    <button mat-fab color="primary" id="scrollToTop" class="scroller" [@400]="'fadeIn'" *ngIf="showScroller" (click)="scrollTo('top')">
      <mat-icon class="mat-36 prt--05">keyboard_arrow_up</mat-icon>
    </button>
  </div>
</div>
<mat-drawer-container [ngClass]="{'drawer-isOpen': appState.default.showNavigationDrawer}" style="z-index: 201;">
  <mat-drawer #drawer class="scrollbar navigation-drawer" mode="push" [opened]="appState.default.showNavigationDrawer">
    <div [@400]="'fadeIn'">
      <!-- Logo & Close Navigation Drawer -->
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <app-logo class="nav-logo clickable p-10" [routerLink]="[ 'home' ]" [height]="56"></app-logo>
        <div fxFlex="50px" class="p-10">
          <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-icon-button (click)="closeDrawer()" class="prt-04">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- Navigation for the drawer -->
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <app-sidenav fxFlex="100"></app-sidenav>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
