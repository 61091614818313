<!-- <svg viewBox="0 0 500 190" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{'height': height, 'width': width}">
  <g transform="matrix(1.174484, 0, 0, 1.174484, -112.735012, -179.993353)">
    <g>
      <polygon class="st2"
        points="232.24,221.92 232.07,312.92 186.8,312.92 186.8,304.35 223.5,304.35 223.5,225.3 164.42,166.22 &#10;&#9;&#9;&#9;138.5,192.14 138.5,171.67 127.16,171.67 127.16,203.48 105.33,225.3 105.33,236.52 96.77,236.52 96.75,221.77 118.59,199.93 &#10;&#9;&#9;&#9;118.59,163.1 147.07,163.1 147.07,171.45 164.42,154.1"
        style="clip-rule: evenodd; fill: rgb(150, 48, 76); fill-rule: evenodd;" />
      <polygon class="st2" points="162.92,304.35 162.92,312.92 96.77,312.92 96.77,297.14 105.33,297.14 105.33,304.35" style="clip-rule: evenodd; fill: rgb(150, 48, 76); fill-rule: evenodd;" />
    </g>
    <g>
      <path class="st3"
        d="M217.62,266.71c0,6.53-2.22,12.19-6.79,16.86c-4.49,4.59-9.98,6.94-16.41,6.94c-3.47,0-6.83-0.71-9.95-2.25 c-1.97-0.97-3.74-2.23-5.34-3.68v27.96h-8.57v-33.97l8.51-8.46c0.29,1.66,0.84,3.25,1.68,4.76c1.32,2.35,3.18,4.13,5.53,5.45 c2.38,1.34,4.93,2.03,7.66,2.03c2.66,0,5.12-0.7,7.41-2.04c2.37-1.39,4.19-3.31,5.54-5.7c1.35-2.39,2.07-4.95,2.07-7.7 c0-2.75-0.7-5.32-2.06-7.71c-1.34-2.37-3.14-4.26-5.5-5.62c-0.73-0.42-1.47-0.78-2.24-1.06c-1.68-0.63-3.45-0.94-5.3-0.94 c-3.58,0-6.68,1.03-9.34,3.18c-1.37,1.11-2.9,2.78-4.12,3.99l-17.48,17.38v13.38h-8.73v-16.81l8.73-8.67v0l7.63-7.59v-12.23h8.57 v5.05c1.5-1.47,3.18-2.73,5.07-3.72c3.14-1.64,6.54-2.36,10.07-2.36c0.61,0,1.22,0.02,1.81,0.06c5.67,0.4,10.58,2.72,14.68,6.86 C215.32,254.66,217.62,260.25,217.62,266.71z"
        style="clip-rule: evenodd; fill: rgb(67, 69, 78); fill-rule: evenodd;" />
      <path class="st2"
        d="M161.82,254.31l-4.28,4.25l-3.37,3.35c-0.07-2.06-0.27-4.34-0.81-5.75c-0.54-1.41-1.44-2.5-2.7-3.33 c-1.42-0.93-3.03-1.28-4.71-1.28c-2.31,0-4.4,0.7-6.28,2.03c-1.96,1.4-3.34,3.23-4.22,5.48c-1.29,3.3-1.4,8.83-1.4,12.34v18.08 h-8.57v-23.14c0-2.61-0.01-7.36-0.87-9.83c-0.52-1.5-1.41-2.69-2.71-3.6c-1.41-0.98-3.01-1.36-4.72-1.36 c-2.26,0-4.28,0.7-6.13,1.98c-1.97,1.36-3.37,3.18-4.27,5.39c-1.28,3.12-1.45,7.8-1.45,11.13v19.43h-8.57v-45.31h8.57v4.43 c1.04-1.07,2.17-2.04,3.43-2.82c2.91-1.71,6.06-2.64,9.44-2.64c2.24,0,4.4,0.44,6.45,1.33c1.93,0.84,3.66,2.01,5.05,3.6 c0.85,0.97,1.55,2.11,2.13,3.3c1.52-2.13,3.37-3.99,5.61-5.41c2.93-1.86,6.13-2.83,9.59-2.83c3.09,0,6,0.77,8.58,2.47 c2.57,1.69,4.4,4.03,5.57,6.87C161.43,253.07,161.64,253.68,161.82,254.31z"
        style="clip-rule: evenodd; fill: rgb(150, 48, 76); fill-rule: evenodd;" />
    </g>
  </g>
  <g transform="matrix(1.174484, 0, 0, 1.174484, -112.735012, -179.993353)">
    <g>
      <path class="st4" d="M291.03,223.64l-13.47,17.96h-1.54l-13.17-18.02v30.07h-7.8V212.3h8.98l12.94,17.78l12.94-17.78h8.92v41.36 h-7.8V223.64z" style="fill: rgb(150, 48, 76);" />
      <path class="st4"
        d="M334.58,239.06c0,8.39-5.73,15.18-15.18,15.18s-15.13-6.79-15.13-15.18c0-8.33,5.79-15.18,15.07-15.18 S334.58,230.73,334.58,239.06z M311.48,239.06c0,4.43,2.66,8.57,7.92,8.57c5.26,0,7.92-4.14,7.92-8.57c0-4.37-3.07-8.63-7.92-8.63 C314.2,230.43,311.48,234.69,311.48,239.06z"
        style="fill: rgb(150, 48, 76);" />
      <path class="st4" d="M346.22,224.47l0.53,3.37c2.25-3.6,5.26-4.14,8.21-4.14c3.01,0,5.91,1.18,7.5,2.78l-3.25,6.26 c-1.48-1.24-2.84-1.89-5.2-1.89c-3.78,0-7.27,2.01-7.27,7.39v15.36h-7.21v-29.13H346.22z" style="fill: rgb(150, 48, 76);" />
      <path class="st4" d="M377.18,216.25v8.27h8.04v6.2h-8.09v12.58c0,2.78,1.54,4.14,3.78,4.14c1.12,0,2.42-0.35,3.49-0.89l2.01,6.14 c-2.07,0.83-3.78,1.18-5.97,1.24c-6.32,0.24-10.46-3.37-10.46-10.63v-12.58h-5.44v-6.2h5.44v-7.5L377.18,216.25z"
        style="fill: rgb(150, 48, 76);" />
      <path class="st4"
        d="M413.46,221.51l5.32,4.02l-3.25,4.14c2.25,2.54,3.07,5.44,3.07,8.63c0,3.6-1.36,8.69-6.14,10.87 c4.84,2.42,6.03,5.91,6.03,9.63c0,8.04-6.14,13-14.59,13c-8.45,0-14.77-5.14-14.77-13h7.15c0,3.78,3.49,6.26,7.62,6.26 c4.14,0,7.39-2.25,7.39-6.26s-3.78-5.85-7.39-5.85c-9.1,0-14.77-5.55-14.77-14.65s6.62-14.77,14.77-14.77 c2.3,0,4.67,0.3,6.68,1.65L413.46,221.51z M396.26,238.29c0,5.08,3.43,8.09,7.62,8.09c4.14,0,7.56-3.07,7.56-8.09 s-3.43-8.21-7.56-8.21C399.69,230.08,396.26,233.21,396.26,238.29z"
        style="fill: rgb(150, 48, 76);" />
      <path class="st4"
        d="M446.3,224.47h6.91v29.13h-6.79l-0.35-4.25c-1.65,3.43-6.2,5.08-9.45,5.14c-8.63,0.06-15.01-5.26-15.01-15.48 c0-10.04,6.68-15.3,15.18-15.24c3.9,0,7.62,1.83,9.28,4.73L446.3,224.47z M428.82,239c0,5.55,3.84,8.86,8.63,8.86 c11.34,0,11.34-17.67,0-17.67C432.66,230.2,428.82,233.45,428.82,239z"
        style="fill: rgb(150, 48, 76);" />
      <path class="st4"
        d="M482.17,221.51l5.32,4.02l-3.25,4.14c2.25,2.54,3.07,5.44,3.07,8.63c0,3.6-1.36,8.69-6.14,10.87 c4.84,2.42,6.03,5.91,6.03,9.63c0,8.04-6.14,13-14.59,13c-8.45,0-14.77-5.14-14.77-13h7.15c0,3.78,3.49,6.26,7.62,6.26 c4.13,0,7.38-2.25,7.38-6.26s-3.78-5.85-7.38-5.85c-9.1,0-14.77-5.55-14.77-14.65s6.62-14.77,14.77-14.77 c2.3,0,4.67,0.3,6.68,1.65L482.17,221.51z M464.98,238.29c0,5.08,3.43,8.09,7.62,8.09c4.13,0,7.56-3.07,7.56-8.09 s-3.43-8.21-7.56-8.21C468.4,230.08,464.98,233.21,464.98,238.29z"
        style="fill: rgb(150, 48, 76);" />
      <path class="st4"
        d="M498.06,241.6c0.47,3.6,3.6,6.2,8.69,6.2c2.66,0,6.14-1,7.8-2.72l4.61,4.55c-3.07,3.19-8.09,4.73-12.53,4.73 c-10.04,0-16.01-6.2-16.01-15.54c0-8.86,6.03-15.24,15.48-15.24c9.75,0,15.83,6.03,14.71,18.02H498.06z M513.9,235.63 c-0.47-3.78-3.43-5.67-7.56-5.67c-3.9,0-7.09,1.89-8.15,5.67H513.9z"
        style="fill: rgb(150, 48, 76);" />
    </g>
    <g>
      <path class="st5" d="M266.06,300.46h-7.29v7.59h-4.86v-25.78c4.05,0,8.1-0.04,12.16-0.04 C278.66,282.22,278.69,300.46,266.06,300.46z M258.76,296h7.29c6.23,0,6.19-9.14,0-9.14h-7.29V296z" style="fill: rgb(67, 69, 78);" />
      <path class="st5" d="M308.16,303.18h-13.48l-2.21,4.86h-5.27l11.57-25.78h5.3l11.57,25.78h-5.3L308.16,303.18z M301.42,287.67 l-4.79,10.98h9.58L301.42,287.67z" style="fill: rgb(67, 69, 78);" />
      <path class="st5"
        d="M351.73,308.04h-5.78l-7.51-8.62h-4.68v8.62h-4.86v-25.82c4.09,0,8.18,0.04,12.27,0.04 c6.08,0.04,9.28,4.09,9.28,8.55c0,3.54-1.62,7.11-6.52,8.1l7.81,8.8V308.04z M333.76,286.79v8.25h7.4c3.09,0,4.42-2.06,4.42-4.13 c0-2.06-1.36-4.13-4.42-4.13H333.76z"
        style="fill: rgb(67, 69, 78);" />
      <path class="st5" d="M372.58,286.68h-8.18v-4.42c7.44,0,13.74,0,21.22,0v4.42h-8.18v21.36h-4.86V286.68z" style="fill: rgb(67, 69, 78);" />
      <path class="st5" d="M416.56,282.22h4.86v25.82h-3.02v0.04l-13.56-17.42v17.39h-4.86v-25.78h3.94l12.63,15.99V282.22z" style="fill: rgb(67, 69, 78);" />
      <path class="st5" d="M456.15,308.04h-19.3c0-8.58,0-17.2,0-25.78h19.3v4.71h-14.48v5.93h13.96v4.53h-13.96v5.82h14.48V308.04z" style="fill: rgb(67, 69, 78);" />
      <path class="st5"
        d="M493.83,308.04h-5.78l-7.51-8.62h-4.68v8.62H471v-25.82c4.09,0,8.18,0.04,12.27,0.04 c6.08,0.04,9.28,4.09,9.28,8.55c0,3.54-1.62,7.11-6.52,8.1l7.81,8.8V308.04z M475.86,286.79v8.25h7.4c3.09,0,4.42-2.06,4.42-4.13 c0-2.06-1.36-4.13-4.42-4.13H475.86z"
        style="fill: rgb(67, 69, 78);" />
    </g>
  </g>
</svg> -->
<!-- <img src="../../../assets/images/MP-Logo.png" alt="Utah mortgage loans in salt lake city with Mortgage Partner" [ngStyle]="{'height': height, 'width': width}" /> -->
<img src="../../../assets/images/mortgage_partner-logo.svg" alt="Utah mortgage loans in salt lake city with Mortgage Partner" [ngStyle]="{'height': height, 'width': width}" />