export class FirebaseErrors {
    static parse(errorCode: string): string {

        let message: string;

        switch (errorCode) {
            case 'auth/wrong-password':
                message = 'Invalid login credentials.';
                break;
                case 'auth/weak-password':
                    message = 'Password should be at least 6 characters.';
                    break;
            case 'auth/network-request-failed':
                message = 'Please check your internet connection';
                break;
            case 'auth/too-many-requests':
                message =
                    'We have detected too many requests from your device. Take a break please!';
                break;
            case 'auth/user-disabled':
                message =
                    'Your account has been disabled or deleted. Please contact the system administrator.';
                break;
            case 'auth/requires-recent-login':
                message = 'Please login again and try again!';
                break;
            case 'auth/email-already-exists':
                message = 'Email address is already in use by an existing user.';
                break;
            case 'auth/email-already-in-use':
                message = 'Email address is already in use by an existing user.';
                break;
            case 'auth/user-not-found':
                message =
                    'We could not find user account associated with the email address or phone number.';
                break;
            case 'auth/phone-number-already-exists':
                message = 'The phone number is already in use by an existing user.';
                break;
            case 'auth/invalid-phone-number':
                message = 'The phone number is not a valid phone number!';
                break;
            case 'auth/invalid-email  ':
                message = 'The email address is not a valid email address!';
                break;
            case 'auth/cannot-delete-own-user-account':
                message = 'You cannot delete your own user account.';
                break;
            default:
                message = 'Oops! Something went wrong. Try again later.';
                break;
        }

        return message;
    }
}
export class FireStorageErrors {
    static parse(errorCode: string): string {

        let message: string;

        switch (errorCode) {
            case 'storage/unauthorized':
                message = 'Permission denied. Please login again and try again!';
                break;
            case 'storage/unauthenticated':
                message = 'Please login again and try again!';
                break;
            case 'storage/retry-limit-exceeded':
                message = 'Timed out. Try uploading again!';
                break;
            case 'storage/canceled':
                message = 'Upload canceled!';
                break;
            default:
                message = 'Oops! Something went wrong. Try again later.';
                break;
        }

        return message;
    }
}