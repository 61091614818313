import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages.routing';
import { ApplyComponent } from './apply/apply.component';
import { AboutUsComponent, ArticleComponent, ArticlesOverviewComponent, ContactUsComponent, DisclosuresComponent, HomepageComponent, PrivacyPolicyComponent, RapidLoComponent, RatesComponent, UploadComponent } from '.';
import { AppCommonModule } from '../app-common.module';
import { TextMaskModule } from 'angular2-text-mask';
// import { ChatButtonComponent, HelpButtonComponent, LoginSelectorComponent, LogoComponent } from '../shared';
import { LoanProcessComponent } from './loan-process/loan-process.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [
		AppCommonModule, 
		SharedModule,
		TextMaskModule,
		PagesRoutingModule
	],
	declarations: [
		PagesComponent,
		AboutUsComponent,
		ContactUsComponent,
		HomepageComponent,
		LoanProcessComponent,
		ArticlesOverviewComponent,
		ApplyComponent,
		ArticleComponent,
		PrivacyPolicyComponent,
		DisclosuresComponent,
		UploadComponent,
		RapidLoComponent,
		RatesComponent
	],
	providers: [
		// Services here
	],
	exports: [
	],
	entryComponents: [
		// Dialogs, bottomsheets. etc. here.
	]
})
export class PagesModule {
	constructor() { console.log('@ModuleCreated::PagesModule'); }
}
