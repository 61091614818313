<div class="hero">
  <div class="grid-container mt-0 mb-0">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex.xs="100" fxFlex.gt-xs="75" fxFlex.gt-lg="80" class="hero-text-backdrop">
        <div fxLayout="row wrap" fxLayoutAlign="center center" wmAnimate="fadeInUp" speed="faster" aos>
          <!-- <h1 class="text-center">Utah's #1 Mortgage Company</h1> -->
          <h1 class="text-center">Save more with a partner in the mortgage industry</h1>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center" wmAnimate="fadeInUp" speed="fast" aos>
          <!-- <h3 class="text-center">The Mortgage Partner has been serving Utah for over 30 years...</h3> -->
          <h4 class="text-center">From your starter home to your dream home&mdash;we're your partner for life.</h4>
        </div>
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="7.5px">
          <div wmAnimate="fadeInRight" speed="fast" aos>
            <chat-button matType="raised" matColor="primary" buttonText="Talk to a loan officer" extraClass="mt-05"></chat-button>
            <!-- <a mat-raised-button style="font-size: 16px;" color="primary" href="tel:8013020100">Talk to a loan officer</a> -->
          </div>
          <div wmAnimate="fadeInLeft" speed="fast" aos>
            <a mat-button color="white" class="with-border mt-05" rel="noopener" [routerLink]="['../', 'apply' ]" isRoundButton="true">Apply online</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Illustrations -->
<div class="pv-40 illustrations">
  <div class="grid-container ph-10-sm">
    <div fxLayout="row" fxLayoutAlign="center center">
      <!-- <h2 class="font-weight-600 mb-0 text-center mb-50" style="color: #9f445d; font-size: 2.715em;">Getting <u>You</u> Utah's Best Mortgage Rates</h2> -->
      <h2 class="font-weight-500 text-center mb-15">Getting <span class="light-primary">You</span> Utah's Best Mortgage Rates</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p class="mb-50 text-center">The Mortgage Partner, your trusted mortgage company.</p>
    </div>
    <!-- Subsection 1: Home Loan Experts -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50">
        <img height="300" class="illustration" src="../../../assets/images/illustrations/mortgage-loan-in-utah.svg" alt="Mortgage Partner loan officer at desk working on a mortgage loan for a utah resident" wmAnimate="fadeInRight" speed="faster"
          aos />
      </div>
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" class="ph-10-sm">
        <div wmAnimate="fadeInLeft" speed="fast" aos>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <h3 class="font-weight-400 light-primary">Home Loan Experts</h3>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <p>Finding the best mortgage rate in Utah shouldn't be a burden on you. At The Mortgage Partner, your home
              loan expert is
              dedicated to getting you the best mortgage rate possible&mdash;with a loan type that fits your needs. Give us a call or chat with us, we won't disappoint.</p>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="100">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="54px">
                  <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">person</mat-icon>
                </div>
                <div fxFlex="calc(100% - 54px)">
                  <p class="mb-0">Talk to your loan officer at anytime.</p>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="100">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="54px">
                  <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">attach_money</mat-icon>
                </div>
                <div fxFlex="calc(100% - 54px)">
                  <p class="mb-0">No origination fees. No application fees.</p>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="100">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="54px">
                  <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">phone</mat-icon>
                </div>
                <div fxFlex="calc(100% - 54px)">
                  <p class="mb-0">Communication tailored to
                    you&mdash;phone, email, and text.</p>
                </div>
              </div>
              <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
                <div fxFlex="54px">
                  <mat-icon class="material-icons-outlined ml-15 mr-15">phone</mat-icon>
                </div>
                <div fxFlex="calc(100% - 54px)">
                  <p class="mb-0">Communication tailored to
                    you&mdash;phone, email, and text.</p>
                </div>
              </div> -->
            </div>
          </div>
          <br />
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <!-- <a mat-raised-button style="font-size: 16px;" color="primary" href="tel:8013020100">Talk to an expert</a> -->
            <chat-button matType="raised" matColor="primary" buttonText="Talk to an expert"></chat-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Subsection 2: Quickly Secure Your Loan -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlexOrder.lt-md="2" fxFlexOrder.gt-sm="1" wmAnimate="fadeInRight" speed="fast" aos class="ph-10-sm">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <h3 class="font-weight-400 light-primary">Quickly Secure Your Loan</h3>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <p>Our salaried loan officers work around the clock on your loan. From initial contact to closing&mdash;and beyond,
            we're here for you. With your best interest in mind, we shop around for your best rate and can lock you into your best interest rate.</p>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
          <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="54px">
                <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">search</mat-icon>
              </div>
              <div fxFlex="calc(100% - 54px)">
                <p class="mb-0">We shop for your best rate.</p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
          <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="54px">
                <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">lock</mat-icon>
              </div>
              <div fxFlex="calc(100% - 54px)">
                <p class="mb-0">Interest rate locked&mdash;even when rates go up.</p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
          <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="54px">
                <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">schedule</mat-icon>
              </div>
              <div fxFlex="calc(100% - 54px)">
                <p class="mb-0">Salaried loan officers working around the clock.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlexOrder.lt-md="1" fxFlexOrder.gt-sm="2" height="300" class="illustration" src="../../../assets/images/illustrations/best-mortgage-interest-rates-in-utah.svg"
        alt="Mortgage Partner loan officer at desk working on a mortgage loan for a utah resident" wmAnimate="fadeInLeft" speed="faster" aos />
    </div>
    <!-- Subsection 3: Professional Guidance -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50">
        <img height="300" wmAnimate="fadeInRight" speed="faster" aos class="illustration" src="../../../assets/images/illustrations/utah-best-mortgage-company.svg"
          alt="Mortgage Partner loan officer at desk working on a mortgage loan for a utah resident" />
      </div>
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" class="ph-10-sm">
        <div wmAnimate="fadeInLeft" speed="fast" aos>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <h3 class="font-weight-400 light-primary">Professional Guidance</h3>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <p>Are you new to the mortgage process? We'll be your guide every step of the way. We let you know what is needed and when it is needed. If you have questions, we are here to answer them. With over 30 years experience in the mortgage
              industry, we are your lifeline.</p>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="100">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="54px">
                  <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">home_work</mat-icon>
                </div>
                <div fxFlex="calc(100% - 54px)">
                  <p class="mb-0">First time getting a mortgage? No problem.</p>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="100">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="54px">
                  <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">contact_support</mat-icon>
                </div>
                <div fxFlex="calc(100% - 54px)">
                  <p class="mb-0">We're here to answer your questions.</p>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
            <div fxFlex="100">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="54px">
                  <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">beenhere</mat-icon>
                </div>
                <div fxFlex="calc(100% - 54px)">
                  <p class="mb-0">We'll guide you through the mortgage process.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Subsection 4: Saving You Money -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlexOrder.lt-md="2" fxFlexOrder.gt-sm="1" wmAnimate="fadeInRight" speed="fast" aos class="ph-10-sm">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <h3 class="font-weight-400 light-primary">Saving You Money</h3>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <p fxFlex.gt-sm="100" fxFlex.lt-md="80">Finding the best mortgage rate in Utah shouldn't be a burden on you. At The Mortgage Partner, your home loan expert is dedicated to getting you the best mortgage rate&mdash;with a loan type fits your
            needs.</p>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
          <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="54px">
                <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">savings</mat-icon>
              </div>
              <div fxFlex="calc(100% - 54px)">
                <p class="mb-0">Dedicated to get you the best rate.</p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
          <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="54px">
                <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">extension</mat-icon>
              </div>
              <div fxFlex="calc(100% - 54px)">
                <p class="mb-0">Loan programs fit to your needs.</p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-15">
          <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="54px">
                <mat-icon class="material-icons-outlined ml-15 mr-15 prt-02">thumb_up_alt</mat-icon>
              </div>
              <div fxFlex="calc(100% - 54px)">
                <p class="mb-0">Lender paid, not borrower paid.</p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <a mat-raised-button color="primary" rel="noopener" [routerLink]="['../', 'apply' ]">Start my application</a>
        </div>
      </div>
      <img fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlexOrder.lt-md="1" fxFlexOrder.gt-sm="2" height="300" class="illustration" src="../../../assets/images/illustrations/mortgage-company-utah.svg"
        alt="Mortgage Partner loan officer at desk working on a mortgage loan for a utah resident" wmAnimate="fadeInLeft" speed="faster" aos />
    </div>
    <br />
    <!-- <div fxLayout="row" fxLayoutAlign="start space-between">
      <a mat-link href="tel:8013020100" class="text-primary">
        <mat-icon class="mat-16 prt-03">call</mat-icon> 801-302-0100
      </a>
    </div> -->
  </div>
</div>
<div class="image-standout">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h2 class="mb-05 font-weight-400 text-center">Saving Your Family Money. Guaranteed.</h2>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400">How are we going to save your family money? </p>
    </div>
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="80">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex.lt-md="100" fxFlex.gt-sm="50" class="mt-15 mb-15 p-20 ph-10-sm">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="65px">
                <mat-icon class="material-icons-outlined text-white mr-15">done_outlined</mat-icon>
              </div>
              <div fxFlex="calc(100% - 65px)">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <h5>Salary-Based Loan Officers</h5>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <p class="mb-0">No commission-based employees.</p>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex.lt-md="100" fxFlex.gt-sm="50" class="mt-15 mb-15 p-20 ph-10-sm">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="65px">
                <mat-icon class="material-icons-outlined text-white mr-15">done_outlined</mat-icon>
              </div>
              <div fxFlex="calc(100% - 65px)">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <h5>Minimal Closing Costs</h5>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <p class="mb-0">We charge less to get you a better rate.</p>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex.lt-md="100" fxFlex.gt-sm="50" class="mt-15 mb-15 p-20 ph-10-sm">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="65px">
                <mat-icon class="material-icons-outlined text-white mr-15">done_outlined</mat-icon>
              </div>
              <div fxFlex="calc(100% - 65px)">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <h5>Rate Locking</h5>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <p class="mb-0">Locking in your rate&mdash;before rates go up.</p>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex.lt-md="100" fxFlex.gt-sm="50" class="mt-15 mb-15 p-20 ph-10-sm">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="65px">
                <mat-icon class="material-icons-outlined text-white mr-15">done_outlined</mat-icon>
              </div>
              <div fxFlex="calc(100% - 65px)">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <h5>No Origination Fees. No Application Fees.</h5>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <p class="mb-0">Your loan application is free, period.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Testimonials section -->
<div class="pv-40 testimonials">
  <div class="grid-container grid-container_full pv-0 mt-0 mb-0">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="grid-container pv-0 mb-0">
        <br />
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <h2 class="font-weight-600 mb-0 text-center mb-50" style="color: #9f445d; font-size: 2.715em;">Getting <u>You</u> Utah's Best Mortgage Rates</h2> -->
          <h2 class="mb-05 font-weight-400 text-center">Client Testimonials</h2>
        </div>
        <br />
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(100% - 100px)" fxFlexOrder.lt-md="1" fxFlexOrder.gt-sm="2">
            <!-- Testimonials -->
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
              <mat-icon>star</mat-icon>
            </div>
            <ng-container *ngFor="let testimonial of testimonials; let i = index">
              <ng-container *ngIf="i === showTestimonialIndex">

                <div wmAnimate="slideInRight" speed="fast" aos>
                  <br />
                  <blockquote>{{testimonial.quote}}</blockquote>
                  <cite>{{testimonial.name}}</cite>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div fxFlex.lt-md="50" fxFlex.gt-sm="40px" fxFlexOrder.lt-md="2" fxFlexOrder.gt-sm="1">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <button mat-icon-button style="position: relative; overflow: hidden;" (click)="prevTestimonial()">
                <mat-icon class="material-icons-outlined text-muted">chevron_left</mat-icon>
              </button>
            </div>
          </div>
          <div fxFlex.lt-md="50" fxFlex.gt-sm="40px" fxFlexOrder="3">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <button mat-icon-button style="position: relative; overflow: hidden;" (click)="nextTestimonial()">
                <mat-icon class="material-icons-outlined text-muted">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="15px">
          <img class="equal-housing-icon" src="../../../assets/images/equal-housing-house.svg" alt="Mortgage Partner serving Salt Lake City, Lehi, Riverton, and Utah is an equal housing lender" />
          <strong class="text-muted">Equal Housing Lender <br /> <small class="text-muted">NMLS # 3123</small></strong>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Why Us section -->
<div class="pv-40 why-us">
  <div class="grid-container pv-0 mt-0 mb-0">
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h2 class="mb-05 font-weight-400">Why Us?</h2>
    </div>
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="text-center" fxFlex.lt-md="100" fxFlex.gt-sm="80">We believe that everyone deserves a chance to get into their dream home; even when the journey begins from your starter home, we're here to help you every step of the way&mdash;for
        many years
        to come.</p>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center start">

      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="33.333">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-icon class="material-icons-outlined mat-64">local_library</mat-icon>
        </div>
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h4 class="font-weight-400">Knowledge</h4>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="pl-15 pr-15">We have an excellent relationship with lenders and title companies. But, when it comes to your loan, we fight for you. With 30 years of experience, we've got your back.</p>
        </div>
      </div>

      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="33.333">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-icon class="material-icons-outlined mat-64">person</mat-icon>
        </div>
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h4 class="font-weight-400">Personalized</h4>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="pl-15 pr-15">When you call, we answer. Every detailed part of you loan is tailored to you. To us, you aren't just another number. Stop dealing with other giant mortgage companies who only care about their bank accounts. </p>
        </div>
      </div>

      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="33.333">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-icon class="material-icons-outlined mat-64">business</mat-icon>
        </div>
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h4 class="font-weight-400">Brokerage</h4>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="pl-15 pr-15">What's so great about going through a broker? We are able to compare loan programs with multiple lenders and get you the best rate and term for your loan. </p>
        </div>
      </div>



    </div>
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" wmAnimate="fadeInUp" speed="fast" aos>
      <chat-button matType="raised" matColor="primary" buttonText="Talk to a loan officer"></chat-button>
      <!-- <a mat-raised-button style="font-size: 16px;" color="primary" href="tel:8013020100">Talk to a loan officer</a> -->
    </div>
    <br />
  </div>
</div>
<div class="social-standout">
  <div class="grid-container">
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <mat-divider fxFlex="50"></mat-divider>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <h2 class="mb-05 font-weight-400">Social Media</h2>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <p>Connect with us on social media.</p>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">

      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social" /></a>

    </div>
    <br />
    <br />
    <br />
  </div>
</div>
