
import { ElementRef, HostListener, Inject, OnDestroy, ViewChild } from '@angular/core';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppState } from './models/app-state-model';
import { filter, first, map, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { fxArray } from './models/prefix';
import { UserService } from './shared/services/user.service';
import { AuthService } from './shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import * as moment from 'moment';
import { LiveChatWidgetApiModel, LiveChatWidgetModel } from '@livechat/angular-widget';
// import 'firebase/app-check';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	host: { class: "mat-typography" },
	animations: fxArray
})


export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
	@ViewChild("top", { static: false }) scrollToTop: ElementRef;
	@ViewChild('liveChatWidget', { static: false }) public liveChatWidget: LiveChatWidgetModel;
	liveChatApi: LiveChatWidgetApiModel;
	isLiveChatWidgetLoaded: boolean = false;
	liveChatId: number = 13015722;
	currentRoute: any = '';
	title = 'mortgage-partner';
	showDrawer: boolean = false;
	isLoadingApp: boolean = true;
	showNavigationDrawer: boolean;
	showScroller: boolean = false;

	constructor(
		@Inject(DOCUMENT) private doc: any,
		// private sw: UpdateService,
		public afAuth: AngularFireAuth,
		private router: Router,
		private route: ActivatedRoute,
		public snackBar: MatSnackBar,
		private titleService: Title,
		public store: UserService,
		public auth: AuthService,
		public appState: AppState,
		private metaTagService: Meta
	) {
		this.showNavigationDrawer = appState.default.showNavigationDrawer;

	}

	ngOnInit(): void {
		this.afAuth.authState.subscribe((user: any) => {
			if (user) {
				this.auth.authState = user;
				this.auth.userSubject.next(user);
				this.store.getUser(user.uid);
				const userCache = JSON.parse(localStorage.getItem('user'));
				if ((userCache?.stsTokenManager) && (moment().unix() >= moment(userCache.stsTokenManager.expirationTime).unix())) {
					console.log("User timed out");
					this.auth.logout(false);
					this.snackBar.open("Timed out, please log in again.", "Close", { panelClass: ["info"] });
				}
			} else {
				localStorage.setItem('user', null);
				this.store.clearUserData();
			}
		});
		// const appCheck = firebase.appCheck();
		// appCheck.activate('6LcP950aAAAAAHlsC-29CpZ7Ju8kQLTHHX1acxcx');


		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.

		this.router.events.pipe(untilDestroyed(this)).subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) return;
			document.body.scrollTop = 0;
			this.scrollTo('top');
		});
		// check the service worker for updates
		// this.sw.checkForUpdates();

		const appTitle = this.titleService.getTitle();
		this.router
			.events.pipe(
				filter(event => event instanceof NavigationEnd),
				tap((route) => {
					this.currentRoute = route;
					this.updateCustomVariable();
					// attempt to close the chat so we can reopen the chat to get a new greeting.
					// if (this.currentRoute.urlAfterRedirects == "/apply" && this.liveChatApi) {
					// 	// console.log("🚀 ~ file: app.component.ts ~ line 119 ~ AppComponent ~ tap ~ this.liveChatApi.visitor_engaged", this.liveChatApi.visitor_engaged);
					// 	if (!this.liveChatApi.visitor_engaged) {
					//         console.log("🚀 ~ file: app.component.ts ~ line 121 ~ AppComponent ~ tap ~ this.liveChatApi.visitor_engaged", this.liveChatApi.visitor_engaged);
					// 		this.liveChatApi.close_chat();
					// 		this.liveChatWidget.closeChat();
					// 	}
					// 	// this.liveChatWidget.openChatWindow();
					// 	this.liveChatApi.minimize_chat_window();
					// 	this.liveChatWidget.minimizeChatWindow();
					// }
				}),
				map(() => {
					let child = this.route.firstChild;
					while (child.firstChild) {
						child = child.firstChild;
					}
					if (child.snapshot.data['title']) {
						return child.snapshot.data['title'];
					}
					return appTitle;
				})
			).subscribe((ttl: string) => {
				if (this.appState.default.showNavigationDrawer === true) this.appState.default.showNavigationDrawer = !this.appState.default.showNavigationDrawer;
				this.appState.default.appTitle = ttl;
				// Set the title to the amount of the new notifications the user has.
				if (this.appState.default.titleCount != 0) this.titleService.setTitle('(' + this.appState.default.titleCount + ') ' + 'Mortgage Partner - ' + ttl);
				// Else return the normal title.
				else this.titleService.setTitle('Mortgage Partner - ' + ttl);
				this.store.pageTitle = this.titleService.getTitle();
			});
		this.store.getArticles();

	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.isLoadingApp = false;
	}


	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (document.body.scrollTop > 20 ||
			document.documentElement.scrollTop > 20) {
			// document.getElementById('scrollToTop').classList.add('show-scroller');
			this.showScroller = true;
		}
		else if (document.body.scrollTop <= 20 ||
			document.documentElement.scrollTop <= 20) {
			this.showScroller = false;
		}
	}

	ngOnDestroy(): void { }


	closeDrawer() {
		this.appState.default.showNavigationDrawer = !this.appState.default.showNavigationDrawer
	}

	async scrollTo(destination: string) {
		if (destination === 'top') this.scrollToTop.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
	}

	// Sets the custom values for Live Chat
	initChat(api: LiveChatWidgetApiModel) {
		// Sometimes it can happen that LC_Invite is is still being loaded when onChatOpened is called. To ensure that LC_Invite is loaded you can give additional check to onChatOpened function:
		// api.on_after_load = () => {
		//   this.liveChatApi = api;
		//   this.isLiveChatWidgetLoaded = true;
		// };
		api.on_after_load = () => {
			this.liveChatApi = api;
			this.store.liveChatApi = api;
			this.isLiveChatWidgetLoaded = true;
			this.store.isLiveChatWidgetLoaded = true;
			if (this.isLiveChatWidgetLoaded) {
				// Set the visitor name in live chat. (The name will be pre-filled doing this.)
				if (this.store.userInfo$.value?.uid != (undefined || null) && this.auth.isAuthenticated) {
					// Set the visitor email in live chat. (The email will be pre-filled doing this.)
					this.liveChatApi.set_visitor_email(this.store.userInfo$.value?.emailAddress);
					this.liveChatApi.set_visitor_name(this.store.userInfo$.value?.displayName);
					this.liveChatApi.update_custom_variables([
						{ name: 'User Name', value: this.store.userInfo$.value?.displayName },
						{ name: 'User Email', value: this.store.userInfo$.value?.emailAddress },
						{ name: 'User Phone', value: this.store.userInfo$.value?.phoneNumber },
						{ name: 'uid', value: this.store.userInfo$.value?.uid },
						{ name: 'Hear About', value: this.store.userInfo$.value?.hearAbout },
						{ name: 'Banned', value: this.store.userInfo$.value?.isBanned },
						{ name: 'Hear About', value: this.store.userInfo$.value?.hearAbout },
					]);
				}
				// attempt to close the chat so we can reopen the chat to get a new greeting.
				if (!this.liveChatApi.chat_running) {
					this.liveChatApi.close_chat();
					this.liveChatWidget.closeChat();
				}
				// this.liveChatWidget.openChatWindow();
				this.liveChatApi.minimize_chat_window();
				this.liveChatWidget.minimizeChatWindow();

				// Show live chat function.
				// this.openLiveChat();
				// Set the initial custom variable, that way we can update it on route changes.
				// We use this for tracking and customize greetings.
				// this.liveChatApi.set_custom_variables([{ name: 'route', value: this.currentRoute.urlAfterRedirects }]);
			};
		};
	}

	// Updates the custom values for Live Chat
	updateCustomVariable() {
		if (this.isLiveChatWidgetLoaded) {
			this.liveChatApi.update_custom_variables([
				{ name: 'Current Page', value: this.currentRoute.urlAfterRedirects },
				{ name: 'Current Page Link', value: "https://www.mortgagepartner.com" + this.currentRoute.urlAfterRedirects },
			]);
		};
	}

	// Fires when the live chat is opened (maximized).
	// Used for everything we need from the user on load of live chat.
	onChatOpened(): void {
		this.updateCustomVariable();
		// Only run if the live chat is loaded
		if (this.isLiveChatWidgetLoaded) {
			// Get the visitor (user) id.
			this.liveChatApi.get_visitor_id();
			// Get the chat id.
			this.liveChatApi.get_chat_id();


			// You can also use methods directly on liveChatApi instance
			// for more details plese read our documentation 
			// https://developers.livechatinc.com/docs/extending-ui/extending-chat-widget/javascript-api/#methods
			// this.liveChatApi.open_chat_window();
		} else return;
	}

	hideChatWindow() {
		if (this.isLiveChatWidgetLoaded) {
			this.liveChatWidget.minimizeChatWindow();

			// You can also use methods directly on liveChatApi instance
			// for more details plese read our documentation 
			// https://developers.livechatinc.com/docs/extending-ui/extending-chat-widget/javascript-api/#methods
			// this.liveChatApi.minimize_chat_window();
		};
	}

}
