import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  testimonial1: boolean = true
  testimonial2: boolean = false
  testimonial3: boolean = false
  testimonials = [
    { quote: "Penny is absolutely WONDERFUL! We’ve used her services a few times and couldn’t not be more impressed! She is honest and cares about her clients. She takes the time to understand what your needs are and does everything to make it happen. She is calm and patient yet has a sense of urgency. We felt 100% comfortable with her as our lender and hope you have the privilege of working with her!", name: "Celeste Grover" },
    { quote: "Penny and her team were awesome to work with. They guided us through the entire process. They were patient with our many questions, and even put up with some last minute changes. Thanks to them closing was a breeze. The Mortgage Partner is the best!", name: "Steve Fox" },
    { quote: "My wife and I have refinanced twice through Mortgage Partner and each time has been a great experience. Penny works hard and takes care of all the small (and not so small) things to make sure the process is smooth and easy. Can't say enough good things about her. 🙂", name: "Steven Bennett" },
  ]
  showTestimonialIndex: number = 0;

  constructor() { }

  ngOnInit(): void {

    // Cycle through testimonials.
    interval(15000).subscribe(x => {
      // Check if we have more testimonials to cycle through. Else go back to 0,
      if (this.showTestimonialIndex < (this.testimonials.length - 1)) this.nextTestimonial();
      else this.showTestimonialIndex = 0;
    });
  }

  nextTestimonial() {
    if (this.showTestimonialIndex < (this.testimonials.length - 1)) this.showTestimonialIndex = this.showTestimonialIndex + 1;
    else this.showTestimonialIndex = 0;
  }

  prevTestimonial() {
    if (this.showTestimonialIndex === 0) this.showTestimonialIndex = (this.testimonials.length - 1);
    else this.showTestimonialIndex = this.showTestimonialIndex - 1;
  }

}
