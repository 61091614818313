import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent, ApplyComponent, ArticleComponent, ArticlesOverviewComponent, ContactUsComponent, DisclosuresComponent,HomepageComponent,PrivacyPolicyComponent, RapidLoComponent, RatesComponent, UploadComponent } from '.';
import { OfflineComponent } from '../shared';
import { LoanProcessComponent } from './loan-process/loan-process.component';
import { PagesComponent } from './pages.component';


const routes: Routes = [
	{
		path: '',
		component: PagesComponent,
		children: [
			{ path: '404', component: OfflineComponent, data: { reason: '404', title: '404' } },
			{ path: '', component: HomepageComponent, data: { title: "Utah's Best Mortgage Rates. No Origination Fees." } },
			{ path: 'home', component: HomepageComponent, data: { title: 'Home' } },
			{ path: 'about', component: AboutUsComponent, data: { title: 'About' } },
			{ path: 'contact', component: ContactUsComponent, data: { title: 'Contact' } },
			{ path: 'loan-process', component: LoanProcessComponent, data: { title: 'Loan Process' } },
			{ path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Mortgage Partner Privacy Policy' } },
			{ path: 'disclosures', component: DisclosuresComponent, data: { title: 'Mortgage Partner Disclosures' } },
			{ path: 'apply', component: ApplyComponent, data: { title: 'Online Application' } },
			{ path: 'upload', component: UploadComponent, data: { title: 'Upload Documents' } },
			{ path: 'rapid-loan-officer', component: RapidLoComponent, data: { title: 'Rapid Loan Officer' } },
			{ path: 'utah-mortgage-rates', component: RatesComponent, data: { title: 'Utah Mortgage Rates' } },
			// { path: 'articles', component: ArticlesOverviewComponent, data: { title: 'Articles' } },
			{
				path: 'articles',
				children: [
					{ path: '', component: ArticlesOverviewComponent, data: { title: 'Articles' }, },
					{ path: ':i', component: ArticleComponent, data: { title: 'Articles' }, },
				]
			},
			//  {
			// 	path: 'mortgage-partner-tools',
			// 	children: [
			// 		{ path: '', component: ToolsComponent, data: { title: 'Tools' } },
			// 		{ path: 'utah-mortgage-glossary', component: MortgageGlossaryComponent, data: { title: 'Everything You Need To Know About Mortgages' } },
			// 		{ path: 'utah-mortgage-faqs', component: FaqsComponent, data: { title: 'Utah Mortgage Frequently Asked Questions' } },
			// 		{ path: 'utah-mortgage-loan-limits', component: LoanLimitsComponent, data: { title: 'Utah Mortgage Loan Limit' } },
			// 		{ path: 'utah-mortgage-loan-pre-qualification', component: PrequalificationCalculatorComponent, data: { title: 'Get Pre-Qualified For A Mortgage Loan In Utah' } },
			// 		{ path: 'utah-mortgage-loan-calculators', component: LoanCalculatorsComponent, data: { title: 'Calculate Your Mortgage Loan In Utah' } },
			// 		{ path: 'utah-mortgage-rate-assumptions', component: RateAssumptionsComponent, data: { title: 'Estimating Your Mortgage Loan In Utah' } },
			// 	]
			// },
			{ path: 'mortgage-partner-tools', loadChildren: () => import('./mortgage-partner-tools/tools.module').then(m => m.ToolsModule) },
			{ path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
			{ path: 'partners', loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule) },
			{ path: 'giveaway', loadChildren: () => import('./giveaway/giveaway.module').then(m => m.GiveawayModule) },

			

			
			// { path: '', loadChildren: () => import('./mortgage-partner-tools/tools.module').then(m => m.ToolsModule) },
			{ path: '**', redirectTo: '/404', pathMatch: 'full' }
		]
		// { path: 'blog', loadChildren: './pages/blog/blog.module#BlogModule' },
		// { path: 'events', loadChildren: '../calendar/calendar.module#TAPCalendarModule', data: {title: 'Calendar'} },
		// { path: 'account', loadChildren: '../account-settings/account/account.module#AccountModule', data: {title: 'Account settings'} },
		// { path: 'settings', loadChildren: './settings/manager-settings.module#ManagerSettingsModule' },
		// { path: 'team', loadChildren: './teams/team.module#TeamModule', data: {title: 'Groups'} },
		// { path: 'booking', loadChildren: './booking/booking.module#BookingModule' },
		// { path: 'staff', loadChildren: './staff/staff.module#StaffModule', data: {title: 'Staff'} },
		// { path: 'announcements', loadChildren: './messages/messages.module#MessagesModule', data: {title: 'Announcements'} },
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PagesRoutingModule { }
