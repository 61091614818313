<div class="light-bg">
  <div class="grid-container">
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">Mortgage Partner Disclosures</h1>
    </div>
    <br />
    <br />
    <br />
  </div>
</div>
<div class="grid-container">
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      Here at The Mortgage Partner we make every effort in keeping our information accurate; however,
      due to daily changes in interest rates, financial regulations, and mortgage programs, the
      details on this website are for informational purposes only, and are not necessarily accurate,
      complete or relevant for today’s mortgage conditions. The loan programs and terms on
      MortgagePartner.com are all subject to approval according to the applicant’s credit, income,
      debt, and personal situation. These loan programs and terms are subject to change without
      notice, including access to and use of the information provided on this site.
    </p>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      Product and Services
    </h3>

    <p> Because of individual financial and personal circumstances, and the geographical nature of
      mortgage loans, all products and services, including fees, regulations, and rates may vary. The
      Mortgage Partner, Inc. will not be held liable, in any way, for damages or loss, indirect or
      consequential, including the loss of information, data, or money, because of the use of this
      website.</p>





  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      Informational Facts
    </h3>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      While facts found on this website provides you an overall understanding of what loan
      programs are available, the most reliable way to determine your ability to acquire a
      mortgage loan, with its accompanying interest rate and fees, is to discuss your personal
      financial situation and desired outcome with a mortgage professional, who can identify the
      best program, and outline the available interest rates, fees and requirements to close your
      loan.
    </p>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      Rates and Terms
    </h3>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>Monthly payment amounts, APRs, fees, rates, points, and loan origination data provided on
      MortgagePartner.com are examples only, and based on limited information. You can review our
      rate assumption page to identify how we base examples of fees and costs associated with
      loan programs. These online examples do not include monthly mortgage insurance premiums—if
      they are required for your loan type, property taxes, and homeowner’s insurance. Other
      factors that help determine the final terms of your loan include:
    </p>
    <ul>
      <li>
        The appraisal value of your home.
      </li>
      <li>
        Actual credit score.
      </li>
      <li>
        Personal financial information including income and debt.
      </li>

    </ul>
    <p>As well as additional circumstances that are unique to your situation. Each of these
      components may cause your loan payment to increase or decrease.</p>

    <p> If you have chosen an ARM loan, you have the possibility of a rise in your mortgage rate
      annually, after a fixed initial period. For example, a 5/1 ARM would include a five year
      fixed interest rate, and a possible annual increase until the maximum interest rate has
      been reached.</p>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <h3>
      Applications and Calculators
    </h3>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <!-- <p>The 1003 applications, ("Quick Application", "5-Step Application", and "PDF Application"),
      on our website is provided by etrafficers.com, a third party mortgage loan application
      company.</p> -->
    <!-- <br /> -->
    <p>Current rate information displayed on our website is provided by etrafficers.com, a third
      party loan pricing data provider.</p>
    <br />
    <br />
    <p>For information about how we use your information, visit our <a rel="noopener" [routerLink]="[ 'privacy-policy' ]">Privacy
        Policy</a> page.</p>
    <br />
    <p>
      For more information please <a rel="noopener" [routerLink]="[ 'contact-us' ]">Contact Us</a>.
    </p>
  </div>
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <p>
      Updated: April 2, 2021
    </p>
  </div>
  <br />
  <br />
</div>
