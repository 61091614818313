import { TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextMaskModule } from 'angular2-text-mask';
import { AuthService, ChatButtonComponent, HelpButtonComponent, LoginBottomSheet, LoginSelectorComponent, LogoComponent, SelectivePreloadingStrategyService } from '.';
import { AppCommonModule } from '../app-common.module';
import { UserService } from './services/user.service';
import { GiveawayService } from './services/giveaway.service';
import { WindowRef } from './services/window.service';
import { ArticlesService } from './services/articles.service';

@NgModule({
    imports: [
        AppCommonModule,
        TextMaskModule,
    ],
    declarations: [
        ChatButtonComponent,
        HelpButtonComponent,
        LogoComponent,
		LoginSelectorComponent,
        LoginBottomSheet
        // CloudinaryUploadComponent
    ],
    providers: [
        // Services here
        WindowRef,
        ArticlesService,
        AuthService,
        UserService,
        GiveawayService,
        SelectivePreloadingStrategyService,
        TitleCasePipe
    ],
    exports: [
        TextMaskModule,
        ChatButtonComponent,
        HelpButtonComponent,
        LogoComponent,
		LoginSelectorComponent,
    ],
    entryComponents: [
        // Dialogs, bottomsheets. etc. here.
        LoginBottomSheet

    ]
})
export class SharedModule { }
