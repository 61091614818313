<div class="light-bg">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <br />
    <br fxHide.lt-md fxShow.gt-sm />
    <br fxHide.lt-md fxShow.gt-sm />
    <br fxHide.lt-md fxShow.gt-sm />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">Mortgage Partner Articles</h1>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400 text-center">Gain insights and education in the mortgage industry in Utah.</p>
    </div>
    <br />
    <br fxHide.lt-md fxShow.gt-sm />
    <br fxHide.lt-md fxShow.gt-sm />
  </div>
</div>
<div class="articles-standout-container">
  <div #topOfPage></div>
  <div class="grid-container">
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <h2 class="font-weight-400">Featured articles</h2>
    </div>
    <!-- Fetured Articles NgFor -->
    <div fxLayout="row wrap" fxLayoutAlign="end center">
      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="35" class="ph-05 mat-form-field_sm">
        <mat-label>Search articles</mat-label>
        <mat-icon matSuffix class="clickable" (click)="resetFilter()" class="prt-04 clickable">{{filter.length >0 ? "cancel" : "search"}}</mat-icon>
        <input matInput tabindex="0" type="text" placeholder="Mortgage rates, How to get a mortgage, etc." (keyup)="applyFilter($event.target.value)" id="articleSearch" name="articleSearch" [(ngModel)]="filter" #artSearch="ngModel" maxlength="250">
        <mat-error *ngIf="artSearch.hasError('maxlength')">Cannot be longer than 250 characters.</mat-error>
        <mat-error *ngIf="artSearch.hasError('server')">{{artSearch.errors['server']}}</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <ng-container *ngIf="store.articlesSource; else loading">
        <ng-container *ngFor="let article of store.articlesSource.filteredData; let i=index">

          <mat-menu #articleMenu="matMenu" direction="down" yPosition="below">
            <a mat-menu-item html="javascript;" rel="noopener" target="_blank" (click)="RouteTo(article)">
              <mat-icon class="material-icons-outlined">launch</mat-icon>
              <span>View article &nbsp; &nbsp;</span>
            </a>
            <a mat-menu-item html="javascript;" rel="noopener" target="_blank">
              <mat-icon class="material-icons-outlined">ios_share</mat-icon>
              <span>Share article &nbsp; &nbsp;</span>
            </a>
          </mat-menu>
          <!-- <div fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-md="33.333"> -->
          <div fxFlex="100" *ngIf="i <= 4" wmAnimate="fadeInUp" speed="fast" aos>
            <mat-card class="flat p-0 article mt-15 mb-15 clickable clickable_hover" (click)="RouteTo(article)">
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex.lt-md="100" fxFlex.gt-sm="40">
                  <div class="article-img-container">
                    <!-- Probably will need Cloundinary for this to make it easily dynamic -->
                    <!-- <img [src]="'../../../assets/images/' + article.imgUrl" alt="article-img" class="article-img" /> -->
                    <img [src]="article.imgUrl" [alt]="article/altText" class="article-img" />
                  </div>
                </div>
                <div fxFlex.lt-md="100" fxFlex.gt-sm="60">
                  <div class="article-header">
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                      <div fxFlex="50px">
                        <div class="article-header-img-container">

                          <!-- <img [src]="'../../../assets/images/' + article.authorImgUrl" alt="author-img" class="article-header-img" /> -->
                          <img [src]="article.authorImgUrl" alt="author-img" class="article-header-img" />
                        </div>
                      </div>
                      <div fxFlex="calc(100% - 100px)">
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                          <p class="mb-0 mt-10 article-header-text">{{article.author}}</p>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                          <p class="article-header-text">{{article.date}} &mdash; {{article.readTime}} mins</p>
                        </div>
                      </div>
                      <div fxFlex="50px">
                        <button mat-icon-button [matMenuTriggerFor]="articleMenu" type="button" (click)="$event.stopPropagation();">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="article-text-container">
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                      <h3>{{article.title}}</h3>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                      <p>{{article.snapshot}}</p>
                    </div>
                  </div>
                  <mat-divider class="mat-card-divider mb-0"></mat-divider>
                  <div class="article-footer-container">
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                      <div fxFlex="calc(100% - 110px)">
                        <mat-chip-list aria-label="Article categories">
                          <ng-container *ngFor="let category of article.categories; let i=index">
                            <mat-chip *ngIf="i <= 2" (click)="applyFilter(category)" class="clickable mat-standard-chip">
                              {{category}}</mat-chip>
                          </ng-container>
                        </mat-chip-list>
                      </div>
                      <div fxFlex="75px">
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                          <p class="mb-0 mr-05"><small [@400]="'fadeIn'">{{article.totalFavorites}}</small></p>
                          <button mat-icon-button (click)="favoriteArticle(article, $event)" type="button">
                            <mat-icon class="material-icons-outlined" color="primary">
                              {{article.isFavorite ? "favorite" : "favorite_border"}}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </mat-card>
          </div>

        </ng-container>

      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  <div fxLayout="row wrap" fxLayoutAlign="center stretch">
    <div class="loading">
      <mat-spinner [diameter]="36" color="primary"></mat-spinner>
    </div>
  </div>
</ng-template>
