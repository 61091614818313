<div class="light-bg">
  <div #topOfPage></div>
  <div class="grid-container">
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">Mortgage Partner Upload</h1>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400 text-center">{{showLogin ? 'You must have a Mortgage Partner login to upload.' : 'We are notified as soon as you upload a document.'}}</p>
    </div>
    <br />
    <br />
    <br />
  </div>
</div>
<br fxHide.lt-md fxShow.gt-sm />
<br fxHide.lt-md fxShow.gt-sm />
<ng-container *ngIf="!waitingForUser; else loading">
  <div class="grid-container grid-container_small  pb-10 pl-0 pr-0">
    <br />
    <br />
    <div fxLayoutAlign="center center" fxLayout="row wrap">
      <p *ngIf="!auth.isAuthenticated; else authText" class="text-center ph-10-sm">To continue, please login or create an account below.</p>
      <ng-template #authText>
        <p class="text-center ph-10-sm">File uploads are restricted to PDFs for documents, JPEGs and PNGs for images.</p>
      </ng-template>

      <help-button matTooltipText="Click for get help with uploading" buttonText="Get help with uploading" matTooltipPosition="after" helpText="Need help with uploading documents?" class="ml-05 prt--07" matType="stroked">
      </help-button>
    </div>
  </div>
  <ng-container *ngIf="!showLogin; else displayLogin">
    <div class="grid-container  pb-10 pl-0 pr-0">
      <ng-container *ngIf="false; else allowUpload">
        <!-- <ng-container *ngIf="(!auth.isAuthenticated && !hasTempUser); else allowUpload"> -->

        <form autocomplete="on" role="form" name="tempForm" method="post" accept-charset="UTF-8" #tempForm="ngForm" (ngSubmit)="submitTemp()" scrollToFirstInvalid>
          <div fxLayoutAlign="center center" fxLayout="row wrap">
            <div fxFlex.gt-xs="500px" fxFlex.xs="100">
              <mat-card>
                <div fxLayoutAlign="end center" fxLayout="row wrap" class="ph-05">
                  <button mat-stroked-button type="button" [disabled]="isSaving" (click)="login()">Login</button>
                </div>
                <br />
                <div fxLayoutAlign="start center" fxLayout="row wrap">
                  <mat-form-field appearance="outline" fxFlex="100" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.firstName}">
                    <mat-label>First name</mat-label>
                    <input matInput tabindex="0" type="text" placeholder="John/Jane" id="fName" name="fName" #legalFName="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.firstName" required minlength="1" maxlength="46"
                      autocomplete="given-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                    <mat-error *ngIf="legalFName.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                    <mat-error *ngIf="legalFName.hasError('required')">First name is required.</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="100" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.lastName}">
                    <mat-label>Last name</mat-label>
                    <input matInput tabindex="0" type="text" placeholder="Doe" id="lastName" name="lastName" #legalLName="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.lastName" required minlength="1" maxlength="46"
                      autocomplete="family-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                    <mat-error *ngIf="legalLName.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                    <mat-error *ngIf="legalLName.hasError('required')">Last name is required.</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex="100" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.emailAddress}">
                    <mat-label>Email</mat-label>
                    <input matInput tabindex="0" type="text" placeholder="{{data.firstName ? data.firstName : 'name'}}@email.com" id="email" name="email" #eAddress="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.emailAddress"
                      required maxlength="62" autocomplete="email">
                    <mat-error *ngIf="eAddress.hasError('required')">Email is required.</mat-error>
                    <mat-error *ngIf="eAddress.hasError('maxlength')">Cannot be longer than 62 characters.</mat-error>
                  </mat-form-field>
                </div>
                <div fxLayoutAlign="space-between center" fxLayout="row wrap" class="ph-05">
                  <mat-checkbox class="mr-15" name="rTemp" id="rTemp" #rTemp="ngModel" [(ngModel)]="rememberTemp">Remember me</mat-checkbox>
                  <button mat-raised-button type="submit" color="primary" [disabled]="isSaving">
                    <ng-container *ngIf="isSaving; else loginText">
                      &nbsp;<mat-spinner [diameter]="24" color="primary"></mat-spinner>&nbsp;
                    </ng-container>
                    <ng-template #loginText>Continue</ng-template>
                  </button>
                </div>
              </mat-card>
              <br />
              <div fxLayoutAlign="center center" fxLayout="row wrap">
                <p class="ph-20"><small>Your information is only used to internally match uploads to you. Information is <i>never</i> sold.</small></p>
              </div>
              <br />
              <br />
              <br fxHide.lt-md fxShow.gt-sm />
              <br fxHide.lt-md fxShow.gt-sm />
              <br fxHide.lt-md fxShow.gt-sm />
              <br fxHide.lt-md fxShow.gt-sm />
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #displayLogin>
    <div class="grid-container">
      <div fxLayoutAlign="center center" fxLayout="row wrap">
        <div fxFlex="1 1 100">
          <mat-card class="no-float" [@400]="'fadeIn'" class="mat-elevation-z6">
            <div class="grid-container grid-container_small">
              <login [title]="'Upload Documents'" [subTitle]="'An account is required to upload'" [showCreate]="true"></login>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>


  <div class="grid-container pb-10 pl-0 pr-0">
    <ng-template #allowUpload>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" ng2FileDrop [uploader]="uploader" class="well my-drop-zone clickable">
          <label for="file-upload">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <mat-icon class="material-icons-outlined mat-48">upload_file</mat-icon>
            </div>
            <br />
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <p>Drag and drop your files here or click to browse your system</p>
            </div>
            <br />
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <p [@400]="'fadeIn'" class="mb-0">{{ uploader?.queue?.length>1 ? uploader?.queue?.length + ' files' : uploader?.queue?.length + ' file' }} attached</p>
            </div>
            <br />
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <button mat-raised-button type="button" color="primary" (click)="file.click();">Upload document</button>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <input type="file" id="file-upload" #file ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" accept=".pdf, .png, .jpg" multiple style="display: none;" hidden />
            </div>
          </label>
        </div>
      </div>
      <br fxHide.lt-md fxShow.gt-sm />
      <br fxHide.lt-md fxShow.gt-sm />
      <ng-container *ngIf="uploader?.queue?.length > 0">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h5 [@400]="'fadeIn'">
            <mat-icon class="material-icons-outlined mr-05 prt-05">folder</mat-icon> Files added&mdash;review and upload files below.
          </h5>
        </div>
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-05" *ngIf="uploader?.queue?.length>7">
          <button mat-raised-button type="submit" color="primary" [@400]="'fadeIn'" (click)="uploadDocs()">
            Upload {{ uploader?.queue?.length>1 ? uploader?.queue?.length + ' files' : uploader?.queue?.length + ' file' }}
          </button>
        </div>
      </ng-container>

      <div #uploadingItems></div>
      <ng-container *ngIf="uploader?.queue?.length > 0">
        <div class="grid-container grid-container_full pb-10 pl-0 pr-0">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <h5 class="ph-20 font-weight-600">Attach items</h5>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <p class="ph-20 mb-05">File uploads are restricted to PDFs for documents, JPEGs and PNGs for images.
            </p>
          </div>
          <br />
          <br />

          <div fxLayout="row wrap" fxLayoutAlign="start center" class="ph-20">
            <div fxFlex="calc(46.666% - 100px)">
              <p class="table-header">Name</p>
            </div>
            <div fxFlex="calc(20% - 100px)">
              <p class="table-header">Size</p>
            </div>
            <div fxFlex="calc(33.333% - 100px)">
              <p class="table-header">Progress</p>
            </div>
            <div fxFlex="100px">
              <p class="table-header"></p>
            </div>

          </div>

          <ng-container *ngFor="let item of uploader.queue">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <mat-divider fxFlex></mat-divider>
            </div>
            <div [ngClass]="{'complete': item.progress === 100}" class="ph-20">
              <div fxLayout="row wrap" fxLayoutAlign="start center" [@400]="'fadeIn'" class="pv-10" style="min-height: 75px;">
                <div fxFlex="calc(46.666% - 100px)">
                  <p class="mb-0">{{ item?.file?.name }}</p>
                </div>
                <div fxFlex="calc(20% - 100px)">
                  <p class="mb-0">{{ item?.file?.size/1024/1024 | number:'.2' }} MB</p>
                </div>
                <div fxFlex="calc(33.333% - 100px)" class="pl-05 pr-20">
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="80%">
                      <mat-progress-bar mode="buffer" bufferValue="100" [value]="item.progress" color="primary" [ngClass]="{'mat-success': item.progress === 100}"></mat-progress-bar>
                    </div>
                  </div>
                </div>
                <div fxFlex="100px">
                  <ng-container *ngIf="item.progress === 100; else showRemove">
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                      <p class="mb-0">Completed</p>
                    </div>
                  </ng-container>
                  <ng-template #showRemove>
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                      <button mat-button type="button" (click)="removedFileAttached(item)" [disabled]="item.isReady || item.isUploading || item.isSuccess">Remove</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <p class="mb-0"><small>Do not refresh the page. Refreshing the page will result in losing your queued uploads.</small></p>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-05">
            <button mat-raised-button type="submit" color="primary" [@400]="'fadeIn'" (click)="uploadDocs()">
              Upload {{ uploader?.queue?.length>1 ? uploader?.queue?.length + ' files' : uploader?.queue?.length + ' file' }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <br />
  </div>
</ng-container>

<ng-template #loading>
  <div fxLayout="row wrap" fxLayoutAlign="center stretch">
    <div class="loading">
      <mat-spinner [diameter]="36" color="primary"></mat-spinner>
    </div>
  </div>
</ng-template>
