import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { fxArray } from 'src/app/models';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: fxArray
})
export class ArticlesOverviewComponent implements OnInit, AfterViewInit {
  @ViewChild("topOfPage", { static: false }) scrollToTopOfPage: ElementRef;
  dataSource: MatTableDataSource<any>;

  // popularArticles = [
  //   { imgUrl: "dinning-room.jpg", categories: [{ name: "mortgage" }], title: "How to get a mortgage", author: "Tyler Arens", date: "Mar 3, 2021", readTime: 5, snapshot: "You can get your mortgage loan started in Utah with The Mortgage Partner by giving us a call, or click the Apply button at the top of you screen.", isFavorite: false, totalFavorites: 32, id: "" },
  //   { imgUrl: "bookshelf.jpg", categories: [{ name: "mortgage" }], title: "How to get a mortgage", author: "Tyler Arens", date: "Mar 3, 2021", readTime: 5, snapshot: "You can get your mortgage loan started in Utah with The Mortgage Partner by giving us a call, or click the Apply button at the top of you screen.", isFavorite: false, totalFavorites: 13, id: "" },
  //   { imgUrl: "family-kitchen.jpg", categories: [{ name: "mortgage" }], title: "How to get a mortgage", author: "Tyler Arens", date: "Mar 3, 2021", readTime: 5, snapshot: "You can get your mortgage loan started in Utah with The Mortgage Partner by giving us a call, or click the Apply button at the top of you screen.", isFavorite: false, totalFavorites: 109, id: "" },
  //   { imgUrl: "rhema-kallianpur-uocSnWMhnAs-unsplash.jpg", categories: [{ name: "mortgage" }], title: "How to get a mortgage", author: "Tyler Arens", date: "Mar 3, 2021", readTime: 5, snapshot: "You can get your mortgage loan started in Utah with The Mortgage Partner by giving us a call, or click the Apply button at the top of you screen.", isFavorite: false, totalFavorites: 143, id: "" },
  //   { imgUrl: "hiveboxx-cyAhOnM19cg-unsplash.jpg", categories: [{ name: "mortgage" }], title: "How to get a mortgage", author: "Tyler Arens", date: "Mar 3, 2021", readTime: 5, snapshot: "You can get your mortgage loan started in Utah with The Mortgage Partner by giving us a call, or click the Apply button at the top of you screen.", isFavorite: false, totalFavorites: 25, id: "" },
  //   { imgUrl: "obi-onyeador-MMHcFUKkBD4-unsplash.jpg", categories: [{ name: "mortgage" }], title: "How to get a mortgage", author: "Tyler Arens", date: "Mar 3, 2021", readTime: 5, snapshot: "You can get your mortgage loan started in Utah with The Mortgage Partner by giving us a call, or click the Apply button at the top of you screen.", isFavorite: false, totalFavorites: 9, id: "" },
  //   { imgUrl: "white-black-kitchen.jpg", categories: [{ name: "mortgage" }], title: "How to get a mortgage", author: "Tyler Arens", date: "Mar 3, 2021", readTime: 5, snapshot: "You can get your mortgage loan started in Utah with The Mortgage Partner by giving us a call, or click the Apply button at the top of you screen.", isFavorite: false, totalFavorites: 12, id: "" },
  // ]
  filter: string = '';



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public store: UserService,
    public articles: ArticlesService,
  ) { }

  ngOnInit(): void {
    this.store.getArticles();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollTo('topOfPage');
    }, 100);
  }

  async favoriteArticle(article, $event) {
    article.isFavorite = !article.isFavorite;
    
    var userFavoritedArticles = [];
    if (localStorage.getItem('favoritedArticles')) userFavoritedArticles = JSON.parse(localStorage.getItem('favoritedArticles'));
    if (userFavoritedArticles?.length > 0) {
      userFavoritedArticles.map((storedData, index) => {
        if (article.id == storedData) {
          userFavoritedArticles.splice(index, 1);
          localStorage.setItem('favoritedArticles', JSON.stringify(userFavoritedArticles));
          article.totalFavorites -= 1;
          return
        } else if (userFavoritedArticles.length == (index + 1)) {
          article.totalFavorites += 1;
          userFavoritedArticles.push(article.id)
          localStorage.setItem('favoritedArticles', JSON.stringify(userFavoritedArticles));
        }
      })
    } else {
      article.totalFavorites += 1;
      userFavoritedArticles.push(article.id)
      localStorage.setItem('favoritedArticles', JSON.stringify(userFavoritedArticles));
    }
    $event.stopPropagation();
    this.articles.updateFavorites(article)
    // Need a preventative measure to prevent spamming.
  }

  async RouteTo(article) {
    this.router.navigate(['./', article.docKey], { relativeTo: this.route });
  }

  // All other filters
  async applyFilter(filterValue: string) {
    setTimeout(() => {
      this.scrollTo('topOfPage');
    }, 100);
    this.filter = filterValue;
    this.store.articlesSource.filter = filterValue.trim().toLowerCase();
  }

  async resetFilter() {
    setTimeout(() => {
      this.scrollTo('topOfPage');
    }, 100);
    this.filter = '';
    this.applyFilter('');
  }

  async scrollTo(destination: string) {
    if (destination === 'topOfPage') this.scrollToTopOfPage.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

}
