import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fxArray } from 'src/app/models';
import { NgForm } from '@angular/forms';
import { UserInfo } from '../models/user.model';
import { textMaskPhoneFn } from '../services/text-mask-functions';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { FirebaseErrors } from '../models/error.model';
import { first } from 'rxjs/operators';

@Component({
  selector: 'login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
  animations: fxArray
})
export class LoginSelectorComponent implements OnInit {
  @Input() title = 'Saving you money';
  @Input() subTitle = 'With salary based loan officers';
  @Input() showCreate: boolean = false;

  @ViewChild('myForm', { static: false }) private myForm: NgForm;
  @ViewChild('caForm', { static: false }) private caForm: NgForm;
  @ViewChild('fForm', { static: false }) private fForm: NgForm;


  showPass: boolean = false;
  email: string;
  password: string;
  showClose: boolean = true;
  fEmail: string;
  timer: any;
  showForgotPass: boolean = false;
  initC: any;
  codeStatus: any;
  trust: any;
  emailVerificationSent: boolean = false;
  canSignUp: boolean = false;
  public userInfo: UserInfo = new UserInfo();
  public mask = { guide: false, mask: textMaskPhoneFn };

  constructor(
    public snackBar: MatSnackBar,
    // public dialogRef: MatDialogRef<LoginBottomSheet>,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public auth: AuthService,
    public store: UserService,
    // public pagesService: PagesService,
  ) { }

  ngOnInit(): void {
    this.userInfo = new UserInfo();
    this.emailVerificationSent = false;
  }

  async login() {
    this.auth.isLoading = true;
    // this.store.clearUserData();
    if (this.myForm.valid) {
      this.auth.login(this.email, this.password)
        .then((result) => {
          this.ngZone.run(() => {
            this.auth.getCurrentUser();
            // this.router.navigate(['dashboard'])
            console.log('Logged in.');
            setTimeout(() => {
              var newData = new UserInfo();
              newData.uid = result.user.uid;
              if (result.user.emailVerified) {
                this.store.getUser(result.user.uid);
                this.store.getUserData();
                this.emailVerificationSent = false;
                this.auth.isLoading = false;
                this.snackBar.open("Welcome, " + (result.user.displayName ? result.user.displayName : result.user.email) + "!", "Close", { duration: 3000, panelClass: ["info"] });
              }
              else {
                this.store.userInfo$.next(newData);
                this.emailVerificationSent = true;
                this.showClose = false;
                this.checkVerification();
                this.auth.isLoading = false;
              }
            });
          }, 100)
          // this.SetUserData(result.user);
        }).catch((error) => {
          this.auth.isLoading = false;
          this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
          console.log("Login error: ", error);
        })
    } else {
      this.auth.isLoading = false;
      this.snackBar.open("Invalid form data", "Close", { duration: 3000, panelClass: ["error"] });
    }
  }

  async createAcc() {
    this.auth.isLoading = true;
    var display = this.userInfo.firstName + ' ' + this.userInfo.lastName
    if (this.caForm.valid) {
      this.auth.userInfo = this.userInfo;
      this.auth.register(this.userInfo.emailAddress, this.password, display)
        .then((user) => {
          this.ngZone.run(() => {
            // this.router.navigate(['dashboard'])
            setTimeout(() => {
              if (user?.user.uid) {
                this.userInfo.uid = user.user.uid;
                this.store.createUserInfo(this.userInfo);
              }
              this.userInfo.displayName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
              setTimeout(() => {
                this.auth.sendEmailVerification()
                  .then((user) => {
                    this.ngZone.run(() => {
                      this.showClose = false;
                      this.emailVerificationSent = true;
                    });
                  }).catch((error) => {
                    if (error) {
                      this.showClose = true;
                      this.emailVerificationSent = false;
                      console.log("Verification email error: ", error);
                      this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
                    }
                  });
              }, 1000)
            }, 100)
            this.auth.isLoading = false;
          });
          return user.user.updateProfile({
            displayName: display
          }).then(function () {
          }, function (error) {
            // An error happened.
            this.canVerify = false;
            this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
          });
        }).catch((error) => {
          this.auth.canVerify = false;
          this.auth.isLoading = false;
          if (error) {
            console.log("🚀error: ", error)
            this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
          }
        });
    } else {
      this.auth.isLoading = false;
      this.snackBar.open("Invalid form data", "Close", { duration: 3000, panelClass: ["error"] });
    }
  }

  async resendVerification() {
    this.snackBar.open("Email sent, make sure to check spam/junk folders.", "Close", { duration: 3000 });
    this.auth.sendEmailVerification();
  }

  async sendForgot() {
    this.auth.isLoading = true;
    if (this.fForm.valid) {
      this.auth.sendPasswordResetEmail(this.fEmail);
      this.showForgotPass = false;
      this.showCreate = false;
      this.auth.isLoading = false;
    } else {
      this.auth.isLoading = false;
      this.snackBar.open("Invalid form data", "Close", { duration: 3000, panelClass: ["error"] });
    }
  }

  async checkVerification() {
    console.log('Checking if email is verified.');
    if (this.auth.user.emailVerified) {
      console.log('Email is verified.');
      this.snackBar.open("Email verified!", "Close", { duration: 5000 });
    }
    else {
      console.log('Email is not verified.');
      this.snackBar.open("Please verify your account.", "Close", { duration: 3000, panelClass: ["info"] });
    }

  }

  async verifyToLogin() {
    this.auth.logout(false);
    this.showCreate = false;
    this.emailVerificationSent = false;
    this.showClose = true;
  }


}
