import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, SecurityContext } from '@angular/core';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { OfflineComponent } from './shared/offline/offline.component';
import { DialogComponent } from './shared';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCommonModule } from './app-common.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PagesModule } from './pages/pages.module';
import { SideNavComponent } from './layout/sidenav/sidenav.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AngularFireAnalyticsModule, UserTrackingService, ScreenTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LayoutComponent,
    SideNavComponent,
    FooterComponent,
    DialogComponent,
    OfflineComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    NgxScrollToFirstInvalidModule,
    AppCommonModule,
		SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    TextMaskModule,
    HttpClientModule,
    PagesModule,
    AppRoutingModule, 
    // Angulartics2Module.forRoot({
    //   pageTracking: {
    //     clearIds: true,
    //     idsRegExp: new RegExp('^[a-z]\\d+$'), /* Workaround: No NgModule metadata found for 'AppModule' */ // example changes /project/a01/feature to /project/feature
    //     clearQueryParams: true,
    //     clearHash: true,
    //   }
    // }),
  ],
  exports: [],
  providers: [
    CookieService,
    ScreenTrackingService,
    UserTrackingService,
    // UpdateService,
    Title,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
