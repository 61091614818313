export class Article {
    imgUrl: string;
    altText: string;
    categories: Categories[];
    title: string;
    author: string;
    authorImgUrl: string;
    authorTitle: string;
    authorNMLS: number;
    authorAltText: string;
    date: string;
    readTime: number;
    snapshot: string;
    isFavorite: boolean;
    totalFavorites: number;
    id: string;
    htmlMarkDown: any
    postLocation: string;
    urlPath: string;
    docKey?: string;
}

export class Categories {
    name: string;
}

// export class Paragraphs {
//     name: string;
// }