import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { AuthService, LoginBottomSheet } from 'src/app/shared';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SideNavComponent implements OnInit {
  expanded: boolean;
  accExpanded: boolean;
  // @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: any;
  @Input() depth: number;

  toolsMenuList = [
    // { text: 'Prequalification Calculator', route: './mortgage-partner-tools/utah-mortgage-loan-pre-qualification', icon: 'location_searching' },
    // { text: 'Loan Calculators', route: './mortgage-partner-tools/utah-mortgage-loan-calculators', icon: 'calculate' },
    // { text: 'Rate Assumptions', route: './mortgage-partner-tools/utah-mortgage-rate-assumptions', icon: 'trending_down' },
    { text: 'Loan Limits', route: './mortgage-partner-tools/utah-mortgage-loan-limits', icon: 'error_outline' },
    { text: 'Write A Review', route: 'route', icon: 'rate_review', html: 'https://www.google.com/search?q=mortgage+partner&rlz=1C1GCEA_enUS936US936&oq=mortgage+partner&aqs=chrome.0.0i355j46i175i199j0l3j69i60l3.3610j1j7&sourceid=chrome&ie=UTF-8#lrd=0x8752869290356721:0x9ad9404e969358a4,3,,,' },
    { text: 'FAQs', route: './mortgage-partner-tools/utah-mortgage-faqs', icon: 'help_outline' },
    { text: 'Mortgage Glossary', route: './mortgage-partner-tools/utah-mortgage-glossary', icon: 'import_contacts' },
    { text: 'Privacy Policy', route: 'privacy-policy', icon: 'privacy_tip' },
    { text: 'Disclosures', route: 'disclosures', icon: 'security' },
  ]

  accMenuList = [
    { text: 'Dashboard', route: 'account', icon: 'dashboard' },
    { text: 'Applications', route: 'account/applications', icon: 'description'},
    { text: 'Items Needed', route: 'account/items-needed', icon: 'upload_file'},
    { text: 'Upload Documents', route: './upload', icon: 'file_upload'},
    { text: 'Documents Uploaded', route: 'account/items-uploaded', icon: 'cloud_upload'},
    // { text: 'Account Settings', route: 'account-settings', icon: 'settings' },
    { text: 'Logout', route: 'route', icon: 'logout', function: 'logout()' },
  ]
  constructor(
    public router: Router,
    private _bottomSheet: MatBottomSheet,
    public auth: AuthService,
    public store: UserService,
  ) {
    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            this.expanded = false;
            this.accExpanded = false;
          }
        });
  }

  ngOnInit(): void {
  }

  login() {
    this._bottomSheet.open(LoginBottomSheet, { panelClass: 'mat-bottom-sheet-container_full-overlay' });
  }

  logout() {
    this.router.navigate(['home']);
    this.auth.logout(true);
    // Clear user's stored in memory data.
    this.store.clearUserData();
  }

}
