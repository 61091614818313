<div class="grid-container grid-container_small">

  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <app-logo class="login-logo"></app-logo>
    <button mat-icon-button (click)="close()" *ngIf="showClose">
      <mat-icon class="material-icons-outlined" matTooltip="Close" matTooltipPosition="left">
        close
      </mat-icon>
    </button>
  </div>
  <br />
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center">
    <h2 class="font-weight-400 mb-10 ph-15 text-center full-width">Saving you money</h2>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center">
    <p class="ph-15 text-center full-width">With salary based loan officers</p>
  </div>

  <br />
  <br />
  <br />
  <ng-container *ngIf="!showForgotPass; else showForgot">
    <ng-container *ngIf="!emailVerificationSent; else emailVSent">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center ">
        <h5 class="ph-15 mb-0">{{showCreate ? "Create account" : "Login"}}</h5>
      </div>
      <br />
      <ng-container *ngIf="!showCreate; else createAccount">

        <form #myForm="ngForm" (ngSubmit)="login()">
          <div fxLayout="row" fxLayoutAlign="center center" class="mt-15 mb-0 ph-15" [@400]="'fadeIn'">
            <mat-form-field class="mw-305 mat-form-field_sm full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" placeholder="user@email.com" name="email" id="email" maxlength="35" minlength="7" #lemail="ngModel" [(ngModel)]="email" autocomplete="email" required>
              <mat-icon matSuffix class="material-icons-outlined">email</mat-icon>
              <mat-error *ngIf="lemail.hasError('minlength')">Email is too short.</mat-error>
              <mat-error *ngIf="lemail.hasError('maxlength')">Email is too long.</mat-error>
              <mat-error *ngIf="lemail.hasError('required')">Email is required.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" class="mb-15 ph-15" [@400]="'fadeIn'">
            <mat-form-field class="mw-305 mat-form-field_sm full-width" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="{{showPass ? 'text' : 'password'}}" placeholder="**********" name="password" id="password" autocomplete="current-password" #lpass="ngModel" [(ngModel)]="password" minlength="8" maxlength="100" required>
              <div matSuffix>
                <div fxLayout="row wrap" fxLayoutAlign="end center">
                  <mat-icon matTooltip="{{showPass ? 'Hide password' : 'Show password'}}" matTooltipPosition="above" class="material-icons-outlined mr-05 prt-01 clickable" (click)="showPass = !showPass">{{showPass ? 'visibility' : 'visibility_off'}}
                  </mat-icon>
                  <mat-icon class="material-icons-outlined">lock</mat-icon>
                </div>
              </div>
              <mat-error *ngIf="lpass.hasError('minlength')">Too weak.</mat-error>
              <mat-error *ngIf="lpass.hasError('maxlength')">Cannot be longer than 100 characters.</mat-error>
              <mat-error *ngIf="lpass.hasError('required')">Password is required.</mat-error>
            </mat-form-field>
          </div>


          <ng-container *ngIf="auth.error">
            <div fxLayout="row" fxLayoutAlign="center center" class="mb-15 ph-15">
              {{auth.error}}
            </div>
          </ng-container>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0 ph-05">
            <div>
              <button mat-button type="button" class="full-width" [disabled]="auth.isLoading" (click)="showCreate = true">Create account</button>
            </div>
            <div>
              <button mat-raised-button type="submit" color="primary" class="full-width" [disabled]="auth.isLoading">
                <ng-container *ngIf="auth.isLoading; else loginText">
                  &nbsp;<mat-spinner [diameter]="24" color="primary"></mat-spinner>&nbsp;
                </ng-container>
                <ng-template #loginText>Login</ng-template>
              </button>
            </div>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="end center" class="mb-0 mt-0 ph-05">
            <a mat-link [disabled]="auth.isLoading" (click)="showForgotPass = true">Forgot password</a>
          </div>
        </form>
      </ng-container>


      <ng-template #createAccount>
        <form #caForm="ngForm" (ngSubmit)="createAcc()">
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-0 ph-15" [@400]="'fadeIn'">
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'">
              <mat-label>First name</mat-label>
              <input matInput tabindex="0" type="text" placeholder="John/Jane" id="legalFName" name="legalFName" #legalFName="ngModel" [(ngModel)]="userInfo.firstName" mapError="firstName" required minlength="1" maxlength="46"
                autocomplete="given-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
              <mat-error *ngIf="legalFName.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
              <mat-error *ngIf="legalFName.hasError('required')">First name is required.</mat-error>
              <mat-error *ngIf="legalFName.hasError('server')">{{legalFName.errors['server']}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm full-width" [@400]="'fadeIn'">
              <mat-label>Last name</mat-label>
              <input matInput tabindex="0" type="text" placeholder="Doe" id="legalLName" name="legalLName" #legalLName="ngModel" [(ngModel)]="userInfo.lastName" mapError="lastName" required minlength="1" maxlength="46" autocomplete="family-name"
                pattern="^[^-\s][a-zA-Z_\s-]+$">
              <mat-error *ngIf="legalLName.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
              <mat-error *ngIf="legalLName.hasError('required')">Last name is required.</mat-error>
              <mat-error *ngIf="legalLName.hasError('server')">{{legalLName.errors['server']}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-0 ph-15" [@400]="'fadeIn'">
            <mat-form-field appearance="outline" class="ph-05 mat-form-field_sm full-width" [@400]="'fadeIn'">
              <mat-label>Phone number</mat-label>
              <input matInput [textMask]="mask" tabindex="0" type="tel" placeholder="(801) 302-0100" id="phone" name="phone" #phone="ngModel" [(ngModel)]="userInfo.phoneNumber" mapError="phoneNumber" required phone minlength="14" autocomplete="tel">
              <mat-error *ngIf="phone.hasError('required')">Phone number is required.</mat-error>
              <mat-error *ngIf="phone.hasError('maxlength')">Cannot be longer than 16 characters.</mat-error>
              <mat-error *ngIf="phone.hasError('server')">{{phone.errors['server']}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-0 ph-15" [@400]="'fadeIn'">
            <mat-form-field class="ph-05 mat-form-field_sm full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" placeholder="user@email.com" name="em" id="em" autocomplete="none" maxlength="35" minlength="7" #cemail="ngModel" [(ngModel)]="userInfo.emailAddress" required autocomplete="email">
              <mat-icon matSuffix class="material-icons-outlined">email</mat-icon>
              <mat-error *ngIf="cemail.hasError('minlength')">Email is too short.</mat-error>
              <mat-error *ngIf="cemail.hasError('maxlength')">Email is too long.</mat-error>
              <mat-error *ngIf="cemail.hasError('required')">Email is required.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-0 ph-15" [@400]="'fadeIn'">
            <mat-form-field class="ph-05 mat-form-field_sm full-width" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="{{showPass ? 'text' : 'password'}}" placeholder="**********" name="password" id="password" maxlength="100" min="8" autocomplete="new-password" #cpass="ngModel" [(ngModel)]="password" required>
              <div matSuffix>
                <div fxLayout="row wrap" fxLayoutAlign="end center">
                  <mat-icon matTooltip="{{showPass ? 'Hide password' : 'Show password'}}" matTooltipPosition="above" class="material-icons-outlined mr-05 prt-01 clickable" (click)="showPass = !showPass">{{showPass ? 'visibility' : 'visibility_off'}}
                  </mat-icon>
                  <mat-icon class="material-icons-outlined">lock</mat-icon>
                </div>
              </div>
              <mat-error *ngIf="cpass.hasError('minlength')">Too weak.</mat-error>
              <mat-error *ngIf="cpass.hasError('maxlength')">Cannot be longer than 100 characters.</mat-error>
              <mat-error *ngIf="cpass.hasError('required')">Password is required.</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-15 ph-15" [@400]="'fadeIn'">
            <mat-form-field appearance="outline" class="ph-05 mat-form-field_sm mat-form-field_sm-select full-width" [@400]="'fadeIn'">
              <mat-label>How did you hear about us?</mat-label>
              <mat-select tabindex="0" id="hearAbout" placeholder="How did you hear about us?" name="hearAbout" required #hAbout="ngModel" [(ngModel)]="userInfo.hearAbout" ngDefaultControl>
                <mat-option value="Facebook">Facebook</mat-option>
                <mat-option value="Instagram">Instagram</mat-option>
                <mat-option value="LinkedIn">LinkedIn</mat-option>
                <mat-option value="Online search">Online search</mat-option>
                <mat-option value="Online search">Online ad</mat-option>
                <mat-option value="Friend or family">Through a friend or family</mat-option>
                <mat-option value="Drove by our office">Drove by our office</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
              <mat-error *ngIf="hAbout.hasError('required')">How did you hear about us?</mat-error>
              <mat-error *ngIf="hAbout.hasError('server')">{{estCredit.errors['server']}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start end">
            <mat-checkbox class="ml-15 mr-15 ph-15" name="acceptTerms" id="acceptTerms" #accTerms="ngModel" color="primary" [(ngModel)]="userInfo.acceptedTerms" required><small>I agree to Mortgage Partner's <a
                  href="https://www.mortgagepartner.com/privacy-policy" rel="noopener" target="_blank">Privacy Policy</a> & <a>Terms of Use</a></small>
              <mat-error *ngIf="accTerms.hasError('server')">{{estCredit.errors['server']}}</mat-error>
            </mat-checkbox>
          </div>
          <ng-container *ngIf="auth.error">
            <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-15 ph-15">
              {{auth.error.message ? auth.error.message : "Error, try again!" | json}}
            </div>
          </ng-container>

          <br />
          <br />
          <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center">
            <p class="ph-15 mb-05"><small class="text-muted"><mat-icon class="material-icons-outlined mr-05 mat-18 prt-03">lock_outline</mat-icon>Your information is secure and never sold.</small></p>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-10 ph-05" [@400]="'fadeIn'">
            <div fxFlex.lt-md="100" fxFlex.gt-sm="60">

              <button mat-raised-button type="submit" color="primary" class="full-width" [disabled]="auth.isLoading">
                <ng-container *ngIf="auth.isLoading; else loginText">
                  &nbsp;<mat-spinner [diameter]="24" color="primary"></mat-spinner>&nbsp;
                </ng-container>
                <ng-template #loginText>Create account</ng-template>
              </button>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-0 ph-05">
            <div fxFlex.lt-md="100" fxFlex.gt-sm="60">
              <button mat-button type="button" class="full-width" [disabled]="auth.isLoading" (click)="showCreate = false">Back to login</button>
            </div>
          </div>
        </form>

      </ng-template>


    </ng-container>
  </ng-container>
  <ng-template #emailVSent>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <mat-icon class="material-icons-outlined mat-64">email</mat-icon>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h5 class="text-center mb-10 mt-10 pl-15" style="font-size: 1em;
        text-transform: uppercase;
        font-weight: 500;">Verify your email address</h5>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="text-center pl-15">Verify your email address then try logging.</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="text-center pl-15"><small>Make sure to check your junk/spam mail if you cannot find the email.</small></p>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <button mat-stroked-button type="button" (click)="resendVerification()">Resend verification email</button>
    </div>
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-10 ph-05">
      <div fxFlex.lt-md="100" fxFlex.gt-sm="60">
        <button mat-raised-button type="button" color="primary" class="full-width" [disabled]="auth.isLoading" (click)="verifyToLogin()">Login</button>
      </div>
    </div>
  </ng-template>
  <ng-template #showForgot>

    <form #fForm="ngForm" (ngSubmit)="sendForgot()">
      <div fxLayout="row" fxLayoutAlign="center center" class="mt-15 mb-0 ph-15" [@400]="'fadeIn'">
        <mat-form-field class="mw-305 mat-form-field_sm full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" placeholder="user@email.com" name="email" id="email" maxlength="35" min="7" #femail="ngModel" [(ngModel)]="fEmail" required autofocus>
          <mat-icon matSuffix class="material-icons-outlined">email</mat-icon>
          <mat-hint>Enter your email to reset your password.</mat-hint>
          <mat-error *ngIf="femail.hasError('minlength')">Email is too short.</mat-error>
          <mat-error *ngIf="femail.hasError('maxlength')">Email is too long.</mat-error>
          <mat-error *ngIf="femail.hasError('required')">Email is required.</mat-error>
        </mat-form-field>
      </div>
      <ng-container *ngIf="auth.error">
        <div fxLayout="row" fxLayoutAlign="center center" class="mb-15 ph-15">
          {{auth.error}}
        </div>
      </ng-container>
      <br />
      <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-0 mt-10 ph-05">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="60">
          <button mat-raised-button type="submit" color="primary" class="full-width" [disabled]="auth.isLoading">Reset password</button>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-05 ph-05">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="60">
          <button mat-button type="button" class="full-width" [disabled]="auth.isLoading" (click)="showForgotPass = false">Back to login</button>
        </div>
      </div>
    </form>

  </ng-template>


</div>
