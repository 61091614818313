export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyA8lWz4RATAEoFSXpC58MPO9De94wFnYT0",
    authDomain: "mortgage-partner.firebaseapp.com",
    databaseURL: "https://mortgage-partner-default-rtdb.firebaseio.com",
    projectId: "mortgage-partner",
    storageBucket: "mortgage-partner.appspot.com",
    messagingSenderId: "1061785793247",
    appId: "1:1061785793247:web:c65b809289f9716a91ef6a",
    measurementId: "G-0G7207FXMZ"
  }
};
