<ng-container *ngIf="type === 'liveChat'">
  <ng-container [ngSwitch]="matType">
    <button *ngSwitchDefault mat-button [color]="matColor" (click)="store.openLiveChat()" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</button>
    <button *ngSwitchCase="'raised'" mat-raised-button [color]="matColor" (click)="store.openLiveChat()" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</button>
    <button *ngSwitchCase="'stroked'" mat-stroked-button [color]="matColor" (click)="store.openLiveChat()" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</button>
    <button *ngSwitchCase="'flat'" mat-flat-button [color]="matColor" (click)="store.openLiveChat()" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</button>
    <button *ngSwitchCase="'icon'" mat-icon-button [color]="matColor" (click)="store.openLiveChat()" [ngClass]="extraClass" [ngStyle]="extraStyle">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </button>
    <button *ngSwitchCase="'fab'" mat-fab [color]="matColor" (click)="store.openLiveChat()">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </button>
    <button *ngSwitchCase="'mini-fab'" mat-mini-fab [color]="matColor" (click)="store.openLiveChat()">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </button>
  </ng-container>
</ng-container>
<ng-container *ngIf="type === 'tel'">
  <ng-container [ngSwitch]="matType">
    <a *ngSwitchDefault mat-button [color]="matColor" href="tel:8013020100" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'raised'" mat-raised-button [color]="matColor" href="tel:8013020100" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'stroked'" mat-stroked-button [color]="matColor" href="tel:8013020100" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'flat'" mat-flat-button [color]="matColor" href="tel:8013020100" [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'icon'" mat-icon-button [color]="matColor" href="tel:8013020100" [ngClass]="extraClass" [ngStyle]="extraStyle">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </a>
    <a *ngSwitchCase="'fab'" mat-fab [color]="matColor" href="tel:8013020100" [ngClass]="extraClass" [ngStyle]="extraStyle">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </a>
    <a *ngSwitchCase="'mini-fab'" mat-mini-fab [color]="matColor" href="tel:8013020100" [ngClass]="extraClass" [ngStyle]="extraStyle">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </a>
  </ng-container>
</ng-container>
<ng-container *ngIf="type === 'email'">
  <ng-container [ngSwitch]="matType">
    <a *ngSwitchDefault mat-button [color]="matColor" href="mailto:tyler@mortgagepartner.com?subject=I'm Interest in Talking to a Loan Officer&body=Let us know how we can reach you and we will reach out ASAP! Make sure to tell us your name and leave a phone number." [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'raised'" mat-raised-button [color]="matColor" href="mailto:tyler@mortgagepartner.com?subject=I'm Interest in Talking to a Loan Officer&body=Let us know how we can reach you and we will reach out ASAP! Make sure to tell us your name and leave a phone number." [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'stroked'" mat-stroked-button [color]="matColor" href="mailto:tyler@mortgagepartner.com?subject=I'm Interest in Talking to a Loan Officer&body=Let us know how we can reach you and we will reach out ASAP! Make sure to tell us your name and leave a phone number." [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'flat'" mat-flat-button [color]="matColor" href="mailto:tyler@mortgagepartner.com?subject=I'm Interest in Talking to a Loan Officer&body=Let us know how we can reach you and we will reach out ASAP! Make sure to tell us your name and leave a phone number." [ngClass]="extraClass" [ngStyle]="extraStyle">{{ buttonText }}</a>
    <a *ngSwitchCase="'icon'" mat-icon-button [color]="matColor" href="mailto:tyler@mortgagepartner.com?subject=I'm Interest in Talking to a Loan Officer&body=Let us know how we can reach you and we will reach out ASAP! Make sure to tell us your name and leave a phone number." [ngClass]="extraClass" [ngStyle]="extraStyle">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </a>
    <a *ngSwitchCase="'fab'" mat-fab [color]="matColor" href="mailto:tyler@mortgagepartner.com?subject=I'm Interest in Talking to a Loan Officer&body=Let us know how we can reach you and we will reach out ASAP! Make sure to tell us your name and leave a phone number." [ngClass]="extraClass" [ngStyle]="extraStyle">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </a>
    <a *ngSwitchCase="'mini-fab'" mat-mini-fab [color]="matColor" href="mailto:tyler@mortgagepartner.com?subject=I'm Interest in Talking to a Loan Officer&body=Let us know how we can reach you and we will reach out ASAP! Make sure to tell us your name and leave a phone number. Make sure to tell us your name and leave a phone number." [ngClass]="extraClass" [ngStyle]="extraStyle">
      <mat-icon class="material-icons-outlined" [ngClass]="iconClass">{{ iconText }}</mat-icon>
    </a>
  </ng-container>
</ng-container>
