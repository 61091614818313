import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FirebaseApp } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { from, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { fxArray } from 'src/app/models';
import { AuthService } from 'src/app/shared';
import { FirebaseErrors, FireStorageErrors } from 'src/app/shared/models/error.model';
import { UserInfo } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
declare var ImageCompressor: any;

// const URL = '/api/';
const URL = '';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  animations: fxArray
})
export class UploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tempForm', { static: false }) tempForm: NgForm;
  @ViewChild("topOfPage", { static: false }) scrollToTopOfPage: ElementRef;
  @ViewChild("uploadingItems", { static: false }) scrollToUploadingItems: ElementRef;
  siteKey = "6LcP950aAAAAAHlsC-29CpZ7Ju8kQLTHHX1acxcx"

  animate: boolean = false;
  public uploader: FileUploader = new FileUploader({ url: '' });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  fileObject: any;
  data: UserInfo;
  hasTempUser: boolean = false;
  rememberTemp: boolean = false;
  index: number = 0;
  uid: string;
  storage: any;
  uploadIndex: number = 0;
  savingNeededItems: boolean = false;
  waitingForUser: boolean = true;
  isSaving: boolean = false;
  showLogin: boolean = false;
  queuedItems: any;

  constructor(
    @Inject(FirebaseApp) firebaseApp: any,
    public afAuth: AngularFireAuth,
    public snackBar: MatSnackBar,
    private afStorage: AngularFireStorage,
    // private reCaptchaV3Service: ReCaptchaV3Service,
    public store: UserService,
    public auth: AuthService,
  ) {
    this.storage = firebaseApp.storage();
    this.uid = localStorage.getItem('uid');
    this.uploader = new FileUploader({
      url: '',
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });


    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

  }

  ngOnInit(): void {
    this.queuedItems = [];
    this.data = new UserInfo();
    localStorage.getItem('user')
    // this.reCaptchaV3Service.execute(this.siteKey, 'upload', (token) => {
    //   console.log('This is your token: ', token);
    // }, {
    //   useGlobalDomain: false
    // });
    this.afAuth.authState.subscribe((user: any) => {
      if (user) {
        this.store.getUser(user.uid);
        this.showLogin = false;
      } else {
        localStorage.setItem('user', null);
        this.store.clearUserData();
        this.showLogin = true;
        console.log("Not logged in");

        //   const tempUserCache = JSON.parse(localStorage.getItem('tempUser'));
        //   if (tempUserCache) {
        //     this.data = tempUserCache;
        //     // this.hasTempUser = true;
        //     this.rememberTemp = true;
        //   }
        //   setTimeout(() => {
        //     this.waitingForUser = false;
        //     this.showLogin = true;
        //   }, 100);
      }
      this.waitingForUser = false;
    });
  }

  ngAfterViewInit(): void {

  }
  ngOnDestroy(): void { }


  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    let index = 0;
    from(event).pipe(untilDestroyed(this)).subscribe((i: any) => {
      index++
      this.prepFile(i);
    });

    // this.readBase64(file)
    //   .then((data) => {
    //   })

  }

  handleSuccess(event) {
    console.log("🚀 ~ file: upload.component.ts ~ line 80 ~ UploadComponent ~ handleSuccess ~ event", event);

  }

  prepFile(file: File) {
    const item = { name: file.name, originalName: file.name, lastModified: file.lastModified, uid: this.store.userInfo$.value.uid, user: this.store.userInfo$.value.firstName + ' ' + this.store.userInfo$.value.lastName, date: moment(moment()).format('MM-DD-YYYY LT'), expires: Date.now() + 1000 * 60 * 60 * 24 * 182.5 };
    item.name = moment(moment()).format('MM-DD-YYYY-HH-MM') + '__' + file.name;
    // item.name = moment(moment()).unix() + '_' + file.name;
    item.name = item.name.split(' ').join('-');
    this.queuedItems.push(item);
  }

  async uploadDocs() {
    // Needs to be a component bound index due to files being large and finishing upload slower.
    this.uploadIndex = 0;
    this.scrollTo('uploadingItems');
    this.uploader.queue.map((doc, index) => {
      // Change the file name to match what is uploaded to storage and the db.
      doc.file.name = moment(moment()).format('MM-DD-YYYY-HH-MM') + '__' + doc.file.name;
      doc.file.name = doc.file.name.split(' ').join('-');
      // Compressing images
      // If the file is not an image, continue with the the upload.
      const compressor = new ImageCompressor();
      if (doc.file.type != "application/pdf") {
        compressor.compress(doc.file.rawFile, { quality: .5 })
          .then((res) => {
            console.log("Compressed file.");
            doc.file.rawFile = res;
            this.sendUpload(doc);
          }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log("Failed to compress file, continuing upload.", error);
            this.sendUpload(doc);
          });
      } else {
        console.log("PDF, not compressing file.");
        this.sendUpload(doc);
      }
    });
  }

  async sendUpload(doc) {
    var task: any;
    if (doc.progress < 100) var task = this.store.uploadDoc(doc);

    task.percentageChanges().pipe(untilDestroyed(this)).subscribe((x) => {
      doc.progress = x;
    }, (error) => {
      this.snackBar.open(FireStorageErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
    }
    );
    if (doc.progress < 100) this.store.uploadDocToMaster(doc)
      .pipe(untilDestroyed(this)).subscribe(x => {
      }, (error) => {
        this.snackBar.open(FireStorageErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
      });

    await task.then((res) => {
      if (res.bytesTransferred === res.totalBytes) {
        this.uploadIndex++;
        if (this.uploader?.queue?.length === this.uploadIndex) this.updateItems();
      }
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log("Failed to upload.", error);
    });
  }


  async updateItems() {
    let index = 0;
    const customMetadata = {
      uid: this.store.userInfo$.value?.uid,
      cacheControl: 'public,max-age=300',
      contentDisposition: 'attachment',
    };
    this.savingNeededItems = true;
    this.queuedItems.map(file => {
      index++;
      var getURL = this.afStorage.refFromURL(`gs://mortgage-partner.appspot.com/mortgage-partner/users/${this.store.userInfo$.value.uid}/${file.name}`);
      // Set the storage item's meta data.
      // Switching the contentDisposition from inline to attachment (this lets us download the file instead of opening it in a new tab).
      getURL.updateMetadata(customMetadata);
      // Get the download url for the file.
      getURL.getDownloadURL().pipe(take(1)).subscribe(x => {
        file.downloadURL = x;
        this.store.uploadDocToDB(file);
        this.store.uploadDocToMasterDB(file);

        if (this.queuedItems.length === index) {
          this.queuedItems = [];
        }
      })


      //
      // ======== Base 64 Version ========
      //
      // this.store.uploadDocToDB(file)
      //   .then((res) => {
      //     this.ngZone.run(() => {
      //       console.log("File uploaded to user");
      //       this.store.uploadDocToMasterDB(file)
      //         .then((res) => {
      //           this.ngZone.run(() => {
      //             console.log("File uploaded to master");
      //           });
      //         }).catch((error) => {
      //           console.log("Server error, please let us know if you see this. Error: ", error);
      //         });
      //     });
      //   }).catch((error) => {
      //     console.log("Upload save error: ", error);
      //     this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
      //   });
    });
  }




  async submitTemp() {
    this.isSaving = true
    if (this.tempForm.valid) {
      // Save the temp user for later
      if (this.rememberTemp) localStorage.setItem('tempUser', JSON.stringify(this.data));
      this.hasTempUser = true;
      this.isSaving = false;
    }
    else {
      this.snackBar.open("Please fill out the form to continue.", "Close", { duration: 3000, panelClass: ["error"] });
    }

  }

  async scrollTo(destination: string) {
    if (destination === 'topOfPage') this.scrollToTopOfPage.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    if (destination === 'uploadingItems') this.scrollToUploadingItems.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  async login() {
    this.showLogin = true;
  }

  async removedFileAttached(item) {
    // Removing file from upload queue items to itemsNeeded queue.
    this.queuedItems.map((x, index) => {
      if (x.originalName === item.file.name) this.queuedItems.splice(index, 1);
      return x;
    })
    // Removing file from upload to Firestore Storage queue.
    this.uploader.queue.map((q, index) => {
      if (q.file.name === item.file.name) this.uploader.queue.splice(index, 1);
      // if (q.name === item.name) q.splice(1, 1);
      // return q;
    })
  }

}

function readBase64(file): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);

    reader.readAsDataURL(file);
  });
  return future;
}

function FirebaseRef(FirebaseRef: any) {
  throw new Error('Function not implemented.');
}