<div class="footer-section">
  <div class="grid-container grid-container_full pv-0 mt-0 mb-0">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <mat-divider fxFlex="100"></mat-divider>
    </div>
  </div>
  <div class="grid-container pv-0 mt-0 mb-0">
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h2 class="mb-05 font-weight-400 text-center">The Mortgage Partner</h2>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="text-muted mb-15">Your Partner For Life</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social" /></a>
    </div>
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center start">
      <!-- Footer section: 1 -->
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="25">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h5 class="mb-20">Company</h5>
        </div>

        <!-- <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-divider fxFlex="30%"></mat-divider>
        </div> 
        <br />-->

        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="[ 'about' ]" rel="noopener">About Us</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="[ 'contact' ]" rel="noopener">Contact Us</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="['../', 'apply' ]" rel="noopener">Start an application</a>
        </div>
      </div>
      <!-- Footer section: 2 -->
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="25">
        <br fxShow.lt-md fxHide.gt-sm />
        <br fxShow.lt-md fxHide.gt-sm />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h5 class="mb-20">Loan Resources</h5>
        </div>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-divider fxFlex="30%"></mat-divider>
        </div>
        <br />-->

        <!-- <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" href="javascript();">How much do I qualify for?</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" href="javascript();">Mortgage rates in Utah</a>
        </div> -->
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="[ 'loan-process' ]" rel="noopener">Loan Process</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="[ 'mortgage-partner-tools', 'utah-mortgage-loan-limits' ]" rel="noopener">Utah Loan Limits</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="[ 'mortgage-partner-tools', 'utah-mortgage-glossary' ]" rel="noopener">Mortgage Glossary</a>
        </div>
      </div>
      <br fxShow.lt-md fxHide.gt-sm />
      <!-- Footer section: 3 -->
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="25">
        <br fxShow.lt-md fxHide.gt-sm />
        <br fxShow.lt-md fxHide.gt-sm />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h5 class="mb-20">Miscellaneous</h5>
        </div>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-divider fxFlex="30%"></mat-divider>
        </div>
        <br />-->

        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn"
            href="https://www.google.com/search?q=mortgage+partner&rlz=1C1GCEA_enUS936US936&oq=mortgage+partner&aqs=chrome.0.0i355j46i175i199j0l3j69i60l3.3610j1j7&sourceid=chrome&ie=UTF-8#lrd=0x8752869290356721:0x9ad9404e969358a4,3,,," rel="noopener"
            target="_blank">Leave us a review on Google</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="[ 'articles' ]" rel="noopener">Articles</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" [routerLink]="[ 'privacy-policy' ]" rel="noopener">Privacy Policy</a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a class="footer-btn" href="javascript();">Sitemap</a>
        </div>
      </div>
      <!-- Footer section: 4 -->
      <div fxFlex.lt-md="100" fxFlex.gt-sm="50" fxFlex.gt-md="25">
        <br fxShow.lt-md fxHide.gt-sm />
        <br fxShow.lt-md fxHide.gt-sm />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h5 class="mb-20">Latest Articles</h5>
        </div>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-divider fxFlex="30%"></mat-divider>
        </div>
        <br />-->
        <ng-container *ngFor="let article of store.articles$.value; let i=index">
          <div fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="i<4">
            <a class="footer-btn" [routerLink]="[ './', 'articles', article.docKey ]" rel="noopener" (click)="store.getArticle(article.docKey)">{{article.title}}</a>
          </div>
        </ng-container>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div>
        <img class="footer-ehl" src="../../../assets/images/EHL.png">
      </div>
      <div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="mb-0">The Mortgage Partner, Inc.</p>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <a mat-button class="font-weight-400" target="_blank" href="https://www.google.com/maps/place/The+Mortgage+Partner+Inc/@40.5218423,-111.9310763,15z/data=!4m5!3m4!1s0x0:0x9ad9404e969358a4!8m2!3d40.5219891!4d-111.9305291" rel="noopener">1369
            W 12600 S Riverton, UT 84065 </a>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <p class="mb-0 font-weight-400"><small class="font-weight-400">NMLS # 3123</small></p>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="text-center">Copyright © {{date}} The Mortgage Partner, Inc. All Rights Reserved.</p>
    </div>
  </div>
</div>
