import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import * as e from 'express';
import { UserService } from '../services/user.service';
import { WindowRef } from '../services/window.service';

@Component({
  selector: 'chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss']
})
export class ChatButtonComponent implements OnInit {
  @Input() type;
  @Input() matType;
  @Input() matColor;
  @Input() extraClass;
  @Input() extraStyle;
  @Input() buttonText;
  @Input() routerLink;
  @Input() iconClass;
  @Input() iconText;
  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (isPlatformBrowser(this.platformId)) {
      // Use the window reference: this.windowRef
      this.innerWidth = this.windowRef.nativeWindow.innerWidth
    }
    // else innerWidth = window.innerWidth
  }

  constructor(
    public store: UserService,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRef
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Use the window reference: this.windowRef
      this.innerWidth = this.windowRef.nativeWindow.innerWidth
    }
    // else innerWidth = window.innerWidth
    if (!this.type) {
      // if (this.innerWidth >= 768) this.type = 'liveChat'
      if (this.innerWidth >= 768) this.type = 'email'
      else this.type = 'tel';
    }
    if (!this.matType) this.matType = 'raised';
    if (!this.matColor) this.matColor = 'primary';

    // Temp until LiveChat
    if (this.innerWidth >= 768) this.type = 'email'
    else this.type = 'tel';
  }

}
