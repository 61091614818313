<div class="light-bg">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center" style="font-size: 8em;">404</h1>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400 text-center">Looks like we didn't find what you were looking for, let's take you back to somewhere safe.</p>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <a mat-raised-button color="primary" [routerLink]="[ '../', 'home' ]" rel="noopener">Go to somewhere safe</a>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</div>
