import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() height;
  @Input() width;


  constructor() { }

  ngOnInit(): void {
    if(!this.height) this.height = '100%'
    else this.height = this.height + 'px'
    if(!this.width) this.width = 'auto';
  }

}
