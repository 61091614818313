/********************************************************
 * 
 *            Text Mask Helper Functions:
 * These functions are used to assist angular2-text-mask use optional values
 * textMaskDateFn: allows for optional leading zero in month and day
 * textMaskPhoenFn: allows for optional leading 1 e.g. +1 (801) 653-0070
 **************************************************************/
export function textMaskDateFn(userInput) {
    if (userInput.match(/^(13)$/)) {
        // Allow user to enter 1 as the month. (most of the time)
        // console.log(`${userInput} => @Month-overflow`);
        return ['0', /[1-9]/, '/', /[3]/, /[0-1]/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    } else if (userInput.match(/^(1[4-9])$/)) {
        // Allow user to enter 1 as the month. (most of the time)
        // console.log(`${userInput} => @Month-1`);
        return ['0', /[1-9]/, '/', '0', /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    } else if (userInput.match(/^([2-9]|[1-9]\/)$/)) {
        // Allow user to not enter zero in month
        // console.log(`${userInput} => @Month`);
        return ['0', /[1-9]/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    } else if (userInput.match(/^[01]\d\/([4-9]|[1-9]\/|3[2-9])$/)) {
        // Allow user to not enter zero in the day
        // console.log(`${userInput} => @Day`);
        return [/[01]/, /[1-9]/, '/', '0', /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    } else {
        // Use Default Input
        // console.log(`${userInput} => @Default`);
        return [/[01]/, /[1-9]/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    }
}

// export const textMaskDob = [/0?[1-9],1[0-2],/([0-2]?[1-9]|[1-3][01])/\d{4}$/];


export function textMaskPhoneFn(userInput) {
    if (userInput.match(/^\+/)) {
        // console.log(`${userInput} => @Plus`);
        return ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    if (userInput.match(/^1/)) {
        // console.log(`${userInput} => @One`);
        return ['1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
        // console.log(`${userInput} => @Default`);
        return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
}

export const textMaskCard = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
export const textMaskExpire = [/[0-9]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const textMaskCVC = [/[0-9]/, /\d/, /\d/, /\d/,];

export const textMaskAccount = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const textMaskRouting = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const textMaskEIN = [/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const textMaskSSN = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];