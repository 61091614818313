import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disclosures',
  templateUrl: './disclosures.component.html',
  styleUrls: ['./disclosures.component.scss']
})
export class DisclosuresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
