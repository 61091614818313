<div class="image-standout">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">The Loan Process</h1>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400">Helping you understand the mortgage loan process.</p>
    </div>
  </div>
</div>
<div class="light-bg">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h2 class="mb-05 font-weight-400 text-center">Saving You And Your Family Money</h2>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start start">
      <p>Taking out a loan, understandably, can be an intimidating experience to new borrowers who are trying to gain their footing on the property ladder. Dealing with banks and lenders can be a lengthy and complex process; handling the paperwork and
        putting together all the requirements can be overwhelming.</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start start">
      <p>
        The Mortgage Partner aims to simplify the loan process for you. Let us guide you through the process, explaining each step in a clear and concise manner. Educating our clients is always top priority for us, because we want them to make fully
        informed decisions when taking out a mortgage. After all, your mortgage will likely be one of the biggest loans of your lifetime.</p>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="7.5px">
      <div >
        <chat-button matType="raised" matColor="accent" buttonText="Chat with us"></chat-button>
        <!-- <a mat-raised-button style="font-size: 16px;" color="accent" href="tel:8013020100">Give us a call</a> -->
      </div>
      <div>
        <a mat-button rel="noopener" class="with-border" [routerLink]="['../', 'apply' ]">Apply online</a>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
</div>
<div class="grid-container ph-15 ph-10-sm">
  <!-- Subsection 5: Stand Out -->
  <br />
  <br />
  <br />
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center start">
    <h2 class="mb-15 font-weight-400 text-center">Five-Step Loan Process</h2>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center start">
    <mat-divider fxFlex="60"></mat-divider>
  </div>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <p>We are your partners through the entire process of taking out a mortgage. We are committed to providing you with a smooth and painless experience so that you can approach your loan with confidence. Working with us is a partnership, and we are
      dedicated to giving you all the help that you need. We aid in managing your mortgage and determining what type is right for you. See how we break down the loan process below in these five steps:</p>
  </div>

  <br />
  <br />
  <div style="position: relative">
    <!-- <mat-divider [vertical]="true" style="position: absolute;
    display: block;
    height: 100%;
    left: 61px;
    top: 0;
    overflow: hidden;
    border-right-style: dotted;
    border-right-width: 7px;
    border-right-color: #43454e;">
    </mat-divider> -->
    <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-15">
      <div fxFlex="94px">
        <mat-icon class="material-icons-outlined ml-15 mr-15 mat-64" style="z-index: 1;">looks_one</mat-icon>
      </div>
      <div fxFlex="calc(100% - 94px)">
        <h4 class="mb-15 mt-10">Contact us or start your application</h4>
        <p class="mb-0">
          Once contact is made, or we receive your online application, we gather your information regarding income, debts, and credit score. Once this information is gathered, we determine about how much you will be able to borrow. Different loan
          programs may lead to different values, depending on whether you are qualified for them, so be sure to get a pre-qualification for each type of program you are suited for..</p>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-15">
      <div fxFlex="94px">
        <mat-icon class="material-icons-outlined ml-15 mr-15 mat-64" style="z-index: 1;">looks_two</mat-icon>
      </div>
      <div fxFlex="calc(100% - 94px)">
        <h4 class="mb-15 mt-10">Application process</h4>
        <p class="mb-0">
          The application is the beginning of the loan process. You will complete a mortgage application with us and supply all of the required documentation for processing. Various fees and down payments are discussed at this time. You will then
          receive a Loan Estimate (LE) within three days that itemizes the rates and associated costs for obtaining the loan.</p>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-15">
      <div fxFlex="94px">
        <mat-icon class="material-icons-outlined ml-15 mr-15 mat-64" style="z-index: 1;">looks_3</mat-icon>
      </div>
      <div fxFlex="calc(100% - 94px)">
        <h4 class="mb-15 mt-10">Loan expert processing</h4>
        <p class="mb-0">
          The processor reviews your credit report and verifies debts, payment histories, income and your assets. If there are unacceptable late payments, collections for judgment, etc., a written explanation is required. The processor also reviews
          the
          appraisal and checks for property issues that may require further discernment. The processor puts together a package that will be underwritten by the lender.</p>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-15">
      <div fxFlex="94px">
        <mat-icon class="material-icons-outlined ml-15 mr-15 mat-64" style="z-index: 1;">looks_4</mat-icon>
      </div>
      <div fxFlex="calc(100% - 94px)">
        <h4 class="mb-15 mt-10">Underwriting</h4>
        <p class="mb-0">
          The underwriter is responsible for determining whether the combined package submitted is an acceptable loan. If more information is needed, you are contacted by the processor to supply more documentation.</p>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-15">
      <div fxFlex="94px">
        <mat-icon class="material-icons-outlined ml-15 mr-15 mat-64" style="z-index: 1;">looks_5</mat-icon>
      </div>
      <div fxFlex="calc(100% - 94px)">
        <h4 class="mb-15 mt-10">Closing</h4>
        <p class="mb-0">
          This will take place at the title company. The escrow officer will prepare the closing documents, insure that all paperwork is signed appropriately, notarize your signature and disperse the funds according to the lenders instruction. They
          are
          a third unbiased party that neither represents the seller, buyer, real estate agent, broker or lender. They will record the note and provide you with a copy of all the documents you signed at the closing.</p>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="7.5px">
      <div wmAnimate="fadeInUp" speed="fast"  >
        <chat-button matType="raised" matColor="primary" buttonText="Talk to a loan expert"></chat-button>
        <!-- <a mat-raised-button style="font-size: 16px;" color="primary" href="tel:8013020100">Talk to a loan expert</a> -->
      </div>
      <div wmAnimate="fadeInUp" speed="fast"  >
        <a mat-button rel="noopener" color="black" class="with-border" [routerLink]="['../', 'apply' ]">Apply online</a>
      </div>
    </div>
  </div>
</div>
<div class="social-standout">
  <div class="grid-container">
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <mat-divider fxFlex="50"></mat-divider>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <h2 class="mb-05 font-weight-400">Social Media</h2>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">
      <p>Connect with us on social media.</p>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-15">

      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
      <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg" class="mat-icon-button-social" /></a>

    </div>
    <br />
    <br />
    <br />
  </div>
</div>
