<div class="light-bg">
  <div class="grid-container">
    <!-- Subsection 5: Stand Out -->
    <br />
    <br />
    <br />
    <br />
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <h1 class="mb-05 font-weight-400 text-center">Mortgage Partner online application</h1>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <p class="font-weight-400 text-center">Looking for a mortgage loan in Utah? You've come to the right place.</p>
    </div>
    <br />
    <br />
    <br />
  </div>
</div>
<br />
<br />
<div class="grid-container grid-container_lg">
  <div #userInfo></div>
  <div fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxFlex.lt-md="1 1 100" fxFlex.gt-sm="1 1 75" fxFlexOrder.gt-sm="1" fxFlexOrder.lt-md="2" fxLayout="column">
      <br />
      <form autocomplete="on" role="form" name="appForm" method="post" accept-charset="UTF-8" #appForm="ngForm" (ngSubmit)="submitApplication()">
        <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutAlign.gt-md="center stretch">
          <div fxFlex="1 1 100">
            <mat-card class="no-float" [@400]="'fadeIn'" class="mat-elevation-z6">
              <div fxLayout="row wrap" fxLayoutAlign="end center">
                <button mat-icon-button type="button" [matMenuTriggerFor]="helpMenu" matTooltip="Click for application help" matTooltipPosition="after">
                  <mat-icon class="material-icons-outlined">help_outline</mat-icon>
                </button>
              </div>
              <ng-container *ngIf="auth.isLoggedIn; else loginComp">
                <div class="overlay white-90" [ngClass]="{'show':animate}">
                  <div class="overlay-icon overlay-success animate">
                    <span class="overlay-line overlay-tip animateSuccessTip"></span>
                    <span class="overlay-line overlay-long animateSuccessLong"></span>
                    <div class="overlay-placeholder"></div>
                    <div class="overlay-fix"></div>
                  </div>
                </div>
                <!-- <div class="overlay white-90" [ngClass]="{'show':store.isLoadingUser}">
                    <div class="grid-container grid-container_full">
                      <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <div class="loading">
                          <mat-spinner [diameter]="36" color="primary"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </div> -->
                <br>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="ph-20">
                  <div fxFlex.gt-sm="33.333" fxFlex.lt-md="100" fxFlexOrder.gt-sm="1" fxFlexOrder.lt-md="2">
                    <br fxHide.gt-sm fxShow.lt-md />
                    <div fxLayout="row wrap" fxLayoutAlign.gt-sm="start center" fxLayoutAlign.lt-md="center center" *ngIf="data && data.id">
                      <h5 class="mb-0">
                        Loan # {{data.id}}
                      </h5>
                    </div>
                  </div>
                  <div fxFlex.gt-sm="33.333" fxFlex.lt-md="100" fxFlexOrder.gt-sm="2" fxFlexOrder.lt-md="1">
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                      <!-- <h5 class="mb-0"><small>Status: &nbsp;</small>{{data.status}}</h5> -->
                      <app-logo style="height: 84px; width: auto;"></app-logo>
                    </div>
                  </div>
                  <div fxFlex.gt-sm="33.333" fxFlex.lt-md="100" fxFlexOrder.gt-sm="3" fxFlexOrder.lt-md="3">
                    <br fxHide.gt-sm fxShow.lt-md>
                    <ng-container *ngIf="data && data.id">
                      <div fxLayout="row wrap" fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="center center">
                        <a mat-raised-button rel="noopener" color="primary" [routerLink]="[ '../', 'account', 'items-needed' ]">View items needed</a>
                      </div>
                      <br>
                      <br>
                    </ng-container>
                  </div>
                </div>
                <br>
                <br>
                <div fxLayoutAlign="space-between center" fxLayout="row wrap">
                  <h4 class="mb-0 font-weight-400">
                    <mat-icon class="Completed mat-18 prt-03 mr-05" *ngIf="data.firstName && data.lastName && data.phoneNumber && data.emailAddress && data.estimatedCreditScore && data.dob" [@400]="'fadeIn'">
                      done_outline</mat-icon>
                    <mat-icon class="material-icons-outlined prt-07 mr-05 mat-30">person</mat-icon> Your information
                  </h4>
                </div>
                <!-- <p>To get started, tell us a little about yourself.</p> -->
                <br>
                <br>
                <div fxLayoutAlign="start center" fxLayout="row wrap">
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="35" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.firstName}">
                    <mat-label>First name</mat-label>
                    <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="John/Jane" id="legalFName" name="legalFName" #legalFName="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.firstName"
                      mapError="firstName" required minlength="1" maxlength="46" autocomplete="given-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                    <mat-error *ngIf="legalFName.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                    <mat-error *ngIf="legalFName.hasError('required')">First name is required.</mat-error>
                    <mat-error *ngIf="legalFName.hasError('server')">{{legalFName.errors['server']}}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.middleName}">
                    <mat-label>Middle name</mat-label>
                    <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="Doe" id="legalMName" name="legalMName" #legalMName="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.middleName"
                      mapError="middleName" minlength="1" maxlength="46" autocomplete="additional-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                    <mat-error *ngIf="legalMName.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                    <mat-error *ngIf="legalMName.hasError('required')">Middle name is required.</mat-error>
                    <mat-error *ngIf="legalMName.hasError('server')">{{legalMName.errors['server']}}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="35" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.lastName}">
                    <mat-label>Last name</mat-label>
                    <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="Doe" id="legalLName" name="legalLName" #legalLName="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.lastName" mapError="lastName"
                      required minlength="1" maxlength="46" autocomplete="family-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                    <mat-error *ngIf="legalLName.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                    <mat-error *ngIf="legalLName.hasError('required')">Last name is required.</mat-error>
                    <mat-error *ngIf="legalLName.hasError('server')">{{legalLName.errors['server']}}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.phoneNumber}">
                    <mat-label>Phone number</mat-label>
                    <input matInput [disabled]="isAppDisabled" [textMask]="mask" tabindex="0" type="tel" placeholder="(801) 565-6565" id="phone" name="phone" #phone="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.phoneNumber"
                      mapError="phoneNumber" required phone minlength="14" autocomplete="tel">
                    <mat-error *ngIf="phone.hasError('required')">Phone number is required.</mat-error>
                    <mat-error *ngIf="phone.hasError('maxlength')">Cannot be longer than 16 characters.</mat-error>
                    <mat-error *ngIf="phone.hasError('server')">{{phone.errors['server']}}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.emailAddress}">
                    <mat-label>Email</mat-label>
                    <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="{{data.firstName ? data.firstName : 'name'}}@email.com" id="email" name="email" #eAddress="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                      [(ngModel)]="data.emailAddress" required maxlength="62" autocomplete="email">
                    <mat-error *ngIf="eAddress.hasError('required')">Email is required.</mat-error>
                    <mat-error *ngIf="eAddress.hasError('maxlength')">Cannot be longer than 62 characters.</mat-error>
                    <mat-error *ngIf="eAddress.hasError('server')">{{eAddress.errors['server']}}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="40" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.estimatedCreditScore}">
                    <mat-label>Estimated credit score</mat-label>
                    <mat-select [disabled]="isAppDisabled" tabindex="0" id="estCredit" placeholder="Estimated credit score" name="estCredit" required #estCredit="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.estimatedCreditScore"
                      ngDefaultControl>
                      <mat-option value="select" selected="selected" disabled="disabled">Type of Property</mat-option>
                      <mat-option value="Excellent (760-850)">Excellent (760-850)</mat-option>
                      <mat-option value="Very good (740-799)">Very good (740-759)</mat-option>
                      <mat-option value="Good (670-739)">Good (670-739)</mat-option>
                      <mat-option value="Fair (580-669)">Decent (580-669)</mat-option>
                      <mat-option value="Poor (300-579)">Fair (300-579)</mat-option>
                    </mat-select>
                    <mat-hint>Estimated is okay</mat-hint>
                    <mat-error *ngIf="estCredit.hasError('required')">Estimated credit score is required</mat-error>
                    <mat-error *ngIf="estCredit.hasError('server')">{{estCredit.errors['server']}}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.ssn}">
                    <mat-label>Social Security Number</mat-label>
                    <mat-icon matSuffix class="clickable" (click)="decryptSsn()">{{showSsn ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [disabled]="isAppDisabled" [textMask]="ssnMask" tabindex="0" type="{{showSsn ? 'text' : 'password'}}" placeholder="123-45-6789" id="ssn" name="ssn" #ssn="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                      [(ngModel)]="data.ssn" minlength="11" (ngModelChange)="convertText('encrypt')" (blur)="scrubSsn()" autocomplete="none">
                    <mat-hint>
                      <strong>
                        <mat-icon class="prt-01">lock</mat-icon> Secure and encrypted
                      </strong>
                    </mat-hint>
                    <mat-error *ngIf="ssn.hasError('maxlength')">Cannot be longer than 11 characters.</mat-error>
                    <mat-error *ngIf="ssn.hasError('server')">{{ssn.errors['server']}}</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.dob}">
                    <mat-label>Date of birth</mat-label>
                    <input matInput [disabled]="isAppDisabled" tabindex="0" [matDatepicker]="dobPicker" placeholder="mm/dd/yyyy" id="bday" name="bday" #dob="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.dob" required
                      autocomplete="bday">
                    <mat-hint>mm/dd/yyyy</mat-hint>
                    <mat-error *ngIf="dob.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
                    <mat-error *ngIf="dob.hasError('server')">{{dob.errors['server']}}</mat-error>
                    <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dobPicker>
                      <mat-datepicker-actions>
                        <button mat-button matDatepickerCancel>Cancel</button>
                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      </mat-datepicker-actions>
                    </mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxLayoutAlign="start center" fxLayout="row wrap">
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="40" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.hasCoBorrower}">
                    <mat-label>Will there be a co-borrower?</mat-label>
                    <mat-select [disabled]="isAppDisabled" tabindex="0" id="hasCoBorrower" placeholder="Estimated credit score" name="hasCoBorrower" required #hasCoBor="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.hasCoBorrower"
                      ngDefaultControl>
                      <mat-option value="select" selected="selected" disabled="disabled">Co-borrower?</mat-option>
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasCoBor.hasError('required')">Estimated credit score is required</mat-error>
                    <mat-error *ngIf="hasCoBor.hasError('server')">{{hasCoBor.errors['server']}}</mat-error>
                  </mat-form-field>
                </div>
                <ng-container *ngIf="data.hasCoBorrower === 'Yes'">
                  <!-- <div fxLayoutAlign="center center" fxLayout="row wrap">
                      <div fxFlex="calc(100% - 32px)">
                        <mat-divider class="mat-card-divider"></mat-divider>
                      </div>
                    </div> -->
                  <br>
                  <br>
                  <div #coBorrower></div>
                  <div fxLayoutAlign="center center" fxLayout="row wrap">
                    <div fxFlex="60">
                      <mat-divider class="mat-card-divider"></mat-divider>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <br>
                    <br>
                    <br>
                    <h4 class="mb-0 font-weight-400">
                      <mat-icon class="Completed mat-18 prt-03 mr-05" *ngIf="data.coFirstName && data.coLastName && data.coPhoneNumber && data.coEmailAddress && data.coEstimatedCreditScore && data.coDob" [@400]="'fadeIn'">
                        done_outline</mat-icon>
                      <mat-icon class="material-icons-outlined prt-07 mr-05 mat-30">person_add</mat-icon> Co-borrower
                    </h4>
                  </div>
                  <br>
                  <div fxLayoutAlign="start center" fxLayout="row wrap">
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="35" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coFirstName}">
                      <mat-label>First name</mat-label>
                      <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="John/Jane" id="coFirstName" name="coFirstName" #coFirst="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.coFirstName" required
                        minlength="1" maxlength="46" autocomplete="given-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                      <mat-error *ngIf="coFirst.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                      <mat-error *ngIf="coFirst.hasError('required')">First name is required.</mat-error>
                      <mat-error *ngIf="coFirst.hasError('server')">{{coFirst.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coMiddleName}">
                      <mat-label>Middle name</mat-label>
                      <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="Doe" id="coMiddleName" name="coMiddleName" #coMiddle="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.coMiddleName" minlength="1"
                        maxlength="46" autocomplete="additional-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                      <mat-error *ngIf="coMiddle.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                      <mat-error *ngIf="coMiddle.hasError('required')">Middle name is required.</mat-error>
                      <mat-error *ngIf="coMiddle.hasError('server')">{{coMiddle.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="35" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coLastName}">
                      <mat-label>Last name</mat-label>
                      <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="Doe" id="coLastName" name="coLastName" #coLast="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.coLastName" required minlength="1"
                        maxlength="46" autocomplete="family-name" pattern="^[^-\s][a-zA-Z_\s-]+$">
                      <mat-error *ngIf="coLast.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                      <mat-error *ngIf="coLast.hasError('required')">Last name is required.</mat-error>
                      <mat-error *ngIf="coLast.hasError('server')">{{coLast.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coPhoneNumber}">
                      <mat-label>Phone number</mat-label>
                      <input matInput [disabled]="isAppDisabled" [textMask]="mask" tabindex="0" type="tel" placeholder="(801) 302-0100" id="coPhoneNumber" name="coPhoneNumber" #coPhone="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                        [(ngModel)]="data.coPhoneNumber" required phone minlength="14" autocomplete="tel">
                      <mat-error *ngIf="coPhone.hasError('required')">Phone number is required.</mat-error>
                      <mat-error *ngIf="coPhone.hasError('maxlength')">Cannot be longer than 16 characters.</mat-error>
                      <mat-error *ngIf="coPhone.hasError('server')">{{coPhone.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coEmailAddress}">
                      <mat-label>Email</mat-label>
                      <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="{{data.coFirstName ? data.coFirstName : 'name'}}@email.com" id="coEmailAddress" name="coEmailAddress" #coEmail="ngModel"
                        [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.coEmailAddress" required maxlength="62" autocomplete="email">
                      <mat-error *ngIf="coEmail.hasError('required')">Email is required.</mat-error>
                      <mat-error *ngIf="coEmail.hasError('maxlength')">Cannot be longer than 62 characters.</mat-error>
                      <mat-error *ngIf="coEmail.hasError('server')">{{coEmail.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="40" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.coEstimatedCreditScore}">
                      <mat-label>Estimated credit score</mat-label>
                      <mat-select [disabled]="isAppDisabled" tabindex="0" id="coEstimatedCreditScore" placeholder="Estimated credit score" name="coEstimatedCreditScore" required #coEstCredit="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                        [(ngModel)]="data.coEstimatedCreditScore" ngDefaultControl>
                        <mat-option value="select" selected="selected" disabled="disabled">Type of Property</mat-option>
                        <mat-option value="Excellent (760-850)">Excellent (760-850)</mat-option>
                        <mat-option value="Very good (740-799)">Very good (740-759)</mat-option>
                        <mat-option value="Good (670-739)">Good (670-739)</mat-option>
                        <mat-option value="Fair (580-669)">Decent (580-669)</mat-option>
                        <mat-option value="Poor (300-579)">Fair (300-579)</mat-option>
                      </mat-select>
                      <mat-hint>Estimated is okay</mat-hint>
                      <mat-error *ngIf="coEstCredit.hasError('required')">Estimated credit score is required</mat-error>
                      <mat-error *ngIf="coEstCredit.hasError('server')">{{coEstCredit.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coSsn}">
                      <mat-label>Social Security Number</mat-label>
                      <mat-icon matSuffix class="clickable" (click)="decryptCoSsn()">{{coShowSsn ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <input matInput [disabled]="isAppDisabled" [textMask]="ssnMask" tabindex="0" type="{{coShowSsn ? 'text' : 'password'}}" placeholder="123-45-6789" id="coSsn" name="coSsn" #coSsnum="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                        [(ngModel)]="data.coSsn" minlength="11" (ngModelChange)="convertCoText('encrypt')" (blur)="scrubCoSsn()" autocomplete="none">
                      <mat-hint>
                        <strong>
                          <mat-icon class="prt-01">lock</mat-icon> Secure and encrypted
                        </strong>
                      </mat-hint>
                      <mat-error *ngIf="coSsnum.hasError('maxlength')">Cannot be longer than 11 characters.</mat-error>
                      <mat-error *ngIf="coSsnum.hasError('server')">{{coSsnum.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coDob}">
                      <mat-label>Date of birth</mat-label>
                      <input matInput [disabled]="isAppDisabled" tabindex="0" [matDatepicker]="coDPicker" placeholder="mm/dd/yyyy" id="coDob" name="coDob" #coD="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.coDob" required>
                      <mat-hint>mm/dd/yyyy</mat-hint>
                      <mat-error *ngIf="coD.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
                      <mat-error *ngIf="coD.hasError('server')">{{dob.errors['server']}}</mat-error>
                      <mat-datepicker-toggle matSuffix [for]="coDPicker"></mat-datepicker-toggle>
                      <mat-datepicker #coDPicker>
                        <mat-datepicker-actions>
                          <button mat-button matDatepickerCancel>Cancel</button>
                          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                </ng-container>
                <br>
                <br>
                <br>
                <div #loanType></div>
                <mat-divider></mat-divider>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <br>
                  <br>
                  <br>
                  <br>
                  <h4 class="mb-0 mt-10 font-weight-400">
                    <mat-icon class="Completed mat-18 prt-03 mr-05"
                      *ngIf="(data.typeOfLoan === 'Refinance (cash out)' && data.cashOut || data.typeOfLoan === 'Refinance (no cash out)' || data.typeOfLoan === 'Purchase' ) && data.lengthOfLoan && data.loanTimeframe  && (data.typeOfLoan === 'Purchase' && data.desiredLoanAmount && data.propertyType || data.typeOfLoan != 'Purchase')"
                      [@400]="'fadeIn'">
                      done_outline</mat-icon>
                    <mat-icon class="material-icons-outlined prt-07 mr-05 mat-30">home_work</mat-icon> Desired loan
                  </h4>
                </div>
                <br>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <p>What kind of loan are you looking for? We'll use this information to determine how we can better serve you, and what loan programs will best fit your needs.</p>
                </div>
                <br>
                <div fxLayoutAlign="start center" fxLayout="row wrap">
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.typeOfLoan}">
                    <mat-label>Type of loan</mat-label>
                    <mat-select [disabled]="isAppDisabled" tabindex="0" id="typeOfLoan" placeholder="Type of loan" name="typeOfLoan" required #tOfLoan="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.typeOfLoan" ngDefaultControl
                      (ngModelChange)="checkLoanType(data)">
                      <mat-option value="select" selected="selected" disabled="disabled">Type of loan</mat-option>
                      <mat-option value="Purchase" (click)="data.rentOrOwn === 'Own'">Purchase</mat-option>
                      <mat-option value="Refinance (no cash out)">Refinance (no cash out)</mat-option>
                      <mat-option value="Refinance (cash out)">Refinance (cash out)</mat-option>
                    </mat-select>
                    <mat-hint>Desired loan</mat-hint>
                    <mat-error *ngIf="tOfLoan.hasError('required')">Type of loan is required</mat-error>
                    <mat-error *ngIf="tOfLoan.hasError('server')">{{tOfLoan.errors['server']}}</mat-error>
                  </mat-form-field>
                  <ng-container *ngIf="data.typeOfLoan">

                    <!-- Refinance options with cash out -->
                    <ng-container *ngIf="data.typeOfLoan === 'Refinance (cash out)'">
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.cashOut}">
                        <mat-label>Desired amount of cash out?</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="cashOut" placeholder="Type of loan" name="cashOut" required #cashBack="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.cashOut" ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">How much cash out?</mat-option>
                          <mat-option value="Less than $2,000">Less than $2,000</mat-option>
                          <mat-option value="$2,001-$6,000">$2,001-$6,000</mat-option>
                          <mat-option value="$6,001-$10,000">$6,001-$10,000</mat-option>
                          <mat-option value="$10,001-$20,000">$10,001-$20,000</mat-option>
                          <mat-option value="$20,001-$30,000">$20,001-$30,000</mat-option>
                          <mat-option value="Over $30,000">$30,000+</mat-option>
                          <mat-option value="Max I can take out">Max I can take out</mat-option>
                        </mat-select>
                        <mat-error *ngIf="cashBack.hasError('required')">Amount of cash out is required</mat-error>
                        <mat-error *ngIf="cashBack.hasError('server')">{{cashBack.errors['server']}}</mat-error>
                      </mat-form-field>
                    </ng-container>
                    <!-- Puchase options -->
                    <ng-container *ngIf="data.typeOfLoan === 'Purchase'">
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.propertyType}">
                        <mat-label>Purchase property type</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="currentProp" placeholder="Type of property" name="propType" required #propertyType="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.propertyType"
                          ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">Property type</mat-option>
                          <mat-option value="Primary residence">Primary residence</mat-option>
                          <mat-option value="Non-primary residence">Non-primary residence</mat-option>
                          <mat-option value="Investment property">Investment property</mat-option>
                        </mat-select>
                        <!-- <mat-hint>Purchase property</mat-hint> -->
                        <mat-error *ngIf="propertyType.hasError('required')">Type of property is required</mat-error>
                        <mat-error *ngIf="propertyType.hasError('server')">{{propertyType.errors['server']}}</mat-error>
                      </mat-form-field>
                    </ng-container>

                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.lengthOfLoan}">
                      <mat-label>Length of loan desired</mat-label>
                      <mat-select [disabled]="isAppDisabled" tabindex="0" id="lengthOfLoan" placeholder="Type of loan" name="lengthOfLoan" required #lOfLoan="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.lengthOfLoan"
                        ngDefaultControl>
                        <mat-option value="select" selected="selected" disabled="disabled">Length of loan desired</mat-option>
                        <mat-option value="30">30 years</mat-option>
                        <mat-option value="25">25 years</mat-option>
                        <mat-option value="20">20 years</mat-option>
                        <mat-option value="15">15 years</mat-option>
                        <mat-option value="10">10 years</mat-option>
                        <mat-option value="0">Less than 10 years</mat-option>
                      </mat-select>
                      <mat-error *ngIf="lOfLoan.hasError('required')">Length of loan desired is required</mat-error>
                      <mat-error *ngIf="lOfLoan.hasError('server')">{{lOfLoan.errors['server']}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.loanTimeframe}">
                      <mat-label>How quickly do you need the loan?</mat-label>
                      <mat-select [disabled]="isAppDisabled" tabindex="0" id="loanTimeframe" placeholder="Type of loan" name="loanTimeframe" required #lLoan="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.loanTimeframe"
                        ngDefaultControl>
                        <mat-option value="select" selected="selected" disabled="disabled">How quickly do you need the loan</mat-option>
                        <mat-option value="0">As soon as possible</mat-option>
                        <mat-option value="2">1-2 weeks</mat-option>
                        <mat-option value="4">Within 4 weeks</mat-option>
                        <mat-option value="6">Within 6 weeks</mat-option>
                        <mat-option value="60">Within 2 months</mat-option>
                        <mat-option value="120">Within 4 months</mat-option>
                        <mat-option value="180">Within 6 months</mat-option>
                        <mat-option value="181">More than 6 months</mat-option>
                      </mat-select>
                      <mat-error *ngIf="lLoan.hasError('required')">Speed of loan desired is required</mat-error>
                      <mat-error *ngIf="lLoan.hasError('server')">{{lLoan.errors['server']}}</mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="data.typeOfLoan === 'Purchase'">
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="35" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.desiredLoanAmount}">
                        <mat-label>Desired loan amount</mat-label>
                        <mat-icon matPrefix>attach_money</mat-icon>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="360,000" id="dLoanAmnt" name="dLoanAmnt" #desLoanAmnt="ngModel"
                          [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.desiredLoanAmount" required minlength="1" maxlength="11">
                        <mat-hint>You can estimate</mat-hint>
                        <mat-error *ngIf="desLoanAmnt.hasError('maxlength')">Cannot be longer than 11 characters.</mat-error>
                        <mat-error *ngIf="desLoanAmnt.hasError('required')">Desired loan amount is required.</mat-error>
                        <mat-error *ngIf="desLoanAmnt.hasError('server')">{{desLoanAmnt.errors['server']}}</mat-error>
                      </mat-form-field>
                    </ng-container>

                  </ng-container>
                </div>
                <ng-container *ngIf="data.propertyType === 'Primary residence' || data.typeOfLoan === 'Refinance (no cash out)' || data.typeOfLoan === 'Refinance (cash out)'">


                  <br>
                  <br>
                  <br>
                  <div #currentLoan></div>
                  <mat-divider></mat-divider>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <br>
                    <br>
                    <br>
                    <br>
                    <h4 class="mb-0 mt-10 font-weight-400">
                      <mat-icon class="Completed mat-18 prt-03 mr-05"
                        *ngIf="(data.rentOrOwn === 'Rent' && data.currentPayment) || data.rentOrOwn === 'n/a' || (data.rentOrOwn === 'Own' && (data.currentPayment && data.currentPropertyType && data.streetAddress && data.city && data.state && data.zipCode && data.estimatedPropertyValue && data.mortgageOwed && data.currentInterestRate))"
                        [@400]="'fadeIn'">
                        done_outline</mat-icon>
                      <mat-icon class="material-icons-outlined prt-07 mr-05 mat-30">home</mat-icon> Current loan and property
                    </h4>
                  </div>
                  <br>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <p>You're on your way to refinancing your home. We'll shop around and find you the best possible rate, while maintaining full transparency and constant contact.</p>
                  </div>
                  <br>
                  <div fxLayoutAlign="start center" fxLayout="row wrap">
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.rentOrOwn}">
                      <mat-label>Do you own or rent your current property?</mat-label>
                      <mat-select [disabled]="isAppDisabled" tabindex="0" id="rentOrOwn" placeholder="Type of loan" name="rentOrOwn" required #rentOwn="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.rentOrOwn" ngDefaultControl>
                        <mat-option value="select" selected="selected" disabled="disabled">Do you own or rent?</mat-option>
                        <mat-option value="Own">Own</mat-option>
                        <ng-container *ngIf="data.typeOfLoan === 'Purchase'">
                          <mat-option value="Rent">Rent</mat-option>
                          <mat-option value="n/a">N/A</mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-hint>Current property</mat-hint>
                      <mat-error *ngIf="rentOwn.hasError('required')">Own or rent is required</mat-error>
                      <mat-error *ngIf="rentOwn.hasError('server')">{{rentOwn.errors['server']}}</mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="data.rentOrOwn && data.rentOrOwn != 'n/a'">
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.currentPayment}">
                        <mat-label>Current {{data.rentOrOwn === 'Own' ? 'mortgage' : 'rent'}} payment</mat-label>
                        <mat-icon matPrefix>attach_money</mat-icon>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="1,150" id="currentPmt" name="currentPmt" #currentPay="ngModel"
                          [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.currentPayment" required minlength="1" maxlength="10">
                        <mat-error *ngIf="currentPay.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
                        <mat-error *ngIf="currentPay.hasError('required')">Payment amount is required.</mat-error>
                        <mat-error *ngIf="currentPay.hasError('server')">{{currentPay.errors['server']}}</mat-error>
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="data.rentOrOwn === 'Own'">
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.lateMortgagePayment}">
                        <mat-label>Have you had a late payment?</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="latePay" placeholder="Late payment?" name="latePay" required #latePayment="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.lateMortgagePayment"
                          ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">Have you had a late payment?</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Yes">Yes</mat-option>
                        </mat-select>
                        <mat-hint>Current property</mat-hint>
                        <mat-error *ngIf="rentOwn.hasError('required')">Own or rent is required</mat-error>
                        <mat-error *ngIf="rentOwn.hasError('server')">{{rentOwn.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.currentPropertyType}">
                        <mat-label>Type of property?</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="currentProp" placeholder="Type of property" name="currentProp" required #curProp="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.currentPropertyType"
                          ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">Type of Property</mat-option>
                          <mat-option value="Single Family Residence">Single Family (most common)</mat-option>
                          <mat-option value="Condo">Condo</mat-option>
                          <mat-option value="Townhouse">Townhouse</mat-option>
                          <!-- <mat-option value="Investment Property">Investment Property</mat-option> -->
                          <mat-option value="Other">Other</mat-option>
                        </mat-select>
                        <mat-hint>Current property</mat-hint>
                        <mat-error *ngIf="curProp.hasError('required')">Type of property desired is required</mat-error>
                        <mat-error *ngIf="curProp.hasError('server')">{{curProp.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- Address Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="40" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.streetAddress}">
                        <mat-label>Street address</mat-label>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="123 S Way Dr." id="address" name="address" #address="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.streetAddress" required
                          autocomplete="address-line1" pattern="^[^-\s][a-zA-Z0-9_.\s-]+$">
                        <mat-error *ngIf="address.hasError('required')">Address is required.</mat-error>
                        <mat-error *ngIf="address.hasError('server')">{{address.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- Address 2 Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.streetAddress2}">
                        <mat-label>Apartment, suite, etc.</mat-label>
                        <input matInput [disabled]="isAppDisabled" placeholder="Ste. 2" class="full-width" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.streetAddress2" id="address2" name="address2" #address2="ngModel"
                          pattern="^[^-\s][a-zA-Z0-9_\s-]+$">
                        <mat-error *ngIf="address2.hasError('server')">{{address2.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- City Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.city}">
                        <mat-label>City</mat-label>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="Southtown" id="address-level2" name="address-level2" #city="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.city"
                          mapError="city" required autocomplete="address-level2" pattern="^[^-\s][a-zA-Z0-9_\s-]+$">
                        <mat-error *ngIf="city.hasError('required')">City is required.</mat-error>
                        <mat-error *ngIf="city.hasError('server')">{{city.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- State Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.state}">
                        <mat-label>State</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="state" placeholder="Select state" name="state" required #pState="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.state" autocomplete="address-level1"
                          ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">Select State</mat-option>
                          <mat-option *ngFor="let t of allStates" [value]="t.value">{{t.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="pState.hasError('required')">State is required</mat-error>
                        <mat-error *ngIf="pState.hasError('server')">{{pState.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.zipCode}">
                        <mat-label>Zip code</mat-label>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" inputmode="numeric" type="text" pattern="^[^-\s][0-9_\s-]+$" placeholder="12345" id="zip" name="zip" autocomplete="postal-code" #zipCode="ngModel"
                          [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.zipCode" mapError="zipCode" required minlength="5" maxlength="10">
                        <mat-error *ngIf="zipCode.hasError('required')">Zip code is required.</mat-error>
                        <mat-error *ngIf="zipCode.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
                        <mat-error *ngIf="zipCode.hasError('server')">{{zipCode.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.estimatedPropertyValue}">
                        <mat-label>Value of current property</mat-label>
                        <mat-icon matPrefix>attach_money</mat-icon>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="410,000" id="estPValue" name="estPValue" #estPValue="ngModel"
                          [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.estimatedPropertyValue" required minlength="1" maxlength="11">
                        <mat-hint>Estimated is okay</mat-hint>
                        <mat-error *ngIf="estPValue.hasError('maxlength')">Cannot be longer than 11 characters.</mat-error>
                        <mat-error *ngIf="estPValue.hasError('required')">Estimated value is required.</mat-error>
                        <mat-error *ngIf="estPValue.hasError('server')">{{estPValue.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.mortgageOwed}">
                        <mat-label>Amount owed on mortgage</mat-label>
                        <mat-icon matPrefix>attach_money</mat-icon>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="362,000" id="mortOwed" name="mortOwed" #mortgOwed="ngModel"
                          [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.mortgageOwed" required minlength="1" maxlength="11">
                        <mat-hint>Estimated is okay</mat-hint>
                        <mat-error *ngIf="mortgOwed.hasError('maxlength')">Cannot be longer than 11 characters.</mat-error>
                        <mat-error *ngIf="mortgOwed.hasError('required')">Mortgage owed is required.</mat-error>
                        <mat-error *ngIf="mortgOwed.hasError('server')">{{mortgOwed.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.currentInterestRate}">
                        <mat-label>Current interest rate</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="state" placeholder="Current interest rate" name="currentInterest" required #cInterest="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                          [(ngModel)]="data.currentInterestRate" ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">Select interest rate</mat-option>
                          <mat-option value="1">1%</mat-option>
                          <mat-option value="1.125">1.125%</mat-option>
                          <mat-option value="1.25">1.25%</mat-option>
                          <mat-option value="1.375">1.375%</mat-option>
                          <mat-option value="1.5">1.5%</mat-option>
                          <mat-option value="1.625">1.625%</mat-option>
                          <mat-option value="1.75">1.75%</mat-option>
                          <mat-option value="1.875">1.875%</mat-option>
                          <mat-option value="2">2%</mat-option>
                          <mat-option value="2.125">2.125%</mat-option>
                          <mat-option value="2.25">2.25%</mat-option>
                          <mat-option value="2.375">2.375%</mat-option>
                          <mat-option value="2.5">2.5%</mat-option>
                          <mat-option value="2.625">2.625%</mat-option>
                          <mat-option value="2.75">2.75%</mat-option>
                          <mat-option value="2.875">2.875%</mat-option>
                          <mat-option value="3">3%</mat-option>
                          <mat-option value="3.125">3.125%</mat-option>
                          <mat-option value="3.25">3.25%</mat-option>
                          <mat-option value="3.375">3.375%</mat-option>
                          <mat-option value="3.5">3.5%</mat-option>
                          <mat-option value="3.625">3.625%</mat-option>
                          <mat-option value="3.75">3.75%</mat-option>
                          <mat-option value="3.875">3.875%</mat-option>
                          <mat-option value="4">4%</mat-option>
                          <mat-option value="4.125">4.125%</mat-option>
                          <mat-option value="4.25">4.25%</mat-option>
                          <mat-option value="4.375">4.375%</mat-option>
                          <mat-option value="4.5">4.5%</mat-option>
                          <mat-option value="4.625">4.625%</mat-option>
                          <mat-option value="4.75">4.75%</mat-option>
                          <mat-option value="4.875">4.875%</mat-option>
                          <mat-option value="5">5%</mat-option>
                          <mat-option value="5.125">5.125%</mat-option>
                          <mat-option value="5.25">5.25%</mat-option>
                          <mat-option value="5.375">5.375%</mat-option>
                          <mat-option value="5.5">5.5%</mat-option>
                          <mat-option value="5.625">5.625%</mat-option>
                          <mat-option value="5.75">5.75%</mat-option>
                          <mat-option value="5.875">5.875%</mat-option>
                          <mat-option value="6">6%</mat-option>
                          <mat-option value="6.125">6.125%</mat-option>
                          <mat-option value="6.25">6.25%</mat-option>
                          <mat-option value="6.375">6.375%</mat-option>
                          <mat-option value="6.5">6.5%</mat-option>
                          <mat-option value="6.625">6.625%</mat-option>
                          <mat-option value="6.75">6.75%</mat-option>
                          <mat-option value="6.875">6.875%</mat-option>
                          <mat-option value="7">7%</mat-option>
                          <mat-option value="7.125">7.125%</mat-option>
                          <mat-option value="7.25">7.25%</mat-option>
                          <mat-option value="7.375">7.375%</mat-option>
                          <mat-option value="7.5">7.5%</mat-option>
                          <mat-option value="7.625">7.625%</mat-option>
                          <mat-option value="7.75">7.75%</mat-option>
                          <mat-option value="7.875">7.875%</mat-option>
                          <mat-option value="8">8%</mat-option>
                          <mat-option value="8.125">8.125%</mat-option>
                          <mat-option value="8.25">8.25%</mat-option>
                          <mat-option value="8.375">8.375%</mat-option>
                          <mat-option value="8.5">8.5%</mat-option>
                          <mat-option value="8.625">8.625%</mat-option>
                          <mat-option value="8.75">8.75%</mat-option>
                          <mat-option value="8.875">8.875%</mat-option>
                          <mat-option value="9">9%</mat-option>
                          <mat-option value="9.125">9.125%</mat-option>
                          <mat-option value="9.25">9.25%</mat-option>
                          <mat-option value="9.375">9.375%</mat-option>
                          <mat-option value="9.5">9.5%</mat-option>
                          <mat-option value="9.625">9.625%</mat-option>
                          <mat-option value="9.75">9.75%</mat-option>
                          <mat-option value="9.875">9.875%</mat-option>
                          <mat-option value="10">10%</mat-option>
                          <mat-option value="10+">10+%</mat-option>
                        </mat-select>
                        <mat-hint>Estimated is okay</mat-hint>
                        <mat-error *ngIf="cInterest.hasError('required')">Interest rate is required.</mat-error>
                        <mat-error *ngIf="cInterest.hasError('server')">{{cInterest.errors['server']}}</mat-error>
                      </mat-form-field>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.typeOfLoan === 'Purchase' && data.propertyType && data.rentOrOwn">


                  <br>
                  <br>
                  <br>
                  <div #purchaseProp></div>
                  <mat-divider></mat-divider>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <br>
                    <br>
                    <br>
                    <br>
                    <h4 class="mb-0 mt-10 font-weight-400">
                      <mat-icon class="Completed mat-18 prt-03 mr-05"
                        *ngIf="(data.havePropertyAddress === 'No') || (data.havePropertyAddress === 'Yes' && (data.purPropertyType && data.purStreetAddress && data.purCity && data.purState && data.purZipCode && data.salesPrice))" [@400]="'fadeIn'">
                        done_outline</mat-icon>
                      <mat-icon class="material-icons-outlined prt-07 mr-05 mat-30">add_location_alt</mat-icon> Purchase property
                    </h4>
                  </div>
                  <br>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <p>You're on your way to refinancing your home. We'll shop around and find you the best possible rate, while maintaining full transparency and constant contact.</p>
                  </div>
                  <br>
                  <div fxLayoutAlign="start center" fxLayout="row wrap">
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.havePropertyAddress}">
                      <mat-label>Do you have the new property's address?</mat-label>
                      <mat-select [disabled]="isAppDisabled" tabindex="0" id="newProp" placeholder="Type of property" name="newProp" required #nProp="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.havePropertyAddress"
                        ngDefaultControl>
                        <mat-option value="select" selected="selected" disabled="disabled">Have the new address?</mat-option>
                        <mat-option value="Yes">Yes</mat-option>
                        <mat-option value="No">No</mat-option>
                      </mat-select>
                      <mat-hint>Purchase property</mat-hint>
                      <mat-error *ngIf="nProp.hasError('required')">Required</mat-error>
                      <mat-error *ngIf="nProp.hasError('server')">{{nProp.errors['server']}}</mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="data.havePropertyAddress && data.havePropertyAddress === 'Yes'">


                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.purPropertyType}">
                        <mat-label>Type of property?</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="newPropType" placeholder="Type of property" name="newPropType" required #nPropType="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.purPropertyType"
                          ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">Type of Property</mat-option>
                          <mat-option value="Single Family Residence">Single Family (most common)</mat-option>
                          <mat-option value="Condo">Condo</mat-option>
                          <mat-option value="Townhouse">Townhouse</mat-option>
                          <!-- <mat-option value="Investment Property">Investment Property</mat-option> -->
                          <mat-option value="Other">Other</mat-option>
                        </mat-select>
                        <mat-hint>Purchase property</mat-hint>
                        <mat-error *ngIf="nPropType.hasError('required')">Type of property is required</mat-error>
                        <mat-error *ngIf="nPropType.hasError('server')">{{nPropType.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- Address Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="40" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.purStreetAddress}">
                        <mat-label>Street address</mat-label>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="123 S Way Dr." id="newPropAddress" name="newPropAddress" #nPropAddress="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                          [(ngModel)]="data.purStreetAddress" required autocomplete="address-line1" pattern="^[^-\s][a-zA-Z0-9_.\s-]+$">
                        <mat-error *ngIf="nPropAddress.hasError('required')">Address is required.</mat-error>
                        <mat-error *ngIf="nPropAddress.hasError('server')">{{nPropAddress.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- Address 2 Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.ssn}">
                        <mat-label>Apartment, suite, etc.</mat-label>
                        <input matInput [disabled]="isAppDisabled" placeholder="Ste. 2" class="full-width" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.purStreetAddress2" id="newPropAddress2" name="newPropAddress2" #nPropAddress2="ngModel"
                          pattern="^[^-\s][a-zA-Z0-9_\s-]+$">
                        <mat-error *ngIf="nPropAddress2.hasError('server')">{{nPropAddress2.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- City Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="30" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.purCity}">
                        <mat-label>City</mat-label>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" type="text" placeholder="Southtown" id="newPropAddress-level2" name="newPropAddress-level2" #nPropCity="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                          [(ngModel)]="data.purCity" required autocomplete="address-level2" pattern="^[^-\s][a-zA-Z0-9_\s-]+$">
                        <mat-error *ngIf="nPropCity.hasError('required')">City is required.</mat-error>
                        <mat-error *ngIf="nPropCity.hasError('server')">{{nPropCity.errors['server']}}</mat-error>
                      </mat-form-field>
                      <!-- State Field -->
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.purState}">
                        <mat-label>State</mat-label>
                        <mat-select [disabled]="isAppDisabled" tabindex="0" id="newPropState" placeholder="Select state" name="newPropState" required #nPropState="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.purState"
                          autocomplete="address-level1" ngDefaultControl>
                          <mat-option value="select" selected="selected" disabled="disabled">Select State</mat-option>
                          <mat-option *ngFor="let t of allStates" [value]="t.value">{{t.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="nPropState.hasError('required')">State is required</mat-error>
                        <mat-error *ngIf="nPropState.hasError('server')">{{nPropState.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="50" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.purZipCode}">
                        <mat-label>Zip code</mat-label>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" inputmode="numeric" type="text" pattern="^[^-\s][0-9_\s-]+$" placeholder="12345" id="newPropZip" name="newPropZip" autocomplete="postal-code" #nPropZipCode="ngModel"
                          [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.purZipCode" required minlength="5" maxlength="10">
                        <mat-error *ngIf="nPropZipCode.hasError('required')">Zip code is required.</mat-error>
                        <mat-error *ngIf="nPropZipCode.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
                        <mat-error *ngIf="nPropZipCode.hasError('server')">{{nPropZipCode.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.salesPrice}">
                        <mat-label>Property's sales price</mat-label>
                        <mat-icon matPrefix>attach_money</mat-icon>
                        <input matInput [disabled]="isAppDisabled" tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="410,000" id="newEstPropValue" name="newEstPropValue" #nEstPropValue="ngModel"
                          [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.salesPrice" required minlength="1" maxlength="7">
                        <mat-error *ngIf="nEstPropValue.hasError('maxlength')">Cannot be longer than 46 characters.</mat-error>
                        <mat-error *ngIf="nEstPropValue.hasError('required')">Sales price is required.</mat-error>
                        <mat-error *ngIf="nEstPropValue.hasError('server')">{{nEstPropValue.errors['server']}}</mat-error>
                      </mat-form-field>

                    </ng-container>

                    <ng-container *ngIf="data.havePropertyAddress && data.havePropertyAddress === 'No'">
                      <div fxFlex="100" class="pv-40 ph-20">

                        <div fxLayout="row wrap" fxLayoutAlign="center center">
                          <mat-icon class="material-icons-outlined mat-48 mb-40">thumb_up</mat-icon>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="center center">
                          <div fxFlex.lt-sm="100" fxFlex.gt-xs="75" fxFlex.gt-sm="45">
                            <p class="text-center">No problem! We'll get you pre-qualified giving you have more buying power when you find the home you love.</p>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.typeOfLoan === 'Purchase' && data.propertyType && data.rentOrOwn">


                  <br>
                  <br>
                  <br>
                  <div #income></div>
                  <mat-divider></mat-divider>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <br>
                    <br>
                    <br>
                    <br>
                    <h4 class="mb-0 mt-10 font-weight-400">
                      <mat-icon class="Completed mat-18 prt-03 mr-05" *ngIf="data.currentlyEmployed && ((data.currentlyEmployed === 'Yes' && data.grossIncome && data.jobStartDate) || data.currentlyEmployed === 'No')" [@400]="'fadeIn'">
                        done_outline</mat-icon>
                      <mat-icon class="material-icons-outlined prt-07 mr-05 mat-30">payments</mat-icon> Income
                    </h4>
                  </div>
                  <br>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <p>Streamline your mortgage application by providing a little information about your employment and/or income status.</p>
                  </div>
                  <br>
                  <div fxLayoutAlign="start center" fxLayout="row wrap">
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.currentlyEmployed}">
                      <mat-label>Are you currently employed?</mat-label>
                      <mat-select tabindex="0" id="curEmployed" placeholder="Currently employed?" name="curEmployed" required #currentlyEmployed="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.currentlyEmployed" ngDefaultControl
                        [disabled]="isAppDisabled">
                        <mat-option value="select" selected="selected" disabled="disabled">Currently employed?</mat-option>
                        <mat-option value="Yes">Yes</mat-option>
                        <mat-option value="No">No</mat-option>
                      </mat-select>
                      <mat-error *ngIf="currentlyEmployed.hasError('required')">Current employment is required</mat-error>
                      <mat-error *ngIf="currentlyEmployed.hasError('server')">{{nPropType.errors['server']}}</mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="data.currentlyEmployed === 'Yes'">
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.grossIncome}">
                        <mat-label>Gross monthly income</mat-label>
                        <mat-icon matPrefix>attach_money</mat-icon>
                        <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="5,150" id="grossIncome" name="grossIncome" #gIncome="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                          [(ngModel)]="data.grossIncome" required minlength="1" maxlength="10" [disabled]="isAppDisabled">
                        <mat-hint>Estimated is okay</mat-hint>
                        <mat-error *ngIf="gIncome.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
                        <mat-error *ngIf="gIncome.hasError('required')">Gross monthly income is required.</mat-error>
                        <mat-error *ngIf="gIncome.hasError('server')">{{nEstPropValue.errors['server']}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="40" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.jobStartDate}">
                        <mat-label>When did you start?</mat-label>
                        <input matInput [matDatepicker]="datepicker" placeholder="mm/dd/yyyy" id="timeOnCurrentJob" name="timeOnCurrentJob" #timeOnJob="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.jobStartDate" required
                          [disabled]="isAppDisabled">
                        <mat-hint>Estimated is okay</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker>
                          <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                      </mat-form-field>
                    </ng-container>

                  </div>
                  <!-- Co-Borrower Information -->
                  <ng-container *ngIf="data.hasCoBorrower === 'Yes'">
                    <div fxLayoutAlign="center center" fxLayout="row wrap" class="mt-15">
                      <div fxFlex="60">
                        <mat-divider class="mat-card-divider"></mat-divider>
                      </div>
                    </div>
                    <div fxLayoutAlign="start center" fxLayout="row wrap">
                      <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm mat-form-field_sm-select" [@400]="'fadeIn'" [ngClass]="{'success': data.coCurrentlyEmployed}">
                        <mat-label>Is {{data.coFirstName}} currently employed?</mat-label>
                        <mat-select tabindex="0" id="curEmployed" placeholder="Currently employed?" name="coCurEmployed" required #coEmployed="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.coCurrentlyEmployed" ngDefaultControl
                          [disabled]="isAppDisabled">
                          <mat-option value="select" selected="selected" disabled="disabled">Currently employed?</mat-option>
                          <mat-option value="Yes">Yes</mat-option>
                          <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-error *ngIf="coEmployed.hasError('required')">Current employment is required</mat-error>
                        <mat-error *ngIf="coEmployed.hasError('server')">{{nPropType.errors['server']}}</mat-error>
                      </mat-form-field>
                      <ng-container *ngIf="data.coCurrentlyEmployed === 'Yes'">
                        <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="50" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coGrossIncome}">
                          <mat-label>{{data.coFirstName}}'s gross monthly income</mat-label>
                          <mat-icon matPrefix>attach_money</mat-icon>
                          <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="5,150" id="coGrossIncome" name="coGrossIncome" #coGIncome="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                            [(ngModel)]="data.coGrossIncome" required minlength="1" maxlength="10" [disabled]="isAppDisabled">
                          <mat-hint>Estimated is okay</mat-hint>
                          <mat-error *ngIf="coGIncome.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
                          <mat-error *ngIf="coGIncome.hasError('required')">Gross monthly income is required.</mat-error>
                          <mat-error *ngIf="coGIncome.hasError('server')">{{nEstPropValue.errors['server']}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="40" class="ph-05 mat-form-field_sm" [@400]="'fadeIn'" [ngClass]="{'success': data.coJobStartDate}">
                          <mat-label>When did {{data.coFirstName}} start?</mat-label>
                          <input matInput [matDatepicker]="datepicker" placeholder="mm/dd/yyyy" id="coTimeOnCurrentJob" name="coTimeOnCurrentJob" #timeOnJob="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="data.coJobStartDate" required
                            [disabled]="isAppDisabled">
                          <mat-hint>Estimated is okay</mat-hint>
                          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                          <mat-datepicker #datepicker>
                            <mat-datepicker-actions>
                              <button type="button" mat-button matDatepickerCancel>Cancel</button>
                              <button type="button" mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            </mat-datepicker-actions>
                          </mat-datepicker>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="data.coCurrentlyEmployed && (data.coCurrentlyEmployed === 'No' || data.coCurrentlyEmployed === 'No')">
                        <div fxFlex="100" class="pv-40 ph-20">

                          <div fxLayout="row wrap" fxLayoutAlign="center center">
                            <mat-icon class="material-icons-outlined mat-48 mb-40">thumb_up</mat-icon>
                          </div>
                          <div fxLayout="row wrap" fxLayoutAlign="center center">
                            <div fxFlex.lt-sm="100" fxFlex.gt-xs="75" fxFlex.gt-sm="45">
                              <p class="text-center">Don't worry! You can still submit your application.</p>
                            </div>
                          </div>
                        </div>
                      </ng-container>




                    </div>
                  </ng-container>



                </ng-container>


                <br />
                <br />
                <div fxLayout="row wrap" fxLayoutAlign="center center">
                  <mat-divider fxFlex></mat-divider>
                </div>

                <br />
                <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-15 mt-10">

                  <button mat-raised-button type="submit" color="primary" [@400]="'fadeIn'" [disabled]="isAppDisabled || isSubmitting">
                    {{canEditApp ? 'Update' : 'Submit'}} application<mat-spinner [diameter]="24" color="primary" *ngIf="isSubmitting"></mat-spinner>
                  </button>
                </div>
              </ng-container>
              <ng-template #loginComp>
                <div class="grid-container grid-container_small">
                  <login [title]="'Apply online'" [subTitle]="'An account is required to apply'" [showCreate]="true"></login>
                </div>
              </ng-template>
              <div id="application-bottom"></div>
            </mat-card>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </form>
    </div>
    <div fxFlex.lt-md="1 1 100" fxFlex.gt-sm="1 1 25" fxFlexOrder.gt-sm="2" fxFlexOrder.lt-md="1" fxLayout="column" *ngIf="auth.isLoggedIn">
      <div id="guide">
        <!-- <ng-container *ngIf="data.status">
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [@400]="'fadeIn'" (click)="startNewApp()" style="color:#fff">
              New application
            </button>
          </div>
          <br fxHide.gt-sm fxShow.lt-md />
        </ng-container> -->
        <ng-container *ngIf="store.hasApplications">
          <div class="tableOfContentContainer">
            <div class="docs-toc-container light-bg">
              <div class="docs-toc-heading">All applications</div>
              <!-- <div fxLayout="row wrap" fxLayoutAlign="center center">
                <button mat-button type="button" (click)="startNewApp()" [@400]="'fadeIn'">
                  Create application
                </button>
              </div> -->
              <br />
              <div fxLayout="row wrap" fxLayoutAlign="center center" class="mb-15">
                <button mat-raised-button color="primary" [@400]="'fadeIn'" (click)="startNewApp()" style="color: #fff;">
                  Start a new application
                </button>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center center">
                <a mat-raised-button rel="noopener" [routerLink]="[ '../', 'account', 'applications' ]" [@400]="'fadeIn'">
                  View my applications
                </a>
              </div>
              <br />
            </div>
          </div>
        </ng-container>
        <div class="tableOfContentContainer">
          <div class="docs-toc-container light-bg">
            <div class="docs-toc-heading">
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div>{{data.id ? 'Loan # ' + data.id  : 'Online application'}}</div>
                <button mat-icon-button type="button" [matMenuTriggerFor]="helpMenu" matTooltip="Click for application help" matTooltipPosition="after">
                  <mat-icon class="material-icons-outlined">help_outline</mat-icon>
                </button>
              </div>
            </div>
            <mat-list>
              <button mat-button type="button" (click)="scrollTo('userInfo')" class="full-width" style="padding: 0 !important;" [@400]="'fadeIn'">
                <mat-list-item class="nav-list ph-05 clickable clickable_hover">
                  <mat-icon matListIcon class="Completed mat-18 prt--01 mr-05" *ngIf="data.firstName && data.lastName && data.phoneNumber && data.emailAddress && data.estimatedCreditScore && data.dob" [@400]="'fadeIn'">
                    done_outline</mat-icon>
                  <mat-icon matListIcon class="material-icons-outlined">person</mat-icon>
                  <h3 matLine class="font-weight-400 text-left"> Your information </h3>
                </mat-list-item>
              </button>
              <button mat-button type="button" (click)="scrollTo('coBorrower')" class="full-width" style="padding: 0 !important;" [@400]="'fadeIn'" *ngIf="data && data.hasCoBorrower === 'Yes'">
                <mat-list-item class="nav-list ph-05 clickable clickable_hover">
                  <mat-icon matListIcon class="Completed mat-18 prt--01 mr-05" *ngIf="data.coFirstName && data.coLastName && data.coPhoneNumber && data.coEmailAddress && data.coEstimatedCreditScore && data.coDob" [@400]="'fadeIn'">
                    done_outline</mat-icon>
                  <mat-icon matListIcon class="material-icons-outlined">person_add</mat-icon>
                  <h3 matLine class="font-weight-400 text-left"> Co-borrower </h3>
                </mat-list-item>
              </button>
              <button mat-button type="button" (click)="scrollTo('loanType')" class="full-width" style="padding: 0 !important;" [@400]="'fadeIn'">
                <mat-list-item class="nav-list ph-05 clickable clickable_hover">
                  <mat-icon matListIcon class="Completed mat-18 prt--01 mr-05"
                    *ngIf="(data.typeOfLoan === 'Refinance (cash out)' && data.cashOut || data.typeOfLoan === 'Refinance (no cash out)' || data.typeOfLoan === 'Purchase' ) && data.lengthOfLoan && data.loanTimeframe  && (data.typeOfLoan === 'Purchase' && data.desiredLoanAmount && data.propertyType || data.typeOfLoan != 'Purchase')"
                    [@400]="'fadeIn'">
                    done_outline</mat-icon>
                  <mat-icon matListIcon class="material-icons-outlined">home_work</mat-icon>
                  <h3 matLine class="font-weight-400 text-left"> Desired loan </h3>
                </mat-list-item>
              </button>
              <button mat-button type="button" (click)="scrollTo('currentLoan')" class="full-width" style="padding: 0 !important;"
                *ngIf="data.propertyType === 'Primary residence' || data.typeOfLoan === 'Refinance (no cash out)' || data.typeOfLoan === 'Refinance (cash out)'" [@400]="'fadeIn'">
                <mat-list-item class="nav-list ph-05 clickable clickable_hover">
                  <mat-icon matListIcon class="Completed mat-18 prt--01 mr-05"
                    *ngIf="(data.rentOrOwn === 'Rent' && data.currentPayment) || data.rentOrOwn === 'n/a' || (data.rentOrOwn === 'Own' && (data.currentPayment && data.lateMortgagePayment && data.currentPropertyType && data.streetAddress && data.city && data.state && data.zipCode && data.estimatedPropertyValue && data.mortgageOwed && data.currentInterestRate))"
                    [@400]="'fadeIn'">
                    done_outline</mat-icon>
                  <mat-icon matListIcon class="material-icons-outlined">home</mat-icon>
                  <h3 matLine class="font-weight-400 text-left"> Current loan </h3>
                </mat-list-item>
              </button>
              <button mat-button type="button" (click)="scrollTo('purchaseProp')" class="full-width" style="padding: 0 !important;" *ngIf="data.typeOfLoan === 'Purchase' && data.propertyType && data.rentOrOwn" [@400]="'fadeIn'">
                <mat-list-item class="nav-list ph-05 clickable clickable_hover">
                  <mat-icon matListIcon class="Completed mat-18 prt--01 mr-05"
                    *ngIf="(data.havePropertyAddress === 'No') || (data.havePropertyAddress === 'Yes' && (data.purPropertyType && data.purStreetAddress && data.purCity && data.purState && data.purZipCode && data.salesPrice))" [@400]="'fadeIn'">
                    done_outline</mat-icon>
                  <mat-icon matListIcon class="material-icons-outlined">add_location_alt</mat-icon>
                  <h3 matLine class="font-weight-400 text-left"> Purchase property </h3>
                </mat-list-item>
              </button>
              <button mat-button type="button" (click)="scrollTo('income')" class="full-width" style="padding: 0 !important;" *ngIf="data.typeOfLoan === 'Purchase' && data.propertyType && data.rentOrOwn" [@400]="'fadeIn'">
                <mat-list-item class="nav-list ph-05 clickable clickable_hover">
                  <mat-icon matListIcon class="Completed mat-18 prt--01 mr-05" *ngIf="data.currentlyEmployed && ((data.currentlyEmployed === 'Yes' && data.grossIncome && data.jobStartDate) || data.currentlyEmployed === 'No')" [@400]="'fadeIn'">
                    done_outline</mat-icon>
                  <mat-icon matListIcon class="material-icons-outlined">payments</mat-icon>
                  <h3 matLine class="font-weight-400 text-left"> Income </h3>
                </mat-list-item>
              </button>
            </mat-list>
            <br />
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <button mat-raised-button type="button" (click)="submitApplication()" [@400]="'fadeIn'" [disabled]="isAppDisabled || isSubmitting">
                {{canEditApp ? 'Update' : 'Submit'}} application<mat-spinner [diameter]="24" color="primary" *ngIf="isSubmitting"></mat-spinner>
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<br />

<ng-template #noApps>
  <br />
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-icon class="material-icons-outlined mat-48">cloud_circle</mat-icon>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h6 class="mb-0" class="text-center mt-15 mb-20">No applications found</h6>
  </div>
  <br />
  <br />
</ng-template>
<ng-template #loading>
  <div fxLayout="row wrap" fxLayoutAlign="center stretch">
    <div class="loading">
      <mat-spinner [diameter]="36" color="primary"></mat-spinner>
    </div>
  </div>
</ng-template>
<mat-menu #helpMenu="matMenu" direction="down" yPosition="below">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <h6 class="ml-15 mt-05 font-weight-400">Need help with your application? &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</h6>
  </div>
  <br />
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <chat-button matType="stroked" matColor="primary" buttonText="Talk to a loan officer"></chat-button>
  </div>
  <br />
</mat-menu>
