<header id="header">
  <div class="header-top" fxShow.gt-xs fxHide.xs>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div class="grid-container pt-0 pb-05 mt-0 mb-0">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <p class="mb-0">Serving Utah for over&nbsp;<strong>30
              years</strong>!</p>
          <div>
            <a mat-button href="tel:8013020100" class="text-primary mr-10">
              <mat-icon class="material-icons-outlined mat-18 prt--01">call</mat-icon> 801-302-0100
            </a>
            <a fxShow.gt-xs fxHide.xs mat-raised-button color="primary" [routerLink]="[ 'apply' ]" rel="noopener">Apply online</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header">
    <div class="grid-container pv-0 mt-0 mb-0">
      <div fxLayout="row" fxLayoutAlign="center space-between">
        <div fxFlex.gt-xs="180px" fxFlex.xs="calc(100% - 60px)">
          <div fxLayout="row" fxLayoutAlign.gt-xs="start start" fxLayoutAlign.xs="start start">
            <app-logo class="header-logo clickable" [routerLink]="[ 'home' ]" [height]="56"></app-logo>
          </div>
        </div>
        <div fxFlex="60px" fxHide.gt-xs fxShow.xs class="text-right" fxFlexFill>
          <div fxLayout="row wrap" fxLayoutAlign="end center" fxFlexFill>
            <button mat-icon-button (click)="closeDrawer()" class="mt-10">
              <mat-icon>menu</mat-icon>
              <!-- <mat-icon [matBadge]="store.itemsNeededLength" matBadgePosition="after" matBadgeColor="primary">menu</mat-icon> -->
            </button>
          </div>
        </div>
        <div fxFlex="calc(100% - 140px)" fxShow.gt-xs fxHide.xs>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px" fxFlexFill>
            <a rel="noopener" mat-link class="header-btn" [routerLink]="[ 'about' ]" routerLinkActive #aboutActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': aboutActive.isActive}" matRipple>About Us</a>
            <a rel="noopener" mat-link class="header-btn" [routerLink]="[ 'loan-process' ]" routerLinkActive #loanprocessActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': loanprocessActive.isActive}"
              matRipple>Loan Process</a>
            <a rel="noopener" mat-link class="header-btn" [routerLink]="[ 'contact' ]" routerLinkActive #contactActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': contactActive.isActive}" matRipple>Contact Us</a>
            <a rel="noopener" mat-link class="header-btn" #button [matMenuTriggerFor]="levelOne" #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger)" [routerLink]="[ 'mortgage-partner-tools' ]"
              routerLinkActive #toolsActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': toolsActive.isActive}" matRipple>Tools</a>
            <mat-menu #levelOne="matMenu" direction="down" yPosition="below" [hasBackdrop]="false">
              <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(levelOneTrigger)">
                <ng-container *ngIf="toolsMenuList?.length>0">
                  <ng-container *ngFor="let item of toolsMenuList">
                    <ng-container *ngIf="item.html; else btn">
                      <a mat-menu-item [href]="item.html" rel="noopener" target="_blank" matRipple>
                        <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon>
                        <span>{{item.text}}  &nbsp; &nbsp;</span>
                      </a>
                    </ng-container>
                    <ng-template #btn>
                      <a mat-menu-item [routerLink]="[item.route]" matRipple rel="noopener">
                        <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon>
                        <span>{{item.text}} &nbsp; &nbsp;</span>
                      </a>
                    </ng-template>

                  </ng-container>

                </ng-container>
              </span>
            </mat-menu>
            <a rel="noopener" class="header-btn" [routerLink]="[ 'articles' ]" routerLinkActive #articlesActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': articlesActive.isActive}" matRipple>Articles</a>
            <ng-container *ngIf="!auth.isLoggedIn; else logOut">
              <a class="header-btn" (click)="login()" matRipple>
                <mat-icon class="material-icons-outlined mat-18">account_circle</mat-icon>&nbsp;&nbsp;My Account&nbsp;&nbsp;
              </a>
            </ng-container>
            <ng-template #logOut>
              <a rel="noopener" mat-link class="header-btn" #button [matMenuTriggerFor]="accMenu" #accTrigger="matMenuTrigger" (mouseenter)="buttonEnter(accTrigger)" (mouseleave)="buttonLeave(accTrigger)" [routerLink]="[ 'account' ]" routerLinkActive
                #accActive="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': accActive.isActive}" *ngIf="store.userInfo$?.value">
                <mat-icon class="material-icons-outlined mat-18" matRipple>account_circle</mat-icon><span [matBadge]="store.itemsNeededLength" matBadgePosition="after"
                  matBadgeColor="primary">&nbsp;&nbsp;{{(store.userInfo$?.value.displayName) ? store.userInfo$?.value.displayName : store.userInfo$?.value.emailAddress}}&nbsp;&nbsp;</span>
              </a>
              <mat-menu #accMenu="matMenu" direction="down" yPosition="below" [hasBackdrop]="false">
                <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(accTrigger)">
                  <a rel="noopener" mat-menu-item [routerLink]="[ 'account' ]" matRipple>
                    <mat-icon class="material-icons-outlined">dashboard</mat-icon>
                    <span>Dashboard &nbsp; &nbsp;</span>
                  </a>
                  <a rel="noopener" mat-menu-item [routerLink]="[ 'account', 'applications' ]" matRipple>
                    <mat-icon class="material-icons-outlined">description</mat-icon>
                    <span>Applications &nbsp; &nbsp;</span>
                  </a>
                  <a rel="noopener" mat-menu-item [routerLink]="[ 'account', 'items-needed' ]" matRipple>
                    <mat-icon class="material-icons-outlined">upload_file</mat-icon>
                    <span [matBadge]="store.itemsNeededLength" matBadgePosition="after" matBadgeColor="primary">Items Needed &nbsp; &nbsp;</span>
                  </a>
                  <a rel="noopener" mat-menu-item [routerLink]="[ './', 'upload' ]" matRipple>
                    <mat-icon class="material-icons-outlined">file_upload</mat-icon>
                    <span> Upload Documents &nbsp; &nbsp;</span>
                  </a>
                  <a rel="noopener" mat-menu-item [routerLink]="[ 'account', 'items-uploaded' ]" matRipple>
                    <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
                    <span> Documents Uploaded &nbsp; &nbsp;</span>
                  </a>
                  <button mat-menu-item (click)="logout()" matRipple>
                    <mat-icon class="material-icons-outlined">logout</mat-icon>
                    <span>Logout &nbsp; &nbsp;</span>
                  </button>
                </span>
              </mat-menu>
            </ng-template>
            <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://www.facebook.com/TheMortgagePartner/?fref=ts" rel="noopener"><img src="../../../assets/images/facebook.svg" class="mat-icon-button-social" /></a>
            <a mat-icon-button color="white" class="btn-social" target="_blank" href="https://twitter.com/MortgagePart" rel="noopener"><img src="../../../assets/images/twitter.svg" class="mat-icon-button-social" /></a>
            <a mat-icon-button color="white" class="btn-social mr-15" target="_blank" href="https://www.linkedin.com/company/the-mortgage-partner-inc-/about/" rel="noopener"><img src="../../../assets/images/linkedin.svg"
                class="mat-icon-button-social" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
