import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
// import { auth } from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfo } from '../models/user.model';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';
import { FirebaseErrors } from '../models/error.model';

@Injectable()
export class AuthService {
  // userData: User; // Save logged in user data
  isLoading: boolean = false;
  canVerify: boolean = true;
  error: any;
  userInfo: UserInfo;
  authState: any = null;
  public userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    public snackBar: MatSnackBar,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    // public store: UserService,
    public router: Router,
  ) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.authState = user;
        this.userSubject.next(user);
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    })
  }


  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  get isAdmin(): boolean {
    const admin = JSON.parse(localStorage.getItem("user"))
    if (admin && admin.uid) return admin.uid === "RZL1zo4ZobPpEyw34FYcaCwb0th1"
    else return false;
  }

  set isAdmin(data: boolean) {
    this.isAdmin = data;
  }

  get currentUserId(): string {
    return this.isAuthenticated ? this.authState.uid : null;
  }

  get isAuthenticated(): boolean {
    return this.authState != null;
  }

  get isEmailVerified(): boolean {
    return this.isAuthenticated ? this.authState.emailVerified : false;
  }
  
  get user() {
    return this.userSubject.value;
  }

  userObservable() {
    return this.userSubject.asObservable();
  }

  getCurrentUser() {
    this.afAuth.authState.subscribe(user => {
      this.isLoading = false;
      if (user) {
        this.userSubject.next(user);
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
      this.isAuthenticated;
    })
  }

  login(email: string, password: string) {
    this.isLoading = true;
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  register(email: string, password: string, display: string) {
    this.isLoading = true;
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  sendEmailVerification() {
    this.snackBar.open("Email verification sent to: " + this.userInfo.emailAddress, "Close", { duration: 3000 });
    return this.afAuth.currentUser.then(u => u.sendEmailVerification());
  }

  sendPasswordResetEmail(emailAddress: string) {
    this.snackBar.open("Password reset email sent to: " + emailAddress, "Close", { duration: 3000 });
    return this.afAuth.sendPasswordResetEmail(emailAddress);
  }

  async logout(showBar: boolean) {
    await this.afAuth.signOut().then(() => {
      this.isAdmin;
      // Clear user's data in local storage.
      localStorage.removeItem('user');
      if (showBar) this.snackBar.open("Logged out", "Close", { duration: 3000, panelClass: ["info"] });
    });
  }

  async updateUserProfile() {
    const profile = {
      displayName: this.userInfo.firstName + ' ' + this.userInfo.lastName,
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName
    }
    this.updateUserInfo(this.userInfo);
    this.updateUserEmail();
    return (await this.afAuth.currentUser).updateProfile(profile)
      .then((user) => {
        this.ngZone.run(() => {
          this.isLoading = false;
        });
      }).catch((error) => {
        this.isLoading = false;
        if (error) {
          console.log("🚀error: ", error);
          this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
        }
      });
  }

  async updateUserInfo(pInfo: UserInfo) {
    this.snackBar.open("Information updated", "Close", { duration: 3000 });
    // delete item.key;
    this.firestore.collection('users').doc(pInfo.uid).collection('userInfo').doc('data').update(pInfo);
  }

  async updateUserEmail() {

    return (await this.afAuth.currentUser).updateEmail(this.userInfo.emailAddress)
      .then((user) => {
        this.ngZone.run(() => {
          this.isLoading = false;
        });
      }).catch((error) => {
        this.isLoading = false;
        if (error) {
          this.snackBar.open(FirebaseErrors.parse(error.code), "Close", { duration: 3000, panelClass: ["error"] });
        }
      });
  }

  // async checkEmailVerification() {
  //   this.afAuth.currentUser.subscribe(user => {
  //     if (user) {
  //       console.log("🚀 ~ file: auth.service.ts ~ line 202 ~ AuthService ~ checkEmailVerification ~ user", user);
  //       this.authState = user;
  //       this.userSubject.next(user);
  //       localStorage.setItem('user', JSON.stringify(this.user));
  //     } else {
  //       localStorage.setItem('user', null);
  //     }
  //   })
  // }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${user.uid}`);
  //   const userData: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified
  //   }
  //   return userRef.set(userData, {
  //     merge: true
  //   })
  // }




  // async loginWithGoogle() {
  //   await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
  //   this.router.navigate(['admin/list']);
  // }

}