<div class="grid-container grid-container_small">
  <!-- Subsection 5: Stand Out -->
  <br />
  <br fxShow.gt-xs fxHide.xs />
  <br fxShow.gt-xs fxHide.xs />
  <br fxShow.gt-xs fxHide.xs />
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap.lt-md="0px" fxLayoutGap.gt-sm="15px">

    <div fxFlex.xs="100" fxFlex.gt-xs="calc(66.666% - 7.5px)" fxFlex.gt-sm="calc(33.333% - 7.5px)">

      <mat-card class="mat-elevation-z4">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <mat-progress-bar fxFlex="100%" class="loading-bar" color="primary" mode="determinate" [value]="stepProgress"></mat-progress-bar>
        </div>

        <br />
        <br />
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h3 class="mb-0 text-center" fxFlex.gt-xs="80" fxFlex.xs="95">{{title}} step: {{step}}</h3>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ng-container *ngIf="step === 1">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="loanType('refinance')" style="padding: 10px 30px !important;">
              &nbsp;<mat-icon class="material-icons-outlined mat-30 mr-10">house</mat-icon> Refinance&nbsp;
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="loanType('purchase')" style="padding: 10px 30px !important;">
              &nbsp;<mat-icon class="material-icons-outlined mat-30 mr-10">sell</mat-icon> Purchase&nbsp;
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 2">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="resType('primary')" style="padding: 10px 30px !important;">
              &nbsp;&nbsp;&nbsp; <mat-icon class="material-icons-outlined mat-30 mr-10">cottage</mat-icon> Primary residence &nbsp;&nbsp;&nbsp;
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="resType('secondary')" style="padding: 10px 30px !important;">
              <mat-icon class="material-icons-outlined mat-30 mr-10">beach_access</mat-icon> Non-primary residence
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="resType('investment')" style="padding: 10px 30px !important;">
              &nbsp; <mat-icon class="material-icons-outlined mat-30 mr-10">holiday_village</mat-icon> Investment property &nbsp;
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 3">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="propertyType('refinance')" style="padding: 10px 30px !important;">
              <div fxLayout="row wrap" fxLayoutAlign="center center">
                &nbsp;&nbsp; <mat-icon class="material-icons-outlined mat-30 mr-10">home</mat-icon> Single Family &nbsp;&nbsp;
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center center">
                <small style="line-height: 2;"> most common</small>
              </div>
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="propertyType('purchase')" style="padding: 10px 30px !important;">
              &nbsp;&nbsp; <mat-icon class="material-icons-outlined mat-30 mr-10">corporate_fare</mat-icon> Townhouse &nbsp;&nbsp;
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="propertyType('purchase')" style="padding: 10px 30px !important;">
              <mat-icon class="material-icons-outlined mat-30 mr-10">apartment</mat-icon> Condominium
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="propertyType('purchase')" style="padding: 10px 30px !important;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <mat-icon class="material-icons-outlined mat-30 mr-10">houseboat</mat-icon> Other &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 4">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05" [@400]="'fadeIn'" [ngClass]="{'success': hasZip}">
              <mat-label>Zip code</mat-label>
              <input matInput tabindex="0" inputmode="numeric" type="text" pattern="^[^-\s][0-9_\s-]+$" placeholder="12345" id="zip" name="zip" autocomplete="postal-code" #zipCode="ngModel" [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="rapidZip"
                required minlength="5" maxlength="10" color="primary" (ngModelChange)="checkZip($event)" (keyup)="checkZip($event)">
              <mat-error *ngIf="zipCode.hasError('required')">Zip code is required.</mat-error>
              <mat-error *ngIf="zipCode.hasError('maxlength')">Cannot be longer than 10 characters.</mat-error>
            </mat-form-field>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="zipNext()" [disabled]="!hasZip" style="padding: 10px 30px !important;">
              Next
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 5">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="militaryType('yes')" style="padding: 10px 30px !important;">
              &nbsp;Yes&nbsp;
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="militaryType('no')" style="padding: 10px 30px !important;">
              &nbsp;No&nbsp;
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 6">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" [@400]="'fadeIn'" class="ph-05" [ngClass]="{'success': hasEstPropValue}">
              <mat-label>Esitmated Property value</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="360,000" id="estPropValue" name="estPropValue" #estPropertyValue="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                [(ngModel)]="estPropValueAmount" required minlength="1" maxlength="11" (ngModelChange)="checkEstPropValue($event)" (keyup)="checkEstPropValue($event)">
              <mat-hint>You can estimate</mat-hint>
              <mat-error *ngIf="estPropertyValue.hasError('maxlength')">Cannot be longer than 11 characters.</mat-error>
              <mat-error *ngIf="estPropertyValue.hasError('required')">Esitmated roperty value is required.</mat-error>
            </mat-form-field>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="EstPropValue()" [disabled]="!hasEstPropValue" style="padding: 10px 30px !important;">
              Next
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 7">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" [@400]="'fadeIn'" class="ph-05" [ngClass]="{'success': hasRemainingMortgage}">
              <mat-label>Remaining Mortgage Balance</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <input matInput tabindex="0" [textMask]="{mask: numberMask}" inputmode="numeric" type="text" placeholder="360,000" id="remainingMortgage" name="remainingMortgage" #remainingMortgageAmnt="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                [(ngModel)]="remainingMortgageAmount" required minlength="1" maxlength="11" (ngModelChange)="checkRemainingMortgageAmnt($event)" (keyup)="checkRemainingMortgageAmnt($event)">
              <mat-hint>You can estimate</mat-hint>
              <mat-error *ngIf="remainingMortgageAmnt.hasError('maxlength')">Cannot be longer than 11 characters.</mat-error>
              <mat-error *ngIf="remainingMortgageAmnt.hasError('required')">Remaining Mortgage Balance is required.</mat-error>
            </mat-form-field>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="remainingMortgageBalance()" [disabled]="!hasEstPropValue" style="padding: 10px 30px !important;">
              Next
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 8">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="cashOutType('yes')" style="padding: 10px 30px !important;">
              &nbsp;Yes&nbsp;
            </button>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-stroked-button type="button" color="primary" (click)="cashOutType('no')" style="padding: 10px 30px !important;">
              &nbsp;No&nbsp;
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="step === 9">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="33.333" class="ph-05" [@400]="'fadeIn'" [ngClass]="{'success': estimatedCreditScore}">
              <mat-label>Estimated credit score</mat-label>
              <mat-select [disabled]="isAppDisabled" tabindex="0" id="estimatedCreditScore" placeholder="Estimated credit score" name="estimatedCreditScore" required #estCredit="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
                [(ngModel)]="estimatedCreditScore" ngDefaultControl>
                <mat-option value="select" selected="selected" disabled="disabled">Type of Property</mat-option>
                <mat-option value="Excellent (760-850)">Excellent (760-850)</mat-option>
                <mat-option value="Very good (740-799)">Very good (740-759)</mat-option>
                <mat-option value="Good (670-739)">Good (670-739)</mat-option>
                <mat-option value="Fair (580-669)">Decent (580-669)</mat-option>
                <mat-option value="Poor (300-579)">Fair (300-579)</mat-option>
              </mat-select>
              <mat-hint>Estimated is okay</mat-hint>
              <mat-error *ngIf="estCredit.hasError('required')">Estimated credit score is required</mat-error>
            </mat-form-field>
          </div>
          <br />
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="creditScore()" [disabled]="!estimatedCreditScore" style="padding: 10px 30px !important;">
              Next
            </button>
          </div>
        </ng-container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ng-container *ngIf="step != 1">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <button mat-button type="button" (click)="back()">
              <mat-icon class="material-icons-outlined prt--01">keyboard_backspace</mat-icon> Back
            </button>
          </div>
        </ng-container>
      </mat-card>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</div>
